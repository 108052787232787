import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PhotoOutlinedIcon from '@material-ui/icons/PhotoOutlined';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import {
  promoModification,
  promoApprove,
  promoDecline,
} from '../../redux/actions/internalActions';
import { Dialog, DialogContent } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark';
import Checkbox from '@material-ui/core/Checkbox';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import './intdash.scss';

const options = {
  title: [
    'Minimum word limit not reached',
    'Word limit exceeded',
    'Inappropriate Content',
    'Other',
  ],
  description: [
    'Minimum word limit not reached',
    'Word limit exceeded',
    'Inappropriate Content',
    'Other',
  ],
  promoImage: [
    'Inadequate resolution/dimension',
    'Inappropriate Content',
    'Other',
  ],
  promoLogo: [
    'Inadequate resolution/dimension',
    'Inappropriate Content',
    'Other',
  ],
  url: ['Broken Link', 'Inappropriate link', 'Other'],
  product: ['Inappropriate product', 'Other'],
};
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#FFFFFF',
    marginTop: 30,
    width: 1180,
    height: 200,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    boxShadow: '0px 12px 26px rgba(16, 30, 115, 0.06)',
    padding: 30,
    borderRadius: 8,
  },
  innerDivs: {
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  heading: {
    fontSize: 16,
    fontWeight: 600,
    color: '#4D535A',
  },
  para: {
    fontSize: 13,
    fontWeight: 400,
    color: '#857F7F',
    marginTop: 10,
    marginBottom: 10,
    lineHeight: 1.5,
  },
  url: {
    fontSize: 12,
    fontWeight: 600,
    color: '#4188f0',
  },
  crtv: {
    color: '#666',
  },
}));

const Tab = ({
  type,
  promo,
  promoApprove,
  promoDecline,
  promoModification,
}) => {
  const classes = useStyles();
  const [creative, setCreative] = useState(false);
  const [crtvSrc, setSrc] = useState(null);

  const [modp, setModPanel] = useState(false);
  const [b, setB] = useState(0);
  const [changes, setChanges] = useState({});
  const [remarks, setRemarks] = useState({});
  const a = ['', ''];

  const handleApprove = (e) => {
    var data = {
      id: e.target.value,
      type: 'active',
    };
    promoApprove(data);
  };
  const handleDecline = (e) => {
    var data = {
      id: e.target.value,
      type: 'rejected',
    };
    promoDecline(data);
  };
  const handleModification = () => {
    var pchanges = { ...changes };
    for (var key in pchanges) {
      if (pchanges[key] !== null) {
        if (pchanges[key] === 'c') pchanges[key] = remarks[key] || 'Other';
        else pchanges[key] = options[key][pchanges[key]];
      } else {
        delete pchanges[key];
      }
    }

    var data = {
      id: promo.id,
      type: 'modification',
      mod: JSON.stringify(pchanges),
    };

    promoModification(data);
    closeModPanel();
  };

  const handleCreative = (e) => {
    setCreative(true);
    setSrc(e.target.getAttribute('value'));
  };

  const closeCreative = (e) => {
    setCreative(false);
  };

  const openModPanel = () => setModPanel(true);
  const closeModPanel = () => setModPanel(false);

  const handleCheck = (e) => {
    var val = e.target.value;
    var tpch = { ...changes };
    if (tpch[val] === null) tpch[val] = '0';
    else tpch[val] = null;

    setB(b ^ 1);
    setChanges(tpch);
  };

  const handleRadio = (e) => {
    var val = e.target.value;
    var tar = e.target.name;
    var tpch = { ...changes };

    tpch[tar] = val;
    setB(b ^ 1);
    setChanges(tpch);
  };

  const handleRemark = (e) => {
    var val = e.target.value;
    var tar = e.target.name;
    var tprm = { ...remarks };

    tprm[tar] = val;
    setB(b ^ 1);
    setRemarks(tprm);
  };

  return (
    <div className={classes.root}>
      <Dialog
        maxWidth="sm"
        open={creative}
        onClose={closeCreative}
        aria-labelledby="max-width-dialog-title"
      >
        <CardMedia component="img" src={crtvSrc} />
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={modp}
        onClose={closeModPanel}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent className="modPanel">
          <div className="text-4xl font-semibold text-eblue-800">
            Modifications & Remarks
          </div>
          <div className="changeCont my-6">
            <div className="entCont" data-hidden={promo.promoType !== 'story'}>
              <div className="chHead flex items-center">
                <Checkbox
                  color="primary"
                  checked={changes.title !== null}
                  value="title"
                  onChange={handleCheck}
                />
                <div className="text-lg font-semibold text-gray-700">TITLE</div>
              </div>
              <div className="px-4">
                <RadioGroup
                  row
                  value={changes.title + a[b]}
                  name="title"
                  className="actOpt"
                  onChange={handleRadio}
                >
                  <FormControlLabel
                    disabled={changes.title === null}
                    value="0"
                    control={<Radio disableRipple />}
                    label={options.title[0]}
                  />
                  <FormControlLabel
                    disabled={changes.title === null}
                    value="1"
                    control={<Radio disableRipple />}
                    label={options.title[1]}
                  />
                  <FormControlLabel
                    disabled={changes.title === null}
                    value="2"
                    control={<Radio disableRipple />}
                    label={options.title[2]}
                  />
                  <FormControlLabel
                    disabled={changes.title === null}
                    value="c"
                    control={<Radio disableRipple />}
                    label={options.title[3]}
                  />
                  <TextareaAutosize
                    minRows={2}
                    placeholder="Your other remarks here"
                    name="title"
                    className={changes.title + a[b]}
                    value={remarks.title}
                    onChange={handleRemark}
                  />
                </RadioGroup>
              </div>
            </div>
            <div className="entCont" data-hidden={promo.promoType !== 'story'}>
              <div className="chHead flex items-center">
                <Checkbox
                  color="primary"
                  checked={changes.description !== null}
                  value="description"
                  onChange={handleCheck}
                />
                <div className="text-lg font-semibold text-gray-700">
                  DESCRIPTION
                </div>
              </div>
              <div className="px-4">
                <RadioGroup
                  row
                  value={changes.description + a[b]}
                  name="description"
                  className="actOpt"
                  onChange={handleRadio}
                >
                  <FormControlLabel
                    disabled={changes.description === null}
                    value="0"
                    control={<Radio disableRipple />}
                    label={options.description[0]}
                  />
                  <FormControlLabel
                    disabled={changes.description === null}
                    value="1"
                    control={<Radio disableRipple />}
                    label={options.description[1]}
                  />
                  <FormControlLabel
                    disabled={changes.description === null}
                    value="2"
                    control={<Radio disableRipple />}
                    label={options.description[2]}
                  />
                  <FormControlLabel
                    disabled={changes.description === null}
                    value="c"
                    control={<Radio disableRipple />}
                    label={options.description[3]}
                  />
                  <TextareaAutosize
                    minRows={2}
                    placeholder="Your other remarks here"
                    name="description"
                    className={changes.description + a[b]}
                    value={remarks.description}
                    onChange={handleRemark}
                  />
                </RadioGroup>
              </div>
            </div>
            <div className="entCont">
              <div className="chHead flex items-center">
                <Checkbox
                  color="primary"
                  checked={changes.promoImage !== null}
                  value="promoImage"
                  onChange={handleCheck}
                />
                <div className="text-lg font-semibold text-gray-700">
                  CREATIVES
                </div>
              </div>
              <div className="px-4">
                <RadioGroup
                  row
                  value={changes.promoImage + a[b]}
                  name="promoImage"
                  className="actOpt"
                  onChange={handleRadio}
                >
                  <FormControlLabel
                    disabled={changes.promoImage === null}
                    value="0"
                    control={<Radio disableRipple />}
                    label={options.promoImage[0]}
                  />
                  <FormControlLabel
                    disabled={changes.promoImage === null}
                    value="1"
                    control={<Radio disableRipple />}
                    label={options.promoImage[1]}
                  />
                  <FormControlLabel
                    disabled={changes.promoImage === null}
                    value="c"
                    control={<Radio disableRipple />}
                    label={options.promoImage[2]}
                  />
                  <TextareaAutosize
                    minRows={2}
                    placeholder="Your other remarks here"
                    name="promoImage"
                    className={changes.promoImage + a[b]}
                    value={remarks.promoImage}
                    onChange={handleRemark}
                  />
                </RadioGroup>
              </div>
            </div>
            <div className="entCont" data-hidden={promo.promoType !== 'story'}>
              <div className="chHead flex items-center">
                <Checkbox
                  color="primary"
                  checked={changes.promoLogo !== null}
                  value="promoLogo"
                  onChange={handleCheck}
                />
                <div className="text-lg font-semibold text-gray-700">LOGO</div>
              </div>
              <div className="px-4">
                <RadioGroup
                  row
                  value={changes.promoLogo + a[b]}
                  name="promoLogo"
                  className="actOpt"
                  onChange={handleRadio}
                >
                  <FormControlLabel
                    disabled={changes.promoLogo === null}
                    value="0"
                    control={<Radio disableRipple />}
                    label={options.promoLogo[0]}
                  />
                  <FormControlLabel
                    disabled={changes.promoLogo === null}
                    value="1"
                    control={<Radio disableRipple />}
                    label={options.promoLogo[1]}
                  />
                  <FormControlLabel
                    disabled={changes.promoLogo === null}
                    value="c"
                    control={<Radio disableRipple />}
                    label={options.promoLogo[2]}
                  />
                  <TextareaAutosize
                    minRows={2}
                    placeholder="Your other remarks here"
                    name="promoLogo"
                    className={changes.promoLogo + a[b]}
                    value={remarks.promoLogo}
                    onChange={handleRemark}
                  />
                </RadioGroup>
              </div>
            </div>
            <div
              className="entCont"
              data-hidden={promo.costModel !== 'perClick'}
            >
              <div className="chHead flex items-center">
                <Checkbox
                  color="primary"
                  checked={changes.url !== null}
                  value="url"
                  onChange={handleCheck}
                />
                <div className="text-lg font-semibold text-gray-700">
                  Website/App Link
                </div>
              </div>
              <div className="px-4">
                <RadioGroup
                  row
                  value={changes.url + a[b]}
                  name="url"
                  className="actOpt"
                  onChange={handleRadio}
                >
                  <FormControlLabel
                    disabled={changes.url === null}
                    value="0"
                    control={<Radio disableRipple />}
                    label={options.url[0]}
                  />
                  <FormControlLabel
                    disabled={changes.url === null}
                    value="1"
                    control={<Radio disableRipple />}
                    label={options.url[1]}
                  />
                  <FormControlLabel
                    disabled={changes.url === null}
                    value="c"
                    control={<Radio disableRipple />}
                    label={options.url[2]}
                  />
                  <TextareaAutosize
                    minRows={2}
                    placeholder="Your other remarks here"
                    name="url"
                    className={changes.url + a[b]}
                    value={remarks.url}
                    onChange={handleRemark}
                  />
                </RadioGroup>
              </div>
            </div>
            <div className="entCont">
              <div className="chHead flex items-center">
                <Checkbox
                  color="primary"
                  checked={changes.product !== null}
                  value="product"
                  onChange={handleCheck}
                />
                <div className="text-lg font-semibold text-gray-700">
                  Product and Service you want to promote
                </div>
              </div>
              <div className="px-4">
                <RadioGroup
                  row
                  value={changes.product + a[b]}
                  name="product"
                  className="actOpt"
                  onChange={handleRadio}
                >
                  <FormControlLabel
                    disabled={changes.product === null}
                    value="0"
                    control={<Radio disableRipple />}
                    label={options.product[0]}
                  />
                  <FormControlLabel
                    disabled={changes.product === null}
                    value="c"
                    control={<Radio disableRipple />}
                    label={options.product[1]}
                  />
                  <TextareaAutosize
                    minRows={2}
                    placeholder="Your other remarks here"
                    name="product"
                    className={changes.product + a[b]}
                    value={remarks.product}
                    onChange={handleRemark}
                  />
                </RadioGroup>
              </div>
            </div>
          </div>
          <div className="flex justify-end px-4">
            <Button
              variant="contained"
              className="sbtn noShad"
              size="small"
              onClick={handleModification}
            >
              Submit
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {type !== 'pending' ? (
        <>
          <div className="flex flex-col items-start" style={{ width: 150 }}>
            <div className="text-gray-600 text-lg font-semibold">Promo Id</div>
            <h4 className="text-gray-600">ETark-{promo.id.substring(18)}</h4>
          </div>
          {promo.promoType === 'story' ? (
            <Divider style={{ height: 130, width: 1 }} />
          ) : null}
        </>
      ) : null}

      <div className={classes.innerDivs} style={{ width: 360 }}>
        <div style={{ maxWidth: 320 }}>
          {type === 'pending' ? (
            <div style={{ marginBottom: 10 }}>
              <h3 className="text-gray-600 text-lg">
                ETark-{promo.id.substring(18)}
              </h3>
            </div>
          ) : null}
          {promo.promoType === 'story' ? (
            <>
              <div className={classes.heading}>{promo.title}</div>
              <p className={classes.para}>{promo.description}</p>
            </>
          ) : null}
        </div>
        <Divider style={{ height: 130, width: 1 }} />
      </div>

      <div className={classes.innerDivs} style={{ width: 170 }}>
        <div>
          <div className={classes.heading}>Promo Budget</div>
          <p className={classes.para}>INR {promo.budget}</p>
          <div className={classes.heading}>Start Date</div>
          <p className={classes.para}>
            {new Date(promo.startDate).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </p>
        </div>
        <Divider style={{ height: 130, width: 1 }} />
      </div>

      <div className={classes.innerDivs} style={{ width: 200 }}>
        <div>
          {promo.url ? (
            <>
              <div className={classes.heading}>Website Link</div>
              <a
                className={classes.url}
                href={promo.url}
                rel="noreferrer"
                target="_blank"
              >
                {new URL(promo.url).hostname}
              </a>
              <div className="h-4"></div>
            </>
          ) : null}
          <div className={classes.heading}>Product/Service</div>
          <p className={classes.para}>{promo.product}</p>
        </div>
        <Divider style={{ height: 130, width: 1 }} />
      </div>

      <div className={classes.innerDivs} style={{ width: '150' }}>
        <div>
          <div className={classes.heading}>Creatives</div>
          <PhotoOutlinedIcon
            className={classes.crtv}
            onClick={handleCreative}
            value={promo.promoImage && promo.promoImage.url}
          />
          {promo.promoType === 'story' ? (
            <BrandingWatermarkIcon
              className={classes.crtv}
              onClick={handleCreative}
              value={promo.promoLogo && promo.promoLogo.url}
            />
          ) : null}
        </div>
      </div>

      {type === 'pending' ? (
        <div className={classes.innerDivs} style={{ width: 250 }}>
          <div className="ml-6 relative">
            <div className="flex w-full">
              <Button
                className="actBtn aprv"
                size="small"
                value={promo.id}
                onClick={handleApprove}
              >
                Approve
              </Button>
              <Button
                className="actBtn decl"
                size="small"
                value={promo.id}
                onClick={handleDecline}
              >
                Decline
              </Button>
            </div>
            {promo.mod === null ? (
              <div>
                <Button className="actBtn rmod" onClick={openModPanel}>
                  Request for Modification
                </Button>
              </div>
            ) : (
              <div className="absolute -top-12 right-0 text-gray-400 text-xss">
                Resubmitted after modification
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

Tab.propTypes = {
  promoApprove: PropTypes.func.isRequired,
  promoDecline: PropTypes.func.isRequired,
  promoModification: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  promoModification,
  promoApprove,
  promoDecline,
})(Tab);

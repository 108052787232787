import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#245FD3",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  logo: {
    position: "absolute",
    width: 56,
    left: 48,
    top: 36,
    paddingRight: 10
  },
  logoText: {
    color: "#F2F2F2",
    position: "absolute",
    width: 107,
    height: 28,
    left: 104,
    top: 45,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 16,
    letterSpacing: "0.02em",

  },
  image: {
    width: "92%",
    height: "550px",
  }
}));

const AuthSidebar = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div style={{position: 'relative'}}>
        <Link to="/">
          <img alt="logo" src="../assets/images/whitelogo.png"  className={classes.logo} />
          <span className={classes.logoText}>For Business</span>
        </Link>
      </div>
      <div style={{marginTop: 122, display: 'flex', alignItems: 'center', justifyContent:'center'}}>
        <img alt="auth-illustration" src="../assets/images/auth-sidebar.png" className={classes.image} />
      </div>
    </div>
  )
}

export default AuthSidebar;

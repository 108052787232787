import React from 'react';
import ActiveIcon from '@material-ui/icons/PlayCircleFilled';
import PropTypes from 'prop-types';
import Tab from './Tab';
import { connect} from 'react-redux';

const ActivePage = ({promos}) => {
  return (
    <div style={{ padding: 36}}>
      <div style={{fontSize: 40, fontWeight: '700', display: 'flex',alignItems: 'center'}}>
        Active Promos
        <ActiveIcon style={{fontSize: "inherit", marginLeft: 10, color: "green"}} />
      </div>
      {promos.map(promo =>{
        if(promo.status==='active'){
          return <Tab promo={promo} />;
        }
        else {
          return null;
        }
      })}
    </div>
  )
}

ActivePage.propTypes = {
  promos: PropTypes.array.isRequired
}

const mapStateToProps = (state) => ({
  promos: state.internal.promos
});

export default connect(mapStateToProps)(ActivePage);

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { logoutUser } from '../../redux/actions/authActions';
import MenuIcon from '@material-ui/icons/Menu';
import './home.scss';
import { Drawer } from '../helpers';
import { blurBackground } from '../../redux/actions/errorActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Solutions, SolutionsDropdown } from './styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '90%',
    margin: '20px 5%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  logo: {
    height: 60,
  },
  logoText: {
    color: '#185ADB',
    marginLeft: 10,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 16,
    letterSpacing: '0.02em',
  },
  btn: {
    textTransform: 'none',
    backgroundColor: '#185ADB',
    color: '#FFF',
    marginLeft: 40,
    padding: '8px 32px',
    boxShadow: '0px 2px 16px rgba(24, 90, 219, 0.4)',
  },
  iframe: {
    width: 'calc(100vw - 40vw)',
    height: 'calc(100vw - 67vw)',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 10vw)',
      height: 'calc(100vw - 50vw)',
    },
  },
  modal: {
    width: 550,
    height: 300,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '80vw',
      height: 200,
    },
  },
  modalLink: {
    color: '#185ADB',
    fontSize: 24,
    fontWeight: 'bold',
    textDecoration: 'none',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
}));

const Navbar = ({ logoutUser, isAuthenticated, setHow, home }) => {
  const classes = useStyles();
  const [openNav, setOpenNav] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openDropdown2, setOpenDropdown2] = useState(false);
  const [open, setOpen] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);

  const ref = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const handleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const handleDropdown2 = () => {
    setOpenDropdown2(!openDropdown2);
  };

  const handleLogout = () => {
    logoutUser();
  };
  const onClose = () => {
    setOpenNav(false);
    dispatch(blurBackground(false));
  };

  useEffect(() => {
    let handler = (event) => {
      if (!ref?.current?.contains(event.target)) {
        setOpenDropdown(false);
      }
    };
    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, []);

  //   useEffect(() => {
  //     console.log(location.pathname);
  //   }, [location.pathname]);
  return (
    <>
      <div className="nav">
        <div className="nav--icon">
          <MenuIcon
            onClick={() => {
              setOpenNav(true);
              dispatch(blurBackground(true));
            }}
          />
        </div>
        <div>
          <div className="nav--container">
            <img
              alt="logo"
              src="assets/icons/logo-final.svg"
              className="nav--logo"
            />
            <div className="nav--horz__line" />
            <div className="nav-logoText">For Business</div>
          </div>
        </div>
      </div>
      <Drawer open={openNav} onClose={onClose} color="#FFFFFF" width="17rem">
        <div className="nav--drawerContainer">
          <div className="nav--lineContainer">
            <div className="line1" />
            <div className="line2" />
            <div className="line3" />
          </div>
          <h1
            className="nav--text"
            onClick={() => {
              setHow(true);
              onClose();
            }}
          >
            HOW TO USE
          </h1>

          <Accordion
            className="accordion"
            style={{
              padding: '0',
              boxShadow: 'none',
              backgroundColor: '#FFF',
              marginTop: -1,
              border: 'none',
              fontSize: '14px',
              marginLeft: -16,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p className="nav--accordion">Solutions</p>
            </AccordionSummary>
            <AccordionDetails className="accordion--content">
              {home ? (
                <Link to="/"> ETark Promo</Link>
              ) : (
                <a
                  href="#etarkpromo"
                  onClick={() => {
                    setOpenNav(false);
                    dispatch(blurBackground(false));
                  }}
                >
                  ETark Promo
                </a>
              )}
              <Link to="/oneshot">
                <p
                  onClick={() => {
                    setOpenNav(false);
                    dispatch(blurBackground(false));
                  }}
                >
                  One Shot Resolution
                </p>
              </Link>
            </AccordionDetails>
          </Accordion>

          <h1
            className="nav--text"
            onClick={() => window.open('mailto:contact@etark.in', '_parent')}
          >
            CONTACT US
          </h1>

          {!isAuthenticated ? (
            <>
              <Button
                onClick={() => {
                  setOpenLoginModal(true);
                  setOpenNav(false);
                }}
                style={{
                  textDecoration: 'none',
                  color: '#52575C',
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                LOG IN
              </Button>
              <Button
                style={{ marginTop: 15 }}
                type="button"
                className="nav--btn"
                onClick={() => {
                  {
                    setOpen(true);
                    setOpenNav(false);
                  }
                }}
                variant="contained"
              >
                Get Started
              </Button>
            </>
          ) : (
            <>
              <Button
                className="nav--btn"
                onClick={() => setOpenNav(false)}
                href="/dashboard"
              >
                Dashboard
              </Button>
              <Button className="nav--btn" onClick={handleLogout}>
                Logout
              </Button>
            </>
          )}
        </div>
      </Drawer>
      <div className={classes.root}>
        <div>
          <NavLink to="/" style={{ textDecoration: 'none' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                alt="logo"
                src="assets/images/bluelogo.png"
                className={classes.logo}
              />
              <div className={classes.logoText}>For Business</div>
            </div>
          </NavLink>
        </div>
        <div
          style={{
            width: 400,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: '#52575C',
            fontSize: 16,
            fontWeight: 500,
          }}
        >
          <Button
            onClick={() => {
              setHow(true);
            }}
          >
            HOW TO USE
          </Button>
          {home ? (
            <Link to="/">
              <div>SOLUTIONS</div>
            </Link>
          ) : (
            <>
              <Solutions onClick={handleDropdown} open={openDropdown}>
                <div>SOLUTIONS</div>
              </Solutions>

              <SolutionsDropdown ref={ref}>
                <ul>
                  <li>
                    <a
                      onClick={() => setOpenDropdown(false)}
                      href="#etarkpromo"
                    >
                      ETark Promo
                    </a>
                  </li>
                  <li>
                    <Link to="/oneshot">One Shot Resolution</Link>
                  </li>
                </ul>
              </SolutionsDropdown>
            </>
          )}
          <div>
            <a
              style={{ cursor: 'pointer' }}
              href="mailto:contact@etark.in"
              onClick={() => window.open('mailto:contact@etark.in', '_parent')}
            >
              CONTACT US
            </a>
          </div>
        </div>
        <div>
          {!isAuthenticated ? (
            <>
              <Button
                style={{ textDecoration: 'none', color: '#52575C' }}
                onClick={() => setOpenLoginModal(true)}
              >
                LOG IN
              </Button>
              <Button
                type="button"
                className={classes.btn}
                onClick={() => setOpen(true)}
                variant="contained"
              >
                Get Started
              </Button>
            </>
          ) : (
            <>
              <Button
                style={{ marginRight: '1em' }}
                color="primary"
                href="/dashboard"
              >
                Dashboard
              </Button>
              <Button type="button" color="primary" onClick={handleLogout}>
                Logout
              </Button>
            </>
          )}
        </div>
      </div>
      <Dialog
        onClose={() => {
          setOpen(false);
          dispatch(blurBackground(false));
        }}
        aria-labelledby="get-started-dialog"
        open={open}
      >
        <div className={classes.modal}>
          <div style={{ fontSize: 20, fontWeight: 500, color: '#888889' }}>
            What would you like to use?
          </div>
          <div style={{ marginTop: 20 }}>
            <Link to="/signup" className={classes.modalLink}>
              ETark Promo
            </Link>
          </div>
          <div style={{ marginTop: 20 }}>
            {location.pathname === '/oneshot' ? (
              <div
                className={classes.modalLink}
                onClick={() => {
                  setOpen(false);
                  dispatch(blurBackground(false));
                }}
              >
                One Shot Resolution
              </div>
            ) : (
              <Button
                to="/oneshot"
                onClick={() => {
                  history.push('/oneshot');
                  setOpen(false);
                }}
                className={classes.modalLink}
              >
                One Shot Resolution
              </Button>
            )}
          </div>
        </div>
      </Dialog>
      <Dialog
        onClose={() => {
          setOpenLoginModal(false);
          dispatch(blurBackground(false));
        }}
        aria-labelledby="get-started-dialog"
        open={openLoginModal}
      >
        <div className={classes.modal}>
          <div style={{ fontSize: 20, fontWeight: 500, color: '#888889' }}>
            What would you like to use?
          </div>
          <div style={{ marginTop: 20 }}>
            <Link to="/signin" className={classes.modalLink}>
              ETark Promo
            </Link>
          </div>
          <div style={{ marginTop: 20 }}>
            {location.pathname === '/oneshot' ? (
              <div
                className={classes.modalLink}
                onClick={() => {
                  setOpenLoginModal(false);
                  dispatch(blurBackground(false));
                }}
              >
                One Shot Resolution
              </div>
            ) : (
              <Link to="/oneshot" className={classes.modalLink}>
                One Shot Resolution
              </Link>
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

Navbar.propTypes = {
  isAuthenticated: PropTypes.bool,
};

export default connect(mapStateToProps, {
  logoutUser,
})(Navbar);

import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import { closeDialog } from '../../redux/actions/verifyActions';
import { phoneVerify } from '../../redux/actions/verifyActions';
import { makeStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: "24px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "80vw",
    },
  },
  modalLink: {
    color: "#185ADB",
    fontSize: 24,
    fontWeight: "bold",
    textDecoration: "none",
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  }
}));

const VerifyDialog = (props) => {
  const { isOpen, type, closeDialog, phoneVerify } = props;
  const [otpValue, setOtpValue] = useState('');
  const classes = useStyles();
  const history = useHistory();

  const handleSuccess = () => {
    closeDialog();
    history.push("/signin");
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    let otpData = {
      phoneToken: otpValue,
      id: localStorage.getItem('id')
    }
    phoneVerify(otpData);
  }

  const VerifyType = ({type}) => {
    if (type==='success'){
      return (
        <div className="flex flex-col items-center">
          <img src="assets/images/email.png" alt="success" />
          <h2 style={{marginTop: 20}}>Congratulations!!</h2>
          <p style={{color: "#545357", marginTop: 10, marginBottom: 4}}>Your phone has been is verified</p>
          <p style={{color: "#545357", marginTop: 2, marginBottom: 10}}>
            Check your <mark>mail</mark> to <mark>complete the verification</mark> process.
          </p>
        </div>
      );
    }else if(type==='otp'){
      return (
        <>
          <p style={{color: "#696777", fontWeight: "bold"}}>
            Enter the OTP sent to the Registered Phone Number
          </p>
          <form onSubmit={handleSubmit} style={{textAlign: 'center'}}>
            <div style={{ marginTop: 20, marginBottom: 20}}>
              <TextField
                autoFocus
                style={{width: 200}}
                name="otpValue"
                size="medium"
                variant="outlined"
                value={otpValue}
                type="tel"
                onChange={(event) => setOtpValue(event.target.value)}
                inputProps={{
                  style: {
                    textAlign: "center",
                    fontSize: 20,
                  }
                }}
              />
            </div>
            <p style={{color: "#fefefe", fontSize: 12, fontWeight: '600'}}>
              Didn’t Recieve OTP? Try again <br/><br/>
            </p>
              <Button type="submit" variant="contained" color="primary" size="small">
                Check to Verify</Button>
          </form>
        </>
      );
    }else{
      return null;
    }
  }

  return (
    <Dialog disableEnforceFocus
      aria-labelledby="verify-dialog" open={isOpen}>
      <div className={classes.modal} id="otpdia">
        <VerifyType  type={type} />
      </div>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  isOpen: state.verify.isOpen,
  type: state.verify.type,
});

VerifyDialog.propTypes = {
  isOpen: PropTypes.bool,
  type: PropTypes.string.isRequired,
  closeDialog: PropTypes.func.isRequired,
  phoneVerify: PropTypes.func.isRequired
};

export default connect(mapStateToProps, { closeDialog, phoneVerify })(VerifyDialog);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { registerUser } from '../../redux/actions/authActions';
import { clearErrors } from '../../redux/actions/errorActions';
import { requestVerify } from '../../redux/actions/verifyActions';
import { clearVerifyErrors } from '../../redux/actions/verifyActions';
import { StyledTextInput } from './styles';
import AuthSidebar from '../shared/AuthSidebar';
import { Link, Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import VerifyDialog from './VerifyDialog';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import './signup.scss';
import { Modal } from './../helpers';
import { PrivacyModal } from '../Home/Privacy';

const SignUp = (props) => {
  const {
    isLoading,
    isAuthenticated,
    error,
    verify,
    registerUser,
    clearErrors,
    requestVerify,
    clearVerifyErrors,
  } = props;

  const [fullName, setFullName] = useState('');
  const [identity, setIdentity] = useState('Individual');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [referralId, setRefId] = useState('NOREF');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [validationError, setValidationError] = useState('');

  const [privacyModal, setPrivacyModal] = useState(false);

  const [checked, setChecked] = useState(false);
  const [showPassword, setPasswordVisiblity] = useState(false);
  const handleClickShowPassword = (event) => {
    event.preventDefault();
    setPasswordVisiblity(!showPassword);
  };

  useEffect(() => {
    clearErrors();
  }, [clearErrors]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setValidationError('');
    clearErrors();
    const newUser = {
      fullName: fullName,
      ...(identity === 'Organization' && { company: company }),
      ...(identity === 'Individual' && { company: 'Individual' }),
      email: email,
      phone: phone,
      password: password,
      referralId: referralId,
    };
    if (password.length <= 6) {
      setValidationError('Password should contain more than six characters');
    } else if (password !== passwordConfirm) {
      setValidationError('Please enter same password in both the fields');
    } else if (phone.length > 10 || phone.length < 10) {
      setValidationError('Please enter a valid phone number');
    } else {
      registerUser(newUser);
      setFullName('');
      setPassword('');
      setCompany('');
      setEmail('');
      setPhone('');
      setPasswordConfirm('');
    }
  };

  const pattern = {
    phone: '^[0-9]{10}',
    email: '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$',
  };

  const checkValid = (val, patobj) => {
    var patt = new RegExp(pattern[patobj]);
    var matched = patt.test(val);
    if (matched) return true;
    else return false;
  };

  const handleVerifyError = () => {
    clearVerifyErrors();
    requestVerify(localStorage.getItem('id'));
  };

  const checkDisable = () => {
    var res = true;
    res &= email !== '';
    res &= checkValid(email, 'email');
    res &= phone !== '';
    res &= checkValid(phone, 'phone');
    res &= fullName !== '';
    res &=
      password !== '' && passwordConfirm !== '' && password === passwordConfirm;
    res &=
      identity !== 'Organization' ||
      (identity === 'Organization' && company !== '');
    res &= checked;
    return res;
  };

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  } else {
    return (
      <div className="signup">
        <div className="authBanner__signup">
          <AuthSidebar />
        </div>

        <VerifyDialog />

        <div className="signup__container">
          <div className="already--signup">
            Already a member?
            <Link
              to="/signin"
              style={{
                textDecorationLine: 'none',
                color: '#245FD3',
                marginLeft: 5,
              }}
            >
              Sign In
            </Link>
          </div>
          <div className="signup__container--upper">
            <div className="signup--title">Sign Up to ETark</div>

            {/*Auth Error Alert*/}
            {error.msg.message && !isLoading ? (
              <Alert
                severity="error"
                className="width-sm"
                style={{ marginTop: 20 }}
              >
                {error.msg.message}
              </Alert>
            ) : null}

            {/*Auth Error Alert*/}
            {verify.vmsg.message && !verify.isVerifying ? (
              <Alert
                severity="error"
                className="width-sm"
                style={{ marginTop: 20 }}
              >
                {verify.vmsg.message}
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleVerifyError}
                  size="small"
                >
                  Verify Again
                </Button>
              </Alert>
            ) : null}

            {/*Validation Error Alert*/}
            {validationError ? (
              <Alert severity="error" className="width-sm">
                {validationError}
              </Alert>
            ) : null}

            {/*SignUp form*/}
            <form style={{ marginTop: 20 }} onSubmit={handleSubmit}>
              <div className="form__upper__section">
                <div style={{ marginRight: 30 }}>
                  <div className="label--signup">Full Name</div>
                  <StyledTextInput
                    variant="outlined"
                    size="small"
                    name="fullName"
                    id="fullName"
                    value={fullName}
                    onChange={(event) => setFullName(event.target.value)}
                  />
                </div>
                <div>
                  <div className="label--signup">
                    How do you identify yourself ?
                  </div>
                  <RadioGroup
                    aria-label="identity"
                    name="identity"
                    value={identity}
                    onChange={(event) => setIdentity(event.target.value)}
                  >
                    <div style={{ display: 'flex' }}>
                      <FormControlLabel
                        value="Individual"
                        control={<Radio color="primary" />}
                        label="Individual"
                        className="label--signup"
                      />
                      <FormControlLabel
                        value="Organization"
                        control={<Radio color="primary" />}
                        label="Organization"
                        className="label--signup"
                      />
                    </div>
                  </RadioGroup>
                </div>
              </div>

              {identity === 'Organization' ? (
                <>
                  <div className="label--signup">
                    Legal name of your company
                  </div>
                  <StyledTextInput
                    required
                    variant="outlined"
                    size="small"
                    name="company"
                    id="company"
                    value={company}
                    onChange={(event) => setCompany(event.target.value)}
                  />
                </>
              ) : null}

              <div className="label--signup">Official Email Address</div>
              <StyledTextInput
                required
                variant="outlined"
                size="small"
                type="email"
                name="email"
                id="email"
                error={!checkValid(email, 'email') && email !== ''}
                helperText={
                  !checkValid(email, 'email') && email !== ''
                    ? 'Invalid Email'
                    : ''
                }
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />

              <div className="label--signup">Phone Number</div>
              <StyledTextInput
                required
                variant="outlined"
                size="small"
                type="tel"
                name="phone"
                id="phone"
                value={phone}
                error={!checkValid(phone, 'phone') && phone !== ''}
                helperText={
                  !checkValid(phone, 'phone') && phone !== ''
                    ? 'Invalid Phone'
                    : ''
                }
                onChange={(event) => setPhone(event.target.value)}
              />

              <div className="signup__section--password">
                <div>
                  <div className="label--signup">Password</div>
                  <StyledTextInput
                    required
                    //   maxWidth="340px"
                    variant="outlined"
                    size="small"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    id="password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </div>
                <div className="signup__section--passwordConfirm">
                  <div className="label--signup">Confirm Password</div>
                  <StyledTextInput
                    variant="outlined"
                    size="small"
                    name="passwordConfirm"
                    type={showPassword ? 'text' : 'password'}
                    value={passwordConfirm}
                    error={password !== passwordConfirm}
                    helperText={
                      password !== passwordConfirm
                        ? "Password doesn't match"
                        : ''
                    }
                    onChange={(event) => setPasswordConfirm(event.target.value)}
                  />
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    className="eyeIcon--signup"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </div>
              </div>

              <div>
                {referralId === 'NOREF' ? (
                  <Button
                    className="refbtn"
                    color="primary"
                    onClick={() => {
                      setRefId('');
                    }}
                  >
                    Got Referral ID?
                  </Button>
                ) : (
                  <TextField
                    className="refbtn"
                    variant="outlined"
                    label="Enter Referral ID"
                    size="small"
                    name="referralId"
                    type="text"
                    value={referralId}
                    onChange={(event) => setRefId(event.target.value)}
                  />
                )}
              </div>

              <div className="checkbox__container">
                <Checkbox
                  color="primary"
                  size="small"
                  inputProps={{ 'aria-label': 'privacy checkbox' }}
                  name="accept"
                  checked={checked}
                  onChange={(e) => setChecked(e.target.checked)}
                />
                <span className="checkbox__container--text">
                  Creating an account means you’re okay with our Terms and
                  Conditions of Service and{' '}
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => setPrivacyModal(true)}
                  >
                    Privacy Policy
                  </span>
                </span>
              </div>
              <Modal
                open={privacyModal}
                handleClose={() => setPrivacyModal(false)}
              >
                <div>
                  <PrivacyModal handleClose={() => setPrivacyModal(false)} />
                </div>
              </Modal>

              <div style={{ position: 'relative' }}>
                {!isLoading ? (
                  <Button
                    disabled={!checkDisable()}
                    className="crtbtn"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Create Account
                  </Button>
                ) : (
                  <div className="loader--signup">
                    <CircularProgress size={35} />
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error,
  verify: state.verify,
});

SignUp.propTypes = {
  isLoading: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  error: PropTypes.object.isRequired,
  verify: PropTypes.object.isRequired,
  registerUser: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  requestVerify: PropTypes.func.isRequired,
  clearVerifyErrors: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  registerUser,
  clearErrors,
  requestVerify,
  clearVerifyErrors,
})(SignUp);

import React from 'react';
import SignUp from './Authentication/SignUp';
import SignIn from './Authentication/SignIn';
import VerifyEmail from './Authentication/VerifyEmail';
import ResetPass from './Authentication/ResetPass';
import Home from './Home/Home';
import OneShot from './OneShot';
import ProtectedRoute from './shared/ProtectedRoute';
import IntMain from './InternalDashboard/IntMain';
import { Switch, Route, Redirect } from 'react-router-dom';
import store from '../redux/ConfigureStore';
import { loadUser } from '../redux/actions/authActions';
import { loadIntUser } from '../redux/actions/internalActions';
import { Privacy, Disclaimer, Refund, Intellectual } from './Home';

class Main extends React.Component {
  componentDidMount() {
    store.dispatch(loadUser());
    store.dispatch(loadIntUser());
  }

  render() {
    var query = window.location.search;

    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/oneshot" component={OneShot} />
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/signin" component={SignIn} />
        <Route exact path="/verify/email" component={VerifyEmail} />
        <Route exact path="/resetpassword" component={ResetPass} />
        <Route exact path="/internal" component={IntMain} />

        <Route path="/privacy-policy" component={Privacy} />
        <Route path="/disclaimers" component={Disclaimer} />
        <Route path="/intellectual-property" component={Intellectual} />
        <Route path="/refund-and-cancellation-policy" component={Refund} />

        <ProtectedRoute query={query} />
        <Redirect to="/" />
      </Switch>
    );
  }
}

export default Main;

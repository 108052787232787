import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import PieChartIcon from '@material-ui/icons/PieChart';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const useStyles = makeStyles((theme) => ({
  TabsBtn1: {
    border: 'none',
    backgroundColor: 'transparent',
    fontSize: 14,
    fontWeight: 700,
    color: '#52575C',
    marginBottom: 20,
    textAlign: 'left',
  },
  TabsBtn2: {
    border: 'none',
    backgroundColor: 'transparent',
    fontSize: 14,
    fontWeight: 700,
    color: '#336CFB',
    marginBottom: 20,
    textAlign: 'left',
  },
}));

const Icon = ({ type }) => {
  if (type === 'create') {
    return (
      <CreateNewFolderIcon
        size="small"
        style={{ marginRight: 10, fontSize: 18 }}
      />
    );
  } else if (type === 'existing') {
    return (
      <PieChartIcon size="small" style={{ marginRight: 10, fontSize: 18 }} />
    );
  } else {
    return (
      <CheckBoxIcon size="small" style={{ marginRight: 10, fontSize: 18 }} />
    );
  }
};

export function TabsBtn1({ text, onClickFunction, type }) {
  const classes = useStyles();
  return (
    <>
      <button
        onClick={onClickFunction}
        className={classes.TabsBtn1}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Icon type={type} /> {text}{' '}
      </button>
    </>
  );
}

export function TabsBtn2({ text, onClickFunction, type }) {
  const classes = useStyles();
  return (
    <>
      <button
        onClick={onClickFunction}
        className={classes.TabsBtn2}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Icon type={type} /> {text}{' '}
      </button>
    </>
  );
}

import React from 'react';
//import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import NotificationsIcon from '@material-ui/icons/Notifications';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 26,
    background: 'linear-gradient(180deg, #353A3F 0%, #25282D 100%)',
    borderRadius: 8,
    marginTop: 0,
    color: '#fff',
    [theme.breakpoints.down('xs')]: {
      padding: '26px 14px',
      width: 300,
    },
  },
  bannerPlace: {
    width: 308,
    height: 120,
    background:
      'linear-gradient(54.77deg, rgba(12, 30, 68, 0.2) 27.97%, rgba(94, 137, 230, 0.2) 93.21%), #516FAA',
    borderRadius: 8,
    marginTop: 15,
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      width: 272,
    },
  },
  storiesPlace: {
    width: 308,
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 15,
    [theme.breakpoints.down('xs')]: {
      width: 272,
    },
  },
  story: {
    width: 45,
    height: 45,
    borderRadius: '50%',
    border: '2px solid #516FAA',
    backgroundColor: '#33383C',
    boxShadow:
      '4px 4px 16px rgba(23, 24, 26, 0.5), -4px -4px 20px -2px rgba(158, 160, 168, 0.35), 4px 4px 16px -4px rgba(13, 13, 15, 0.4), inset -1px -1px 2px rgba(102, 102, 109, 0.14), inset 1px 1px 2px rgba(16, 17, 18, 0.1)',
  },
  name: {
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  helperText: {
    fontSize: 13,
    color: '#EDEFF3',
    [theme.breakpoints.down('xs')]: {
      fontSize: 10.5,
    },
  },
  personIcon: {
    color: '#B6A654',
    fontSize: 24,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  bellIcon: {
    color: '#B6A654',
    fontSize: 24,
    marginLeft: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
}));

const BannerPreview = ({ previewPicture, previewLogo }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ lineHeight: 0.8 }}>
          <small>Hi,</small> <b class={classes.name}>Aanchal</b> <br />
          <span className={classes.helperText}>
            Have you got any complaints today?
          </span>
        </div>
        <div>
          <span>
            <PersonIcon className={classes.personIcon} />
          </span>
          <span>
            <NotificationsIcon className={classes.bellIcon} style={{}} />
          </span>
        </div>
      </div>
      <div className={classes.bannerPlace}>
        {previewPicture ? (
          <img src={previewPicture} alt="" style={{ width: 'inherit' }} />
        ) : null}
      </div>
      <div className={classes.storiesPlace}>
        <div className={classes.story}></div>
        <div className={classes.story}></div>
        <div className={classes.story}>
          {previewLogo ? (
            <img
              src={previewLogo}
              alt=""
              style={{ height: '38px', width: '40px', borderRadius: '50%' }}
            />
          ) : null}
        </div>
        <div className={classes.story}></div>
        <div className={classes.story}></div>
      </div>
    </div>
  );
};

export default BannerPreview;

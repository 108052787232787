import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import Navbar from './Home/Navbar';
import { Footer } from './Home';
import { Modal } from './helpers';
import { useSelector } from 'react-redux';

const prodapi = process.env.REACT_APP_API;

const useStyles = makeStyles((theme) => ({
  btn: {
    color: '#245fd3',
    backgroundColor: '#fff',
    textTransform: 'capitalize',
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: 'Poppins',
    marginTop: '1rem',
    padding: '.5rem 2rem',
    transition: 'background 0.4s, color 1s',
    '&:hover': {
      backgroundColor: '#ffffff99',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      padding: '.5rem 1.5rem',
    },
  },

  input: {
    width: '35%',
    minWidth: '50%',
    '& .MuiOutlinedInput-root': {
      width: '100%',
      height: '2rem',
      fontSize: '14px',
    },
    '& .MuiFormHelperText-contained': {
      color: 'red',
      marginRight: '0',
      fontWeight: 600,
      marginLeft: 10,
      fontSize: '11px !important',
      [theme.breakpoints.down('xl')]: {
        minWidth: 'max-content',
      },
    },
  },

  submitBtn: {
    color: '#fff',
    backgroundColor: '#245fd3',
    padding: '.5rem 2rem',
    position: 'absolute',
    bottom: '5%',
    right: '9.2%',
    width: '220px',
    textTransform: 'none',
    fontFamily: 'Poppins',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#245fd3',
    },
    [theme.breakpoints.down('sm')]: {
      right: '5%',
      width: 160,
    },
  },

  iframe: {
    width: 'calc(100vw - 30vw)',
    height: 'calc(100vw - 67vw)',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100vw - 1vw)',
      height: 'calc(100vw - 45vw)',
    },
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 0vw)',
      height: 'calc(100vw - 45vw)',
    },
  },
}));

const OneShot = () => {
  const classes = useStyles();
  const location = useLocation();
  const error = useSelector((state) => state.error);
  const [openModal, setOpenModal] = useState(false);
  const [done, setDone] = useState(false);
  const [fullName, setFullName] = useState('');
  const [orgName, setOrgName] = useState('');
  const [avgComplaints, setAvgComplaints] = useState('');
  const [email, setEmail] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [err, setErr] = useState('');

  const [how, setHow] = useState(false);
  const [open, setOpen] = useState(false);

  const pattern = {
    phone: '^[0-9]{10}',
    email: '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$',
  };

  const checkEmail = () => {
    var patt = new RegExp(pattern.email);
    var matched = patt.test(email);
    if (matched) return 'Correct';
    if (email.length === 0) return 'Correct';
  };

  const handlePhoneNo = () => {
    var patt = new RegExp(pattern.phone);
    var matched = patt.test(contactNo);
    if (matched) return 'Correct';
  };

  const handleContactNo = (e) => {
    if (isNaN(e.target.value)) return false;
    setContactNo(e.target.value);
  };

  const handleAvgComplaint = (e) => {
    if (isNaN(e.target.value) || e.target.value === '0' || e.target.value === 0)
      return false;
    setAvgComplaints(e.target.value);
  };

  const disable =
    fullName.length === 0 ||
    orgName.length === 0 ||
    avgComplaints.length === 0 ||
    email.length === 0 ||
    contactNo.length === 0 ||
    handlePhoneNo() !== 'Correct' ||
    checkEmail() !== 'Correct';

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      var data = {
        name: fullName,
        organization: orgName,
        complaints: avgComplaints,
        email: email,
        contact_number: contactNo,
      };
      const res = await axios.post(`${prodapi}/one-shot-inquiries`, data);

      console.log(res);
      if (res.status === 200) {
        setDone(true);
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      setErr(error.response.data.message);
    }
  };

  useEffect(() => {
    setOpenModal(false);
    setDone(false);
    setFullName('');
    setOrgName('');
    setAvgComplaints('');
    setEmail('');
    setContactNo('');
    setErr('');
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Dialog
        onClose={() => setOpen(false)}
        aria-labelledby="get-started-dialog"
        open={open}
      >
        <div className={classes.modal}>
          <div style={{ fontSize: 20, fontWeight: 500, color: '#888889' }}>
            What would you like to use?
          </div>
          <div style={{ marginTop: 20 }}>
            <Link to="/signup" className={classes.modalLink}>
              ETark Promo
            </Link>
          </div>
          <div style={{ marginTop: 20 }}>
            <Link to="/oneshot" className={classes.modalLink}>
              One Shot Resolution
            </Link>
          </div>
        </div>
      </Dialog>
      <Navbar how={how} setHow={setHow} home={true} />
      <Modal
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
          setDone(false);
          setFullName('');
          setOrgName('');
          setAvgComplaints('');
          setEmail('');
          setContactNo('');
          setErr('');
        }}
      >
        <>
          <div className="modal">
            <CloseIcon
              className="modal-close-icon"
              onClick={() => {
                setOpenModal(false);
                setDone(false);
                setFullName('');
                setOrgName('');
                setAvgComplaints('');
                setEmail('');
                setContactNo('');
                setErr('');
              }}
            />
            {done ? (
              <div className="success-page">
                <img src="assets/images/success-illustration.svg" />
                <h1>Thanks!!</h1>
                <h3>We'll get back to you shortly</h3>
              </div>
            ) : (
              <>
                <p className="oneshot-err">{err && err}</p>
                <div className="modal-content">
                  <div className="margin">
                    <span>
                      <FiberManualRecordIcon />
                      <p>Your Full Name</p>
                    </span>
                    <TextField
                      className={classes.input}
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      variant="outlined"
                    />
                  </div>
                  <div className="margin">
                    <span>
                      <FiberManualRecordIcon />
                      <p>Your Organization's name</p>
                    </span>
                    <TextField
                      value={orgName}
                      onChange={(e) => setOrgName(e.target.value)}
                      className={classes.input}
                      variant="outlined"
                    />
                  </div>
                  <div className="margin">
                    <span>
                      <FiberManualRecordIcon />
                      <p className="org-text">
                        Organization's average consumer complaints in a month
                      </p>
                    </span>
                    <TextField
                      type="text"
                      value={avgComplaints}
                      onChange={handleAvgComplaint}
                      className={classes.input}
                      variant="outlined"
                    />
                  </div>
                  <div className="margin">
                    <span>
                      <FiberManualRecordIcon />
                      <p>Official email ID</p>
                    </span>
                    <TextField
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className={classes.input}
                      variant="outlined"
                      helperText={
                        checkEmail() === 'Correct'
                          ? ''
                          : 'Please enter a valid Email'
                      }
                    />
                  </div>
                  <div className="margin">
                    <span>
                      <FiberManualRecordIcon />
                      <p>Contact number</p>
                    </span>
                    <TextField
                      type="text"
                      value={contactNo}
                      onChange={handleContactNo}
                      className={classes.input}
                      variant="outlined"
                      helperText={
                        (handlePhoneNo() === 'Correct' &&
                          contactNo.length <= 10) ||
                        contactNo.length === 0
                          ? ''
                          : 'Enter a valid Number'
                      }
                    />
                  </div>
                  <Button
                    disabled={disable}
                    className={`${classes.submitBtn} ${
                      disable && 'disabled-btn'
                    }`}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </>
            )}
          </div>
        </>
      </Modal>
      <div
        className="oneshot"
        style={{ filter: error.bluredState ? 'blur(3px)' : 'blur(0px)' }}
      >
        <Link to="/">
          <span>
            <HomeRoundedIcon />
            <p>return to home page</p>
          </span>
        </Link>

        <h1>One Shot Resolution</h1>
        <div className="oneshot-container">
          <img
            className="os-tower1"
            src="/assets/images/landingPage/lower-tower1.svg"
            alt=""
          />
          <img
            className="os-tower2"
            src="/assets/images/landingPage/lower-tower2.svg"
            alt=""
          />

          <img className="" src="/assets/images/one-shot.svg" alt="" />

          {/* will use that after video comes in */}
          {/* <div className="iframe-container">
            <h1> </h1>
          </div> */}
          <div className="blur-radius" />
        </div>

        <div>
          <div className="oneshot-text-section">
            <div>
              <div>
                <img
                  src="assets/icons/pane2a.png"
                  className="solution--icon"
                  alt=""
                />
                <span className="mt">
                  Customer complaint resolution time reduced to less than 1
                  second thus saving cost
                </span>
              </div>
              <div>
                <img
                  src="assets/icons/pane2b.png"
                  className="solution--icon"
                  alt=""
                />
                <span className="mt">
                  Increase in net promoter score & brand equity
                </span>
              </div>
              <div>
                <img
                  src="assets/icons/pane2c.png"
                  className="solution--icon"
                  alt=""
                />
                <span className="mt">
                  Prevent your consumer complaints from turning into a legal
                  risk
                </span>
              </div>
              <div>
                <img
                  src="assets/icons/pane2d.png"
                  className="solution--icon"
                  alt=""
                />
                <span className="mt">
                  Listing your online business for free in our mobile app for
                  sales
                </span>
              </div>
            </div>
            <img src="assets/images/home4.png" className="oneshot-png" alt="" />
          </div>
          <div className="oneshot-text-section">
            <div>
              <div>
                <img
                  src="/assets/icons/vector-3.svg"
                  alt=""
                  className="solution--icon"
                />
                <span>
                  No customer care follow up thus bring down cost and time of
                  resolution
                </span>
              </div>
              <br />
              <div>
                <img
                  src="/assets/icons/vector-4.svg"
                  alt=""
                  className="solution--icon"
                />
                <span>
                  Customers trust our resolution because we are an independent
                  platform
                </span>
              </div>
              <br />

              <div>
                <img
                  src="/assets/icons/vector-5.svg"
                  alt=""
                  className="solution--icon"
                />
                <span>
                  Save your business from the embarrasment of the customer
                  complaining on social media/review sites
                </span>
              </div>
            </div>
            <img src="assets/images/home6.png" className="oneshot-png" alt="" />
          </div>
        </div>
      </div>
      <div className="inquire-section">
        <img
          className="inquire-tower1"
          src="/assets/images/landingPage/lower-tower1.svg"
          alt=""
        />
        <img
          className="inquire-tower2"
          src="/assets/images/landingPage/lower-tower2.svg"
          alt=""
        />
        <h1>Want to give this a “shot” and transform your business?</h1>
        <Button onClick={() => setOpenModal(true)} className={classes.btn}>
          Inquire now
        </Button>
      </div>
      <Footer home="home" />
    </>
  );
};

export default OneShot;

import axios from 'axios';
import {
  USER_LOADING,
  SIGNIN_FAIL,
  SIGNIN_SUCCESS,
  SIGNUP_FAIL,
  SIGNUP_SUCCESS,
  LOGOUT_SUCCESS,
  USER_LOGGEDIN,
  USER_NOT_LOGGEDIN,
} from './ActionTypes';
import { returnErrors } from './errorActions';
import { requestVerify } from './verifyActions';

const prodapi = process.env.REACT_APP_API;

// Check jwt and load user
export const loadUser = () => (dispatch) => {
  if (localStorage.getItem('jwt')) {
    dispatch({ type: USER_LOGGEDIN });
  } else {
    dispatch({ type: USER_NOT_LOGGEDIN });
  }
};

//register user
export const registerUser =
  ({ fullName, company, email, phone, password }) =>
  (dispatch) => {
    //headers
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    //request body
    const body = JSON.stringify({ fullName, company, email, phone, password });

    dispatch({ type: USER_LOADING });

    axios
      .post(`${prodapi}/buszs/register`, body, config)
      .then((res) => {
        dispatch({
          type: SIGNUP_SUCCESS,
          payload: res.data,
        });
        //console.log(res.data.account.id);
        dispatch(requestVerify(res.data.account.id));
      })
      .catch((error) => {
        dispatch(returnErrors(error.response.data, error.response.status));
        dispatch({ type: SIGNUP_FAIL });
      });
  };

//login user
export const loginUser =
  ({ email, phone, password }) =>
  (dispatch) => {
    //headers
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    //request body
    const body = JSON.stringify({ email, phone, password });
    dispatch({ type: USER_LOADING });

    axios
      .post(`${prodapi}/buszs/login`, body, config)
      // .post(`https://testapi.etark.in/buszs/login`, body, config)
      .then((res) => {
        dispatch({
          type: SIGNIN_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch(
          returnErrors(
            error.response.data,
            error.response.status,
            'SIGNIN_FAIL'
          )
        );
        dispatch({ type: SIGNIN_FAIL });
      });
  };

//logout user
export const logoutUser = () => {
  return {
    type: LOGOUT_SUCCESS,
  };
};

import axios from 'axios';
import {
  INTUSER_LOADING,
  INTSIGNIN_FAIL,
  INTSIGNIN_SUCCESS,
  INTLOGOUT_SUCCESS,
  INTUSER_LOGGEDIN,
  INTUSER_NOT_LOGGEDIN,
  GET_INTPROMOS,
  INTPROMOS_FAIL,
  ACTION_FAIL,
  ACTION_SUCCESS
} from './ActionTypes';
import { returnErrors } from './errorActions';

const prodapi = process.env.REACT_APP_API

// Check jwt and load user
export const loadIntUser = () => (dispatch) => {
  if(localStorage.getItem('jwtInt')){
    dispatch({type: INTUSER_LOGGEDIN});
  }else{
    dispatch({type: INTUSER_NOT_LOGGEDIN});
  }
};


export const loginIntUser = ({email, password}) => (dispatch) => {
  //headers
  const config={
    headers: {
      'Content-Type': 'application/json'
    }
  }
  //request body
  const body = JSON.stringify({email, password});
  dispatch({type: INTUSER_LOADING});

  axios.post(`${prodapi}/vpromos/login`, body, config).then(res => {
    dispatch({
      type: INTSIGNIN_SUCCESS,
      payload: res.data
    });
  }).catch(error =>{
    dispatch(returnErrors(error.response.data, error.response.status));
    dispatch({type: INTSIGNIN_FAIL});
  });
};

export const logoutIntUser = () => {
  console.log('btn reached');
  return {
    type: INTLOGOUT_SUCCESS
  };
};

export const getIntPromos = () => dispatch => {
  axios.get(`${prodapi}/promos`, {
    params: {
      _limit: -1
    }
  }).then(res =>
    dispatch({
      type: GET_INTPROMOS,
      payload: res.data
    })
  ).catch(error =>{
    console.log(error.response);
    dispatch({type: INTPROMOS_FAIL});
  });
};

export const promoApprove = ({id, type}) => dispatch => {
  //headers
  const jwtInt = localStorage.getItem('jwtInt');

  const config={
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwtInt}`
    }
  }
  //request body
  const body = JSON.stringify({id, type});

  axios.post(`${prodapi}/promos/action`, body, config)
    .then(res => {
      dispatch({
      type: ACTION_SUCCESS,
      });
    })
    .catch(error =>{
      dispatch({type: ACTION_FAIL});
    });
};

export const promoDecline = ({id, type}) => dispatch => {
  //headers
  const jwtInt = localStorage.getItem('jwtInt');
  const config={
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwtInt}`
    }
  }
  //request body
  const body = JSON.stringify({id, type});

  axios.post(`${prodapi}/promos/action`, body, config)
    .then(res => {
      dispatch({
      type: ACTION_SUCCESS,
      });
    }).catch(error =>{
      dispatch({type: ACTION_FAIL});
    });
};

export const promoModification = ({id, type, mod}) => dispatch => {
  //headers
  const jwtInt = localStorage.getItem('jwtInt');
  const config={
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwtInt}`
    }
  }
  //request body
  const body = JSON.stringify({id, type, mod});

  axios.post(`${prodapi}/promos/action`, body, config).then(res => {
      dispatch({
      type: ACTION_SUCCESS,
      });
    }).catch(error =>{
      dispatch({type: ACTION_FAIL});
    });
};

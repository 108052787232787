import {
  VERIFY_DIALOG,
  CLOSE_DIALOG,
  VERIFY_SUCCESS,
  OTP_SENT,
  EMAIL_SENT,
  GET_VERIFYERRORS,
  CLEAR_VERIFYERRORS,
  VERIFY_LOADING
} from '../actions/ActionTypes';

const initialState = {
  isOpen: false,
  type: '',
  isVerifying: false,
  vmsg: {},
}

const VerifyReducer = (state=initialState, action) => {
  switch (action.type) {
    case VERIFY_DIALOG:
      return {
        ...state,
        isOpen: true,
        type: 'otp',
      }
    case OTP_SENT:
      return {
        ...state,
        isOpen: true,
        type: 'otp',
        ...action.payload
      }
    case EMAIL_SENT:
      return {
        ...state,
        isOpen: true,
        type: 'email',
        ...action.payload
      }
    case VERIFY_LOADING:
      return{
        ...state,
        isVerifying: true,
      }
    case VERIFY_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isOpen: true,
        type: 'success',
        isVerifying: false,
      }
    case CLOSE_DIALOG:
      return {
        ...state,
        isOpen: false,
        type: '',
      }
    case GET_VERIFYERRORS:
      return {
        ...state,
        vmsg: action.payload.vmsg,
        isVerifying: false,
      };
    case CLEAR_VERIFYERRORS:
      return {
        ...state,
        vmsg: {},
      };
    default:
      return state;
  }
}
export default VerifyReducer;

import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from '../../redux/actions/authActions';
import { clearErrors } from '../../redux/actions/errorActions';
import { requestVerify } from '../../redux/actions/verifyActions';
import { clearVerifyErrors } from '../../redux/actions/verifyActions';
import useStyles, { SignInBtn, StyledTextInput } from './styles';
import AuthSidebar from '../shared/AuthSidebar';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/PhoneAndroid';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Dialog, DialogContent } from '@material-ui/core';

import MailOutlineIcon from '@material-ui/icons/MailOutline';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import axios from 'axios';

const prodapi = process.env.REACT_APP_API;

const SignIn = (props) => {
  const {
    isLoading,
    isAuthenticated,
    error,
    verify,
    loginUser,
    clearErrors,
    requestVerify,
    clearVerifyErrors,
  } = props;

  const classes = useStyles();
  const [method, setMethod] = useState(true);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [keepSignedIn, setKeepSignedIn] = useState(false);

  const [vpanel, setVpanel] = useState(null);
  const [ident, setIdent] = useState('');
  const [keyp, setKeyp] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [uid, setUid] = useState('');
  const [phoneSent, setPsent] = useState(false);
  const [emailSent, setEsent] = useState(false);
  const [ptoken, setPtoken] = useState('');
  const [verf, setVerf] = useState(false);
  const [isl, setIsl] = useState(!true);

  const [fpanel, setFpanel] = useState(null);
  const [fstatus, setFstatus] = useState('');

  const pattern = {
    phone: '^[0-9]{10}',
    email: '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$',
  };

  const [showPassword, setPasswordVisiblity] = useState(false);
  const handleClickShowPassword = (event) => {
    event.preventDefault();
    setPasswordVisiblity(!showPassword);
  };

  const selectMethod = async () => {
    setMethod(!method);
  };

  useEffect(() => {
    clearErrors();
  }, [clearErrors]);

  const handleSubmit = (event) => {
    event.preventDefault();
    let user = '';
    if (method) {
      user = {
        phone: phone,
        password: password,
        keepSignedIn: keepSignedIn,
      };
    } else {
      user = {
        email: email,
        password: password,
        keepSignedIn: keepSignedIn,
      };
    }
    loginUser(user);
    clearErrors();
    setEmail('');
    setPassword('');
    setPhone('');
  };

  const handleVerifyError = () => {
    clearVerifyErrors();
    clearErrors();
    setVpanel(0);
    // requestVerify(localStorage.getItem('id'));
  };

  const checkValid = () => {
    var patt = new RegExp(pattern.phone);
    var matched = patt.test(ident);
    if (matched) return [1, ''];
    else {
      patt = new RegExp(pattern.email);
      matched = patt.test(ident);
      if (matched) return [2, ''];
      else return [0, 'Invalid Phonne or Email'];
    }
  };

  const closeVerify = () => setVpanel(null);
  const callInfo = (query) => {
    axios
      .post(`${prodapi}/buszs/changeinfo`, query)
      .then((res) => {
        setUid(res.data.id);
        setErrMsg('');
      })
      .catch((err) => {
        setErrMsg(
          (err.response && err.response.data.message) || 'Unexpected  Error'
        );
      });
  };

  const handleProceed = () => {
    var itype = checkValid()[0];
    var query = {};
    query.password = keyp;
    if (itype === 1) {
      query.phone = ident;
    } else if (itype === 2) {
      query.email = ident;
    }
    if (itype !== 0) {
      callInfo(query);
    }
  };

  const handleVerify = () => {
    setIsl(true);
    var data = {
      id: uid,
      phoneToken: ptoken,
    };

    axios
      .post(`${prodapi}/buszs/verify`, data)
      .then((res) => {
        setIsl(false);
        setFpanel(2);
        setVerf(res.data.confirmed);
        setErrMsg('');
      })
      .catch((err) => {
        setIsl(false);
        setErrMsg(
          (err.response && err.response.data.message) || 'Unexpected Error'
        );
      });
  };

  const handleForget = () => {
    setIsl(true);
    var data = {
      email: ident,
    };

    axios
      .post(`${prodapi}/buszs/sendResetToken`, data)
      .then((res) => {
        if (res.data.status === 200) {
          setIsl(false);
          setFpanel(1);
          setFstatus('');
        } else {
          setIsl(false);
          setFpanel(2);
          setFstatus((res.data && res.data.message) || 'Unexpected Error');
        }
      })
      .catch((err) => {
        setIsl(false);
        setFpanel(2);
        setFstatus(
          (err.response && err.response.data.message) || 'Unexpected Error'
        );
      });
  };

  useEffect(() => {
    if (uid !== '') {
      setIsl(true);
      var data = { id: uid };
      axios
        .post(`${prodapi}/buszs/sendVerify`, data)
        .then((res) => {
          // console.log(res.data);
          setIsl(false);
          setEsent(res.data.emailSent);
          setPsent(res.data.otpSent);
          setVpanel(1);
        })
        .catch((err) => {
          setErrMsg(
            (err.response && err.response.data.message) || 'Unexpected  Error'
          );
        });
    }
  }, [uid]);

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  } else {
    return (
      <div className="auth__container">
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={fpanel !== null}
          aria-labelledby="max-width-dialog-title"
          onClose={() => {
            setFpanel(null);
          }}
        >
          <DialogContent className="forpanel">
            {!isl && fpanel === 0 ? (
              <>
                <StyledTextInput
                  autoFocus
                  label="Enter your email address"
                  variant="outlined"
                  type="email"
                  className="foremail"
                  value={ident}
                  error={checkValid()[0] !== 2 && ident !== ''}
                  helperText={
                    checkValid()[0] !== 2 && ident !== '' ? 'Invalid Email' : ''
                  }
                  onChange={(e) => setIdent(e.target.value)}
                />

                <Button
                  variant="contained"
                  color="primary"
                  disabled={checkValid()[0] !== 2 || ident === ''}
                  onClick={handleForget}
                >
                  Submit
                </Button>
              </>
            ) : null}

            {!isl && fpanel === 1 ? (
              <>
                <img src="assets/images/email.png" alt="success" />
                <p
                  style={{ color: '#545357', marginTop: 24, marginBottom: 10 }}
                >
                  A mail has been sent to your email address along with a link
                  to change password. Please check your inbox.
                </p>
              </>
            ) : null}

            {!isl && fpanel === 2 ? (
              <>
                <img src="assets/images/home5.png" alt="success" width="240" />
                <p
                  style={{ color: '#f04d4d', marginTop: 24, marginBottom: 10 }}
                >
                  {fstatus || 'Unexpected Error Occurred'}
                </p>
              </>
            ) : null}

            {isl ? (
              <div className="text-center py-24">
                <CircularProgress color="primary" size={64} />
              </div>
            ) : null}
          </DialogContent>
        </Dialog>

        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={vpanel !== null}
          aria-labelledby="max-width-dialog-title"
          onClose={closeVerify}
        >
          <DialogContent className="flex flex-col relative">
            {!isl && errMsg !== '' ? (
              <div className="text-center text-xs text-red-600">{errMsg}</div>
            ) : null}
            {!isl && vpanel === 0 ? (
              <>
                <div>
                  <div className={classes.label}>Email Or Phone</div>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="text"
                    value={ident}
                    error={checkValid()[0] === 0 && ident !== ''}
                    helperText={ident !== '' && checkValid()[1]}
                    onChange={(e) => setIdent(e.target.value)}
                  />
                </div>
                <div>
                  <div className={classes.label}>Password</div>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="text"
                    value={keyp}
                    onChange={(e) => setKeyp(e.target.value)}
                  />
                </div>
              </>
            ) : null}
            {!isl && vpanel === 1 ? (
              <>
                <div>
                  {emailSent ? (
                    <div className="">
                      * We have sent you a
                      <mark className="bg-transparent text-eblue-800">
                        verification email
                      </mark>
                      . Please check your inbox{' '}
                      <MailOutlineIcon color="primary" /> *
                    </div>
                  ) : null}
                  {phoneSent ? (
                    <>
                      <div className={classes.label}>
                        Enter the otp sent on your phone
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        type="tel"
                        value={ptoken}
                        onChange={(e) => setPtoken(e.target.value)}
                      />
                    </>
                  ) : null}
                </div>
              </>
            ) : null}
            {!isl && vpanel === 2 ? (
              <div className="flex flex-col items-center">
                <img
                  src="../assets/images/success.png"
                  alt="success"
                  width="600"
                />
                <div className="my-6 text-lg text-gray-800">
                  Congratulations!! Your {verf ? 'account' : 'phone'} has been
                  verified
                </div>
                {verf ? (
                  <Button
                    className="noShad"
                    href="/signin"
                    variant="contained"
                    color="primary"
                  >
                    Proceed to Login
                  </Button>
                ) : null}
                {emailSent ? (
                  <div className="">
                    * We have sent you a
                    <mark className="bg-transparent text-eblue-800">
                      verification email
                    </mark>
                    . Please check your inbox{' '}
                    <MailOutlineIcon color="primary" /> *
                  </div>
                ) : null}
              </div>
            ) : null}
            {!isl && vpanel === 0 ? (
              <div className="my-4 w-full flex justify-end">
                <Button
                  variant="contained"
                  className="noShad w-24"
                  color="primary"
                  disabled={checkValid()[0] === 0}
                  onClick={handleProceed}
                >
                  Proceed
                </Button>
              </div>
            ) : null}
            {!isl && vpanel === 1 && phoneSent ? (
              <div className="my-4 w-full flex justify-end">
                <Button
                  variant="contained"
                  className="noShad w-24"
                  color="primary"
                  disabled={ptoken.length !== '6' || !Number(ptoken)}
                  onClick={handleVerify}
                >
                  Verify
                </Button>
              </div>
            ) : null}
            {isl ? (
              <div className="text-center py-24">
                <CircularProgress color="primary" size={64} />
              </div>
            ) : null}
          </DialogContent>
        </Dialog>
        <div className="authBanner">
          <AuthSidebar />
        </div>
        <div style={{ width: '100%', padding: 30 }}>
          <div className="already--signin">
            New to ETark?
            <Link
              to="/signup"
              style={{
                textDecorationLine: 'none',
                color: '#245FD3',
                marginLeft: 5,
              }}
            >
              Sign Up
            </Link>
          </div>
          <div className="signin__container">
            <div className="signin--title">Sign In to ETark</div>

            {/*SignIn method button */}
            <Button
              type="button"
              onClick={selectMethod}
              variant="contained"
              color="primary"
              className="toggleBtn"
            >
              {method ? (
                <>
                  <EmailIcon style={{ marginRight: 15 }} />
                  Sign In with Email
                </>
              ) : (
                <>
                  <PhoneIcon style={{ marginRight: 15 }} />
                  Sign In with Phone Number
                </>
              )}
            </Button>

            {/*Divider*/}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: 380,
                marginTop: 30,
              }}
            >
              <Divider style={{ width: 148 }} />
              <span style={{ color: '#C4C4C4' }}>or</span>
              <Divider style={{ width: 148 }} />
            </div>

            {/*Error Alert*/}
            {error.msg.message && !isLoading ? (
              <Alert
                severity="error"
                className="width-sm"
                style={{ marginTop: 20 }}
              >
                {error.msg.message}
              </Alert>
            ) : null}

            {error.msg.message === 'User is not verified' && !isLoading ? (
              <Alert
                severity="error"
                className="width-sm "
                style={{ marginTop: 20 }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleVerifyError}
                  size="small"
                >
                  Verify Again
                </Button>
              </Alert>
            ) : null}

            {/*SignIn form*/}
            <form onSubmit={handleSubmit}>
              {method ? (
                <div>
                  <div className="label--signin">Phone Number</div>
                  <StyledTextInput
                    required
                    variant="outlined"
                    size="small"
                    className="width-sm"
                    name="phpne"
                    id="phone"
                    type="tel"
                    value={phone}
                    onChange={(event) => setPhone(event.target.value)}
                  />
                </div>
              ) : (
                <div s>
                  <div className={classes.label}>Email Address</div>
                  <StyledTextInput
                    required
                    variant="outlined"
                    size="small"
                    name="email"
                    id="email"
                    type="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </div>
              )}
              <div
                style={{
                  position: 'relative',
                  width: 'max-content',
                }}
              >
                <div className="label--signin">Password</div>
                <div className="">
                  <StyledTextInput
                    required
                    variant="outlined"
                    size="small"
                    name="password"
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </div>
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                  className="eyeIcon--signin"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </div>

              <div className="bottomDiv">
                <Checkbox
                  color="primary"
                  size="small"
                  name="keepSignedIn"
                  checked={keepSignedIn}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  onClick={() => setKeepSignedIn(!keepSignedIn)}
                />
                <span className="signin--checkbox--text">
                  Keep me Signed In?
                </span>

                <Button
                  className="forbtn float-right"
                  size="small"
                  onClick={() => {
                    setFpanel(0);
                  }}
                >
                  Forgot Password?
                </Button>
              </div>

              <div style={{ position: 'relative' }}>
                {!isLoading ? (
                  <SignInBtn type="submit" variant="contained" color="primary">
                    Sign In
                  </SignInBtn>
                ) : (
                  <div className="loader--signin">
                    <CircularProgress size={35} />
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error,
  verify: state.verify,
});

SignIn.propTypes = {
  isLoading: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  error: PropTypes.object.isRequired,
  verify: PropTypes.object.isRequired,
  loginUser: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  requestVerify: PropTypes.func.isRequired,
  clearVerifyErrors: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  loginUser,
  clearErrors,
  requestVerify,
  clearVerifyErrors,
})(SignIn);

import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  iframe: {
    width: 'calc(100vw - 40vw)',
    height: 'calc(100vw - 67vw)',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 10vw)',
      height: 'calc(100vw - 50vw)',
    },
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
      color: 'red',
    },
  },
}));

export const Title = styled.div`
  width: 100%;
  font-size: ${({ fontSize }) => fontSize || '32px'};
  font-weight: 700;
  font-family: Poppins !important;
  text-align: ${({ align }) => align || 'center'};
  margin-top: ${({ mt }) => mt || '0px'};
  margin-bottom: ${({ mb }) => mb || '0px'};
  margin-left: ${({ ml }) => ml || '0px'};
  padding-left: ${({ pl }) => pl || '0px'};
  @media (max-width: 1000px) {
    font-size: 23px;
  }

  @media (max-width: 500px) {
    font-size: 20px;
  }
`;

export const SubTitle = styled.div`
  width: 100%;
  padding-left: ${({ pl }) => pl || '3rem'};
  margin-bottom: 1rem;
  font-size: 24px;
  font-weight: 700;
  text-align: left;

  @media (max-width: 1000px) {
    font-size: 18px;
  }

  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

export const Content = styled.div`
  height: 75vh;
  width: 1000px;
  margin: auto;
  margin-bottom: 2rem;
  padding: 0 15px;
  margin-top: 1rem;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 5px 55px;

  &::-webkit-scrollbar {
    width: 5px;
    display: ${({ scroll }) => scroll || 'block'};
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #88888870;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #55555590;
  }

  @media (max-width: 1000px) {
    width: calc(100% - 10%);
    p {
      font-size: 14px;
    }
  }
  @media (max-width: 650px) {
    padding: 10px 10px !important;
  }
  @media (max-width: 500px) {
    p {
      font-size: 13px;
    }
  }
`;

export const Wrapper = styled.div`
  margin: auto;
  padding: 10px;
  width: 900px;
  padding-bottom: 3rem;
  overflow-y: scroll;
  height: 80vh;
  background-color: #fff;
  padding: 5px 15px;
  @media (max-width: 990px) {
    width: calc(100% - 8%);
  }
  @media (max-width: 450px) {
    width: calc(100% - 6%);
  }
  & .MuiAccordion-root {
    margin-bottom: 1rem;
    box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.1),
      0px 1px 5px 2px rgba(0, 0, 0, 0.1), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
  }

  & .MuiAccordionDetails-root {
    flex-direction: column;
  }

  & .MuiAccordionSummary-root {
    padding: 0px 15px;
  }
`;

export const HelpContainer = styled.div`
  background-color: #ffffff;
  width: calc(100vw - 10vw);
  height: 95vh;
  position: relative;
  padding: 20px 8rem;
  overflow-y: scroll;

  @media (max-width: 810px) {
    padding: 20px 2rem;
  }
  @media (max-width: 500px) {
    padding: 20px 1rem;
  }
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background: #00000020;
  }
  ::-webkit-scrollbar-thumb {
    background: #00000050;
    border-radius: 10px;
  }
  & .MuiSvgIcon-root {
    cursor: pointer;
    position: fixed !important;
    top: 4%;
    right: 8%;
  }

  h1 {
    font-size: 24px;
    font-weight: 900;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 1rem;
    @media (max-width: 800px) {
      font-size: 20px;
    }
    @media (max-width: 450px) {
      font-size: 18px;
    }
  }

  div {
    text-align: left;
    width: 100%;
    display: flex;
    justify-content: center;
    img {
      height: 100%;
      width: 700px;
      object-fit: contain;
    }
  }
`;

export const HelpText = styled.h3`
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.3px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  div {
    display: flex;
    flex-direction: column;
    margin-left: 7px;
  }
  span {
    font-weight: 900;
  }
  @media (max-width: 800px) {
    font-size: 15px;
  }
  @media (max-width: 450px) {
    font-size: 13px;
  }
`;

export const SolutionsDropdown = styled.div`
  position: absolute;
  top: 2.5%;
  left: 43%;
  height: max-content;
  width: max-content;
  background-color: #f2f2f290;
  box-shadow: 17px 17px 26px -11px rgba(0, 0, 0, 0.62);
  -webkit-box-shadow: 17px 17px 26px -11px rgba(0, 0, 0, 0.62);
  -moz-box-shadow: 17px 17px 26px -11px rgba(0, 0, 0, 0.62);
  border: 1px solid #f2f2f2;
  opacity: 0;
  transition: 0.2s opacity ease-in-out;
  color: #185adb;
  border-radius: 10px;
  ul {
    li {
      cursor: pointer;
      font-size: 15px;
      font-weight: bold;
      padding: 15px 40px;
      border-radius: 10px;
    }
  }
`;

export const Solutions = styled.div`
  position: relative;
  cursor: pointer;

  & + ${SolutionsDropdown} {
    opacity: ${(props) => (!props.open ? '0' : '1')};
    /* opacity: 1; */
  }
`;

export default useStyles;

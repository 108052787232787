import {
  CLOSE_DIALOG,
  //VERIFY_FAIL,
  VERIFY_SUCCESS,
  OTP_SENT,
  EMAIL_SENT,
  GET_VERIFYERRORS,
  CLEAR_VERIFYERRORS,
  VERIFY_LOADING,
} from './ActionTypes';
import axios from 'axios';

const prodapi = process.env.REACT_APP_API;

export const closeDialog = () => (dispatch) => {
  dispatch({
    type: CLOSE_DIALOG,
  });
};

export const requestVerify = (id) => (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  //request body
  const body = JSON.stringify({ id });
  // console.log(body);

  //dispatch({type: USER_LOADING});
  axios
    .post(`${prodapi}/buszs/sendVerify`, body, config)
    .then((res) => {
      if (res.data.emailSent && !res.data.otpSent) {
        dispatch({
          type: EMAIL_SENT,
          payload: res.data,
        });
      } else {
        dispatch({
          type: OTP_SENT,
          payload: res.data,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: VERIFY_LOADING,
      });
      // console.log(error.response);
      if (error.response) {
        dispatch(
          returnVerifyErrors(error.response.data, error.response.status)
        );
      } else {
        console.log(error);
      }
    });
};

export const phoneVerify =
  ({ id, phoneToken }) =>
  (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    //request body
    const body = JSON.stringify({ id, phoneToken });
    // console.log(body);

    axios
      .post(`${prodapi}/buszs/verify`, body, config)
      .then((res) => {
        dispatch({
          type: VERIFY_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch(
          returnVerifyErrors(error.response.data, error.response.status)
        );
        //dispatch({type: SIGNUP_FAIL});
      });
  };

//Verification errors
export const returnVerifyErrors = (vmsg, status) => {
  return {
    type: GET_VERIFYERRORS,
    payload: { vmsg, status },
  };
};

export const clearVerifyErrors = () => {
  return {
    type: CLEAR_VERIFYERRORS,
  };
};

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Divider from '@material-ui/core/Divider';

import './preview.scss';

const useStyles = makeStyles((theme) => ({
  colorContainer: {
    width: 310,
    marginTop: 15,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      width: 300,
    },
  },
  colour: {
    width: 50,
    height: 50,
    borderRadius: '50%',
    overflow: 'hidden',
    boxShadow:
      '4px 4px 16px -4px rgba(13, 13, 15, 0.4), inset -1px -1px 2px rgba(102, 102, 109, 0.14), inset 1px 1px 2px rgba(16, 17, 18, 0.1)',
  },
  colourSelected: {
    width: 50,
    height: 50,
    borderRadius: '50%',
    border: '6px solid #7A7A7A',
    boxShadow:
      '4px 4px 16px -4px rgba(13, 13, 15, 0.4), inset -1px -1px 2px rgba(102, 102, 109, 0.14), inset 1px 1px 2px rgba(16, 17, 18, 0.1)',
  },
  helperText: {
    fontSize: 14,
    fontWeight: 300,
    color: '#245FD3',
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
  layoutOne: {
    height: 560,
    width: 330,
    border: '10px solid #222129',
    borderRadius: 8,
    marginTop: 15,
    boxShadow: '5px 5px 20px rgba(11, 12, 14, 0.6)',
  },
}));

const Layout = [
  'layout1',
  'layout2',
  'layout3',
  'layout4',
  'layout5',
  'layout6',
  'layout7',
];

const StoriesPreview = ({
  previewPicture,
  promoDesc,
  promoTitle,
  storyColour,
  setStoryColour,
  storyLayout,
  setStoryLayout,
}) => {
  const classes = useStyles();
  const [layoutNum, setLayoutNum] = useState(0);
  const [tmpCol, setCol] = useState('#444');

  const handleBack = async () => {
    setLayoutNum(layoutNum - 1);
  };
  const handleForward = async () => {
    await setLayoutNum(layoutNum + 1);
  };

  useEffect(() => {
    setStoryLayout(Layout[layoutNum]);
  }, [layoutNum, setStoryLayout]);

  useEffect(() => {
    // console.log(storyLayout);
  }, [storyLayout]);

  const ChooseColor = () => {
    return (
      <div>
        <div className={classes.helperText}>Choose Colour</div>
        <div className={classes.colorContainer}>
          <div
            onClick={() => setStoryColour('#34393E')}
            className={
              storyColour === '#34393E'
                ? classes.colourSelected
                : classes.colour
            }
            style={{ backgroundColor: '#34393E' }}
          ></div>
          <div
            onClick={() => setStoryColour('#D04A61')}
            className={
              storyColour === '#D04A61'
                ? classes.colourSelected
                : classes.colour
            }
            style={{ backgroundColor: '#D04A61' }}
          ></div>
          <div
            onClick={() => setStoryColour('#3A8B6E')}
            className={
              storyColour === '#3A8B6E'
                ? classes.colourSelected
                : classes.colour
            }
            style={{ backgroundColor: '#3A8B6E' }}
          ></div>
          <div
            onClick={() => setStoryColour('#4A68A2')}
            className={
              storyColour === '#4A68A2'
                ? classes.colourSelected
                : classes.colour
            }
            style={{ backgroundColor: '#4A68A2' }}
          ></div>
        </div>
      </div>
    );
  };

  const ChooseLayout = () => {
    if (layoutNum === 0) {
      return (
        <Layout1
          storyColour={storyColour}
          promoTitle={promoTitle}
          promoDesc={promoDesc}
          previewPicture={previewPicture}
        />
      );
    } else if (layoutNum === 1) {
      return (
        <Layout2
          storyColour={storyColour}
          promoTitle={promoTitle}
          promoDesc={promoDesc}
          previewPicture={previewPicture}
        />
      );
    } else if (layoutNum === 2) {
      return (
        <Layout3
          storyColour={storyColour}
          promoTitle={promoTitle}
          promoDesc={promoDesc}
          previewPicture={previewPicture}
        />
      );
    } else if (layoutNum === 3) {
      return (
        <Layout4
          storyColour={storyColour}
          promoTitle={promoTitle}
          promoDesc={promoDesc}
          previewPicture={previewPicture}
        />
      );
    } else if (layoutNum === 4) {
      return (
        <Layout5
          storyColour={storyColour}
          promoTitle={promoTitle}
          promoDesc={promoDesc}
          previewPicture={previewPicture}
        />
      );
    } else if (layoutNum === 5) {
      return (
        <Layout6
          storyColour={storyColour}
          promoTitle={promoTitle}
          promoDesc={promoDesc}
          previewPicture={previewPicture}
        />
      );
    } else {
      return (
        <Layout7
          storyColour={storyColour}
          promoTitle={promoTitle}
          promoDesc={promoDesc}
          previewPicture={previewPicture}
        />
      );
    }
  };

  return (
    <div>
      <ChooseColor />
      <div style={{ marginTop: 15 }}>
        <div className={classes.helperText}>Choose Layout</div>
        <div className="layoutPreview">
          {layoutNum !== 0 ? (
            <button
              className="arrowBackward"
              type="button"
              name={storyLayout}
              onClick={handleBack}
              style={{ border: 'none', backgroundColor: 'transparent' }}
            >
              <ArrowBackIosIcon style={{ fontSize: 40, marginRight: 10 }} />
            </button>
          ) : null}
          <ChooseLayout />
          {layoutNum !== 6 ? (
            <button
              className="arrowForward"
              type="button"
              name={storyLayout}
              onClick={handleForward}
              style={{ border: 'none', backgroundColor: 'transparent' }}
            >
              <ArrowForwardIosIcon style={{ fontSize: 40, marginLeft: 20 }} />
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default StoriesPreview;

const Layout1 = ({ storyColour, previewPicture, promoTitle, promoDesc }) => {
  return (
    <div
      className="layout1"
      style={{
        backgroundColor: storyColour,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          marginTop: 10,
        }}
      >
        <Divider style={{ width: 90, height: 5, color: '#636262' }} />
        <Divider style={{ width: 90, height: 5, color: '#636262' }} />
        <Divider style={{ width: 90, height: 5, color: '#636262' }} />
      </div>
      <div className="layoutTextContainer">
        <div className="layoutTextContainer--title">{promoTitle}</div>
        <p className="layoutTextContainer--description">{promoDesc}</p>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="imgCont">
            {previewPicture ? <img src={previewPicture} alt="" /> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const Layout2 = ({ storyColour, previewPicture, promoTitle, promoDesc }) => {
  return (
    <div
      className="layout2"
      style={{
        backgroundColor: storyColour,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          marginTop: 10,
        }}
      >
        <Divider style={{ width: 90, height: 5, color: '#636262' }} />
        <Divider style={{ width: 90, height: 5, color: '#636262' }} />
        <Divider style={{ width: 90, height: 5, color: '#636262' }} />
      </div>
      <div className="layoutTextContainer">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="imgCont">
            {previewPicture ? <img src={previewPicture} alt="" /> : null}
          </div>
        </div>
        <div className="layoutTextContainer--title">{promoTitle}</div>
        <p className="layoutTextContainer--description">{promoDesc}</p>
      </div>
    </div>
  );
};

const Layout3 = ({ storyColour, previewPicture, promoTitle, promoDesc }) => {
  return (
    <div
      className="layout3"
      style={{
        backgroundColor: storyColour,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          marginTop: 10,
        }}
      >
        <Divider style={{ width: 90, height: 5, color: '#636262' }} />
        <Divider style={{ width: 90, height: 5, color: '#636262' }} />
        <Divider style={{ width: 90, height: 5, color: '#636262' }} />
      </div>
      <div className="layoutTextContainer">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="imgCont horz">
            {previewPicture ? <img src={previewPicture} alt="" /> : null}
          </div>
        </div>

        <div className="layoutTextContainer--title" style={{ marginTop: 10 }}>
          {promoTitle}
        </div>
        <p className="layoutTextContainer--description">{promoDesc}</p>
      </div>
    </div>
  );
};

const Layout4 = ({ storyColour, previewPicture, promoTitle, promoDesc }) => {
  return (
    <div
      className="layout4"
      style={{
        backgroundColor: storyColour,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          marginTop: 10,
        }}
      >
        <Divider style={{ width: 90, height: 5, color: '#636262' }} />
        <Divider style={{ width: 90, height: 5, color: '#636262' }} />
        <Divider style={{ width: 90, height: 5, color: '#636262' }} />
      </div>
      <div className="layoutTextContainer">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="imgCont vert">
            {previewPicture ? <img src={previewPicture} alt="" /> : null}
          </div>
        </div>

        <div className="layoutTextContainer--title">{promoTitle}</div>
        <p className="layoutTextContainer--description">{promoDesc}</p>
      </div>
    </div>
  );
};

const Layout5 = ({ storyColour, previewPicture, promoTitle, promoDesc }) => {
  return (
    <div
      className="layout5"
      style={{
        backgroundColor: storyColour,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          marginTop: 10,
        }}
      >
        <Divider style={{ width: 90, height: 5, color: '#636262' }} />
        <Divider style={{ width: 90, height: 5, color: '#636262' }} />
        <Divider style={{ width: 90, height: 5, color: '#636262' }} />
      </div>
      <div className="layoutTextContainer">
        <div className="layoutTextContainer--title">{promoTitle}</div>
        <p className="layoutTextContainer--description">{promoDesc}</p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="imgCont vert">
            {previewPicture ? <img src={previewPicture} alt="" /> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const Layout6 = ({ storyColour, previewPicture, promoTitle, promoDesc }) => {
  return (
    <div
      className="layout6"
      style={{
        backgroundColor: storyColour,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          marginTop: 10,
        }}
      >
        <Divider style={{ width: 90, height: 5, color: '#636262' }} />
        <Divider style={{ width: 90, height: 5, color: '#636262' }} />
        <Divider style={{ width: 90, height: 5, color: '#636262' }} />
      </div>
      <div className="layoutTextContainer">
        <div className="layoutTextContainer--title">{promoTitle}</div>
        <p className="layoutTextContainer--description">{promoDesc}</p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="imgCont portr">
            {previewPicture ? <img src={previewPicture} alt="" /> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const Layout7 = ({ storyColour, previewPicture, promoTitle, promoDesc }) => {
  return (
    <div
      className="layout7"
      style={{
        backgroundColor: storyColour,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          marginTop: 10,
        }}
      >
        <Divider style={{ width: 90, height: 5, color: '#636262' }} />
        <Divider style={{ width: 90, height: 5, color: '#636262' }} />
        <Divider style={{ width: 90, height: 5, color: '#636262' }} />
      </div>
      <div className="layoutTextContainer">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="imgCont portr">
            {previewPicture ? <img src={previewPicture} alt="" /> : null}
          </div>
        </div>

        <div className="layoutTextContainer--title">{promoTitle}</div>
        <p className="layoutTextContainer--description">{promoDesc}</p>
      </div>
    </div>
  );
};

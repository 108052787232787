import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TableCell, TableRow } from '@material-ui/core';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import ActiveIcon from '@material-ui/icons/PlayCircleFilled';
import PauseIcon from '@material-ui/icons/PauseCircleFilled';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CancelIcon from '@material-ui/icons/NotInterested';
import PendingIcon from '@material-ui/icons/HourglassFull';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { connect } from 'react-redux';
import { promoStateToggle } from '../../redux/actions/promoActions';

import './shared.scss';
import { CustomTable, Head, Icon, Info, Left, Right } from './styles';

const useStyles = makeStyles((theme) => ({
  icondiv: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 15,
  },
}));

const StatusIcon = ({ type }) => {
  const classes = useStyles();

  if (type === 'active') {
    return (
      <div className={classes.icondiv} style={{ color: 'green' }}>
        <ActiveIcon style={{ fontSize: 'inherit', marginRight: 5 }} /> Active
      </div>
    );
  } else if (type === 'paused') {
    return (
      <div className={classes.icondiv} style={{ color: 'grey' }}>
        <PauseIcon style={{ fontSize: 'inherit', marginRight: 5 }} /> Paused
      </div>
    );
  } else if (type === 'pending') {
    return (
      <div className={classes.icondiv} style={{ color: '#ee4242' }}>
        <PendingIcon style={{ fontSize: 'inherit', marginRight: 5 }} /> Pending
      </div>
    );
  } else if (type === 'modification') {
    return (
      <div className={classes.icondiv} style={{ color: 'orange' }}>
        Modification Requested
      </div>
    );
  } else if (type === 'rejected') {
    return (
      <div className={classes.icondiv} style={{ color: '#ff5555' }}>
        <CancelIcon style={{ fontSize: 'inherit', marginRight: 5 }} /> Rejected
      </div>
    );
  } else if (type === 'notpaid') {
    return (
      <div className={classes.icondiv} style={{ color: 'red' }}>
        Not Paid
      </div>
    );
  } else {
    return (
      <div className={classes.icondiv} style={{ color: '#2655f5' }}>
        <CheckBoxIcon style={{ fontSize: 'inherit', marginRight: 5 }} />{' '}
        Completed
      </div>
    );
  }
};

const TableView = ({ Data, type, promoStateToggle }) => {
  const handleMod = (e) => {
    window.location =
      '/dashboard?update=true&promoId=' + e.target.getAttribute('value');
  };

  const handleActive = (e) => {
    promoStateToggle({
      type: 'active',
      id: e.target.getAttribute('value'),
    });
  };

  const handlePause = (e) => {
    promoStateToggle({
      type: 'paused',
      id: e.target.getAttribute('value'),
    });
  };

  const addNoise = (num, range) => {
    var x = Math.sin(new Date().getHours() + new Date().getDate()) * 10000;
    x -= Math.floor(x);

    return Math.floor(num + (2 * x - 1) * range * num);
  };

  const simplify = (res) => {
    res = res.toString();
    if (res.length > 3) {
      res = Math.floor((res * 10) / 1000) / 10;
      res += 'K';
    }

    return res;
  };

  const calculateEstimated = ({
    budget,
    budgetLeft,
    promoType,
    costModel,
    isRelevant,
  }) => {
    var priceModel = {
      banner: {
        perView: [5, 7.5],
        perClick: [20, 30],
      },
      story: {
        perView: [1, 1.5],
        perClick: [10, 15],
      },
    };

    var reach = 0;
    reach += (budget - budgetLeft) * priceModel[promoType][costModel][0];

    reach = addNoise(reach, 0.05);
    if (isRelevant) {
      reach = Math.floor(addNoise(reach, 0.05) * 0.8);
    }

    return simplify(reach);
  };

  return (
    <>
      {Data.length === 0 ? (
        <>
          {'000000000000000'.split('').map((x) => {
            return (
              <TableRow className="tableTop">
                <TableCell
                  className="tableCell"
                  style={{ border: 'none' }}
                ></TableCell>
              </TableRow>
            );
          })}
          <div className="absolute inset-32 text-4xl text-gray-600">
            No items here
          </div>
        </>
      ) : null}
      {Data.map((item) => {
        return (
          <div className="tableContainer">
            <CustomTable key={item.id}>
              <Left>
                <Head> Promo ID</Head>
                <Head> Product/Services</Head>
                <Head> Total Budget</Head>
                <Head> Promo Type</Head>
                <Head> Cost Model</Head>
                <Head> Budget Left</Head>
                <Head> Conversion</Head>
                <Head> Status</Head>
              </Left>
              <Right>
                <Info>ETark-{item.id.substring(18)}</Info>
                <Info> {item.product}</Info>
                <Info> INR {item.budget}</Info>
                <Info> {item.promoType}</Info>
                <Info> {item.costModel}</Info>
                <Info> INR {item.budgetLeft}</Info>
                <Info> {calculateEstimated(item)}</Info>
                <Info boxShadow="none">
                  <StatusIcon
                    type={item.isCompleted ? 'completed' : item.status}
                  />
                  <Icon>
                    {item.status === 'modification' ? (
                      <div title="modify">
                        <CreateOutlinedIcon
                          className="handcr"
                          style={{ color: '#A0A4A8' }}
                          value={item.id}
                          onClick={handleMod}
                        />
                      </div>
                    ) : null}
                    {item.status === 'active' && !item.isCompleted ? (
                      <div title="pause promo">
                        <PauseCircleOutlineIcon
                          className="handcr"
                          style={{ color: '#A0A4A8' }}
                          value={item.id}
                          onClick={handlePause}
                          inputProps={{
                            title: 'Pause Promo',
                          }}
                        />
                      </div>
                    ) : null}
                    {item.status === 'paused' && !item.isCompleted ? (
                      <div title="resume promo">
                        <PlayCircleOutlineIcon
                          className="handcr"
                          style={{ color: '#A0A4A8' }}
                          value={item.id}
                          onClick={handleActive}
                        />
                      </div>
                    ) : null}
                  </Icon>
                </Info>
              </Right>
            </CustomTable>
          </div>
        );
      })}
    </>
  );
};

// <div className="w-160 absolute p-24 text-4xl text-gray-600">No Items</div>
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, { promoStateToggle })(TableView);

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import PieChartIcon from '@material-ui/icons/PieChart';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#FFFFFF',
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    boxShadow: '4px 0px 16px rgba(16, 30, 115, 0.08)',
    minWidth: '280px',
    zIndex: 1,
    padding: 24,
  },
  logo: {
    width: 48,
    height: 49,
    marginTop: 34,
  },
  logoText: {
    color: '#185ADB',
    marginLeft: 10,
    marginTop: 48,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 16,
    letterSpacing: '0.02em',
  },
  promos: {
    marginTop: 40,
    marginLeft: -5,
    fontSize: 14,
    fontWeight: 'bold',
    color: '#185ADB',
    display: 'flex',
    marginBottom: 20,
  },
  TabsHead: {
    //  marginTop: 20,
    marginLeft: 27,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  TabsBtn1: {
    border: 'none',
    backgroundColor: 'transparent',
    fontSize: 14,
    fontWeight: 700,
    color: '#52575C',
    marginBottom: 20,
    textAlign: 'left',
  },
  TabsBtn2: {
    border: 'none',
    backgroundColor: 'transparent',
    fontSize: 14,
    fontWeight: 700,
    color: '#336CFB',
    marginBottom: 20,
    textAlign: 'left',
  },
  promoTextSelected: {
    color: '#336CFB',
    marginLeft: '4px',
    backgroundColor: 'transparent',
    fontSize: 14,
    fontWeight: 700,
    marginLeft: 8,
  },
  promoTextNotSelected: {
    color: '#52575C',
    marginLeft: '4px',
    backgroundColor: 'transparent',
    fontSize: 14,
    fontWeight: 700,
    marginLeft: 8,
  },
}));

export function TabsBtn1({ text, onClickFunction, customClass }) {
  const classes = useStyles();
  return (
    <>
      <button
        onClick={onClickFunction}
        className={customClass ? customClass : classes.TabsBtn1}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {text}
      </button>
    </>
  );
}

export function TabsBtn2({ text, customClass }) {
  const classes = useStyles();
  return (
    <>
      <button
        className={customClass ? customClass : classes.TabsBtn2}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {text}
      </button>
    </>
  );
}

const IntLeftBar = (props) => {
  const {
    promo,
    pending,
    modification,
    active,
    paused,
    completed,
    rejected,
    selectPromo,
    selectPending,
    selectModification,
    selectCompleted,
    selectActive,
    selectPaused,
    selectRejected,
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Link to="/" style={{ textDecoration: 'none' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
          }}
        >
          <img
            alt="logo"
            src="assets/images/bluelogo.png"
            className={classes.logo}
          />
          <div className={classes.logoText}>For Business</div>
        </div>
      </Link>
      <div className={classes.promos}>
        <PieChartIcon />
        {/* <span className="ml-2">Promo Account</span> */}
        {promo ? (
          <TabsBtn2
            customClass={classes.promoTextSelected}
            text="Promo Account"
          />
        ) : (
          <TabsBtn1
            customClass={classes.promoTextNotSelected}
            text="Promo Account"
            onClickFunction={selectPromo}
          />
        )}
      </div>
      <div className={classes.TabsHead}>
        {pending ? (
          <TabsBtn2 text="Pending for verification" />
        ) : (
          <TabsBtn1
            text="Pending for verification"
            onClickFunction={selectPending}
          />
        )}
        {modification ? (
          <TabsBtn2 text="Request for modification" />
        ) : (
          <TabsBtn1
            text="Request for modification"
            onClickFunction={selectModification}
          />
        )}
        {active ? (
          <TabsBtn2 text="Active" />
        ) : (
          <TabsBtn1 text="Active" onClickFunction={selectActive} />
        )}
        {paused ? (
          <TabsBtn2 text="Paused" />
        ) : (
          <TabsBtn1 text="Paused" onClickFunction={selectPaused} />
        )}
        {completed ? (
          <TabsBtn2 text="Completed" />
        ) : (
          <TabsBtn1 text="Completed" onClickFunction={selectCompleted} />
        )}
        {rejected ? (
          <TabsBtn2 text="Rejected" />
        ) : (
          <TabsBtn1 text="Rejected" onClickFunction={selectRejected} />
        )}
      </div>
    </div>
  );
};

export default IntLeftBar;

import React from 'react';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PropTypes from 'prop-types';
import Tab from './Tab';
import {connect} from 'react-redux';

const CompletedPage = ({promos}) => {
  return (
    <div style={{ padding: 36}}>
      <div style={{fontSize: 40, fontWeight: '700', display: 'flex',alignItems: 'center'}}>
        Completed Promos
        <CheckBoxIcon style={{fontSize: "inherit", marginLeft: 10, color: "blue"}} />
      </div>
      {promos.map(promo =>{
        if(promo.isCompleted){
          return <Tab promo={promo} />;
        }
        else {
          return null;
        }
      })}
    </div>
  )
}

CompletedPage.propTypes = {
  promos: PropTypes.array.isRequired
}

const mapStateToProps = (state) => ({
  promos: state.internal.promos
});

export default connect(mapStateToProps)(CompletedPage);

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import PeopleIcon from '@material-ui/icons/People';
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import BannerPreview from './BannerPreview';
import StoriesPreview from './StoriesPreview';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { promoResubmission } from '../../redux/actions/promoActions';

import Resizer from 'react-image-file-resizer';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const prodapi = process.env.REACT_APP_API;

const PromoTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: 14,
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 17,
    fontWeight: 'bold',
    color: '#336CFB',
  },
  option: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 30,
    cursor: 'pointer',
  },
  optionPara: {
    fontSize: 14,
    fontWeight: 'normal',
    color: '#857F7F',
    marginTop: 10,
  },
  input: {
    display: 'none',
  },
  submitDiv: {
    width: 1100,
    backgroundColor: '#336CFB',
    height: 124,
    marginTop: 30,
    borderRadius: 8,
    boxShadow: '0px 12px 26px rgba(16, 30, 115, 0.06)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  cbox: {
    fontSize: 14,
    fontWeight: 400,
    color: '#FFF',
  },
}));

const UpdateForm = (props) => {
  const { promoResubmission } = props;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [pstate, setPstate] = useState('');
  const [promoId, setPromoId] = useState(props.promoId);
  const [costModel, setCostModel] = useState('');
  const [link, setLink] = useState('');
  const [product, setProduct] = useState('');
  const [startDate, setStartDate] = useState();
  const [promoType, setPromoType] = useState('banner');
  const [promoImage, setPromoImage] = useState('');
  const [promoLogo, setPromoLogo] = useState('');
  const [promoTitle, setPromoTitle] = useState('');
  const [promoDesc, setPromoDesc] = useState('');
  const [budget, setBudget] = useState('');

  const [changes, setChanges] = useState({});
  const [focus, setFocus] = useState({});
  const [b, setB] = useState(0);
  const a = [true, true];

  const [storyColour, setStoryColour] = useState('#34393E');
  const [storyLayout, setStoryLayout] = useState('layout1');

  const [pictureUrl, setPictureUrl] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);
  const [checked, setChecked] = useState(false);

  const [previewPicture, setPreviewPicture] = useState('');
  const [previewLogo, setPreviewLogo] = useState('');

  const [clked, setClicked] = useState(false);
  const [apiErr, setError] = useState(false);
  const [errMsg, setMsg] = useState('');

  const handlePublish = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setPstate('Uploading Assets');
    setClicked(true);
    uploadPicture();
  };

  const handleCompression = (file) => {
    return new Promise((resolve, reject) => {
      try {
        Resizer.imageFileResizer(
          file,
          1000,
          1000,
          'JPEG',
          90,
          0,
          (uri) => {
            resolve(uri);
          },
          'file'
        );
      } catch (err) {
        console.log(err);
        resolve(file);
      }
    });
  };

  const uploadPicture = () => {
    if (promoImage === '' && pictureUrl.his === true) {
      setPromoImage(pictureUrl.id);
      return;
    }

    let pictureData = new FormData();
    if (pictureUrl !== null) {
      pictureData.append('files', pictureUrl, pictureUrl.name);
      localStorage.setItem('previewPicture', previewPicture);
      localStorage.setItem('pictureName', pictureUrl.name);
      localStorage.setItem('promoImage', null);

      try {
        axios
          .post(`${prodapi}/upload`, pictureData)
          .then((res) => {
            // console.log(res.data[0].id);
            setPromoImage(res.data[0].id);
            localStorage.setItem('promoImage', res.data[0].id);
          })
          .catch((err) => {
            // console.log(error.message);
            setIsLoading(false);
            setError(true);
            setMsg('Upload Error ! Please Refresh');
          });
      } catch (err) {
        // console.log(error);
        setIsLoading(false);
        setError(true);
        setMsg('Upload Error ! Please Refresh');
      }
    }
  };

  const uploadLogo = () => {
    if (promoLogo === '' && logoUrl.his === true) {
      setPromoLogo(logoUrl.id);
      return;
    }

    let logoData = new FormData();
    if (logoUrl !== null) {
      logoData.append('files', logoUrl, logoUrl.name);
      localStorage.setItem('previewLogo', previewLogo);
      localStorage.setItem('logoName', logoUrl.name);
      localStorage.setItem('promoLogo', null);

      try {
        axios
          .post(`${prodapi}/upload`, logoData)
          .then((res) => {
            setPromoLogo(res.data[0].id);
            localStorage.setItem('promoLogo', res.data[0].id);
          })
          .catch((err) => {
            // console.log(error.message);
            setIsLoading(false);
            setError(true);
            setMsg('Upload Error ! Please Refresh');
          });
      } catch (err) {
        // console.log(error);
        setIsLoading(false);
        setError(true);
        setMsg('Upload Error ! Please Refresh');
      }
    }
  };

  const handleSubmit = async () => {
    // console.log("Submitting");
    setPstate('Updating your promo');
    var correction = {
      product: product,
      promoImage: promoImage,
      startDate: startDate,
      ...(costModel === 'perClick' && {
        url: link,
      }),
      ...(promoType === 'story' && {
        title: promoTitle,
        description: promoDesc,
        promoLogo: promoLogo,
        layout: storyLayout,
        color: storyColour,
      }),
    };
    var data = {
      id: promoId,
      type: 'pending',
      changes: JSON.stringify(correction),
    };

    promoResubmission(data);
  };

  useEffect(() => {
    if (promoImage !== '' && clked) {
      if (promoType === 'story') {
        uploadLogo();
      } else {
        handleSubmit();
      }
    }
  }, [promoImage]);

  useEffect(() => {
    if (promoLogo !== '' && clked) {
      handleSubmit();
    }
  }, [promoLogo]);

  const handlePicture = (event) => {
    if (event.target.files[0]) {
      handleCompression(event.target.files[0]).then((file) => {
        setPictureUrl(file);
        var reader = new FileReader();
        reader.onload = function (base64) {
          setPreviewPicture(base64.target.result);
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const handleLogo = (event) => {
    if (event.target.files[0]) {
      handleCompression(event.target.files[0]).then((file) => {
        console.log(file);
        setLogoUrl(file);
        var reader = new FileReader();
        reader.onload = function (base64) {
          setPreviewLogo(base64.target.result);
        };
        reader.readAsDataURL(file);
      });
    }
  };

  useEffect(() => {
    if (!clked) {
      const jwt = localStorage.getItem('jwt');
      axios
        .get(`${prodapi}/promos`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
          params: {
            id: promoId,
          },
        })
        .then((res) => res.data[0])
        .then((promo) => {
          setCostModel(promo.costModel);
          setLink(promo.url);
          setProduct(promo.product);
          setStartDate(new Date(promo.startDate).toLocaleDateString('en-CA'));
          setPromoType(promo.promoType);
          setPromoTitle(promo.title);
          setPromoDesc(promo.description);
          setBudget(promo.budget);
          setStoryColour(promo.color || '#34393E');
          setStoryLayout(promo.layout);
          setChanges(promo.mod);

          setPreviewPicture(promo.promoImage && promo.promoImage.url);
          setPictureUrl({
            name: promo.promoImage && promo.promoImage.name,
            id: promo.promoImage && promo.promoImage.id,
            his: true,
          });
          setPreviewLogo(promo.promoLogo && promo.promoLogo.url);
          setLogoUrl({
            name: promo.promoLogo && promo.promoLogo.name,
            id: promo.promoLogo && promo.promoLogo.id,
            his: true,
          });
        })
        .catch((err) => console.log(err));
    }
  }, [clked]);

  const checkDisable = () => {
    var res = true;
    res &= checked;
    for (var key in changes) {
      if (changes[key] !== null) {
        res &= focus[key] === true;
      }
    }
    return res;
  };

  const handleFocus = (e) => {
    var tar = e.target.name;
    var tpfc = { ...focus };

    tpfc[tar] = true;
    setB(b ^ 1);
    setFocus(tpfc);
  };

  return (
    <form>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={apiErr}
        key={'topcenter'}
        autoHideDuration={3000}
      >
        <Alert severity="error">
          <span style={{ fontFamily: 'Poppins' }}>{errMsg}</span>
        </Alert>
      </Snackbar>
      <div style={{ padding: 30, backgroundColor: '#fff', borderRadius: 8 }}>
        {/* Promo Goal */}
        <div className={classes.title}>What’s your promotion goal?</div>

        {costModel === 'perView' ? (
          <div className={classes.option}>
            <BrandingWatermarkIcon style={{ color: '#336CFB' }} />
            <div style={{ width: 500, paddingLeft: 30, paddingRight: 30 }}>
              <div style={{ fontSize: 16, fontWeight: 600 }}>
                Brand Awarness/Announcement
              </div>
              <p className={classes.optionPara}>
                Showing your ads to create brand awarness or announcements
                regarding any new product/service.
              </p>
            </div>
            <RadioButtonCheckedIcon style={{ color: '#336CFB' }} />
          </div>
        ) : null}

        {costModel === 'perClick' ? (
          <div className={classes.option}>
            <PeopleIcon style={{ color: '#336CFB' }} />
            <div style={{ width: 500, paddingLeft: 30, paddingRight: 30 }}>
              <div style={{ fontSize: 16, fontWeight: 600 }}>
                Get more Website/Mobile App Sign ups
              </div>
              <p className={classes.optionPara}>
                Helps you to create ads and increase your app sign up counts.
              </p>
            </div>
            <RadioButtonCheckedIcon style={{ color: '#336CFB' }} />
          </div>
        ) : null}

        <Divider
          style={{
            marginTop: 50,
            marginBottom: 20,
            width: 1100,
            marginLeft: -30,
          }}
        />
        {/* Promo Goal End*/}

        {/* Link and Product */}
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <div style={{ width: 550 }}>
            {costModel === 'perClick' ? (
              <>
                <div className={classes.title}>
                  Share the link of your Website/Mobile App
                </div>
                <TextField
                  required
                  size="small"
                  variant="outlined"
                  name="url"
                  id="link"
                  value={link}
                  onFocus={handleFocus}
                  error={!focus.url && changes.url}
                  helperText={!focus.url && changes.url}
                  placeholder="Eg. https://mybrand.in"
                  onChange={(event) => setLink(event.target.value)}
                  style={{ width: 550, marginTop: 10, marginBottom: 15 }}
                />
              </>
            ) : null}
            <div className={classes.title}>
              Product/Service that you want to promote
            </div>
            <TextField
              size="small"
              variant="outlined"
              name="product"
              id="product"
              onFocus={handleFocus}
              error={!focus.product && changes.product}
              helperText={!focus.product && changes.product}
              value={product}
              placeholder="Eg. Telecom"
              onChange={(event) => setProduct(event.target.value)}
              style={{ width: 550, marginTop: 10, marginBottom: 15 }}
            />
          </div>
          {/* Link and Product End*/}

          {/* Date and Promo Mode*/}
          <div style={{ marginLeft: 80 }}>
            <div className={classes.title}>Start Date</div>
            <TextField
              variant="outlined"
              size="small"
              name="startDate"
              id="startDate"
              type="date"
              value={startDate}
              onChange={(event) => {
                var date1 = new Date(new Date().toDateString());
                var date2 = new Date(event.target.value);
                if (date1 <= date2) {
                  setStartDate(event.target.value);
                }
              }}
              style={{ width: 200, marginTop: 10, marginBottom: 15 }}
            />
            <div className={classes.title}>Choose your promo mode</div>
            <FormControl
              variant="outlined"
              size="small"
              style={{ width: 160, marginTop: 10, marginBottom: 15 }}
            >
              <Select
                id="promoType"
                name="promoType"
                value={promoType}
                disabled
              >
                <MenuItem value={'banner'}>banner</MenuItem>
                <MenuItem value={'story'}>story</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        <Divider
          style={{
            marginTop: 30,
            marginBottom: 20,
            width: 1100,
            marginLeft: -30,
          }}
        />

        <div style={{ display: 'flex' }}>
          <div style={{ width: 550 }}>
            {/* Stories Promo Info*/}
            {promoType === 'story' ? (
              <>
                <div>
                  <div className={classes.title}>Promo Info</div>
                  <TextField
                    required
                    variant="outlined"
                    size="small"
                    name="title"
                    id="promoTitle"
                    value={promoTitle}
                    onFocus={handleFocus}
                    error={!focus.title && changes.title}
                    helperText={
                      !focus.title &&
                      (changes.title ||
                        (promoTitle.length === 30
                          ? 'Maximum length is 30'
                          : null))
                    }
                    placeholder="Enter Title"
                    inputProps={{ maxLength: 30 }}
                    onChange={(event) => setPromoTitle(event.target.value)}
                    style={{ marginTop: 10 }}
                  />
                  <span></span>
                </div>
                <div>
                  <TextField
                    required
                    rows={4}
                    multiline
                    aria-label="Description area"
                    placeholder="Description"
                    id="promoDesc"
                    name="description"
                    value={promoDesc}
                    onFocus={handleFocus}
                    error={!focus.description && changes.description}
                    helperText={!focus.description && changes.description}
                    inputProps={{ maxLength: 50 }}
                    onChange={(event) => setPromoDesc(event.target.value)}
                    style={{
                      width: 550,
                      marginTop: 15,
                      padding: 5,
                      border: 'solid 1px #ccc',
                      borderRadius: 3,
                    }}
                  />
                </div>
              </>
            ) : null}
            {/*Stories Promo Info End */}

            {/* Banner or Stories Picture */}
            <div className="mt-4 relative">
              <input
                type="file"
                id="pictureUrl"
                accept="image/*"
                name="promoImage"
                onChange={handlePicture}
                onClick={handleFocus}
                className={classes.input}
              />
              <label htmlFor="pictureUrl" className="relative">
                <label className="text-eblue-800 font-semibold">
                  Your Promo Image
                </label>
                <Button
                  type="button"
                  className={pictureUrl ? 'uploadedBtn' : 'uploadBtn'}
                  disabled
                  style={{ marginLeft: 48 }}
                >
                  {pictureUrl ? pictureUrl.name : 'Upload'}
                </Button>
                <TextField
                  disabled
                  className="wellHidden"
                  error={!focus.promoImage && changes.promoImage}
                  helperText={!focus.promoImage && changes.promoImage}
                />
              </label>
            </div>
            {/* Banner or Stories Picture End */}
          </div>

          <div style={{ marginLeft: 80 }}>
            {/* Stories Logo */}
            {promoType === 'story' ? (
              <>
                <div className="mt-3 mb-12 relative">
                  <input
                    required
                    accept="image/*"
                    className={classes.input}
                    id="promoLogo"
                    name="promoLogo"
                    type="file"
                    onChange={handleLogo}
                    onClick={handleFocus}
                  />
                  <label htmlFor="promoLogo">
                    <label className="text-eblue-800 font-semibold">
                      Your Brand Logo
                    </label>
                    <Button
                      disabled
                      type="button"
                      className={logoUrl ? 'uploadedBtn' : 'uploadBtn'}
                    >
                      {logoUrl ? logoUrl.name : 'Upload'}
                    </Button>
                    <TextField
                      disabled
                      className="wellHidden"
                      error={!focus.promoLogo && changes.promoLogo}
                      helperText={!focus.promoLogo && changes.promoLogo}
                    />
                  </label>
                </div>
              </>
            ) : null}
            {/* Stories Logo End*/}

            {/* Promo Budget */}
            <div
              style={{ display: 'flex', alignItems: 'center', marginTop: 6 }}
            >
              <div className={classes.title} style={{ width: 200 }}>
                Total Budget (in INR)
                {/* <p style={{fontSize: 9, fontWeight: 'normal'}}>XXX x 6 days</p> */}
              </div>
              <Button
                variant="contained"
                size="large"
                style={{
                  backgroundColor: '#FFF',
                  color: '#336CFB',
                  fontSize: 18,
                  boxShadow: '0px 6px 18px rgba(16, 30, 115, 0.1)',
                  width: 160,
                  marginLeft: 20,
                }}
              >
                {budget ? budget : 'XXX'}
              </Button>
            </div>
          </div>
          {/* Promo Budget End */}
        </div>

        {/*Expected and Preview*/}
        <div style={{ display: 'flex', marginTop: 100 }}>
          <div>
            {promoType === 'banner' ? (
              <BannerPreview previewPicture={previewPicture} />
            ) : null}
            {promoType === 'story' ? (
              <BannerPreview previewLogo={previewLogo} />
            ) : null}
          </div>
          <div style={{ width: 360, marginLeft: 100 }}>
            {promoType === 'story' ? (
              <StoriesPreview
                previewPicture={previewPicture}
                promoTitle={promoTitle}
                promoDesc={promoDesc}
                storyColour={storyColour}
                setStoryColour={setStoryColour}
                storyLayout={storyLayout}
                setStoryLayout={setStoryLayout}
              />
            ) : null}
          </div>
        </div>

        <Divider
          style={{
            marginTop: 50,
            marginBottom: 30,
            width: 1100,
            marginLeft: -30,
          }}
        />
      </div>

      <div className={classes.submitDiv}>
        <div>
          <Checkbox
            required
            style={{ color: '#FFF' }}
            size="medium"
            inputProps={{ 'aria-label': 'privacy checkbox' }}
            name="accept"
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
          />
          <span className={classes.cbox}>
            By Clicking the Publish Button, you agree to ETark’s Promotion
            guidelines
          </span>
        </div>
        {!isLoading ? (
          <Button
            className="publbtn"
            disabled={!checkDisable()}
            variant="contained"
            size="large"
            type="submit"
            onClick={checkDisable() && handlePublish}
          >
            Resubmit
          </Button>
        ) : (
          <div className="flex items-center">
            <CircularProgress style={{ color: '#FFF' }} />
            <div className="text-sm text-gray-200 mx-4">{pstate}</div>
          </div>
        )}
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, { promoResubmission })(UpdateForm);

import React from 'react';
import ClearSharpIcon from '@material-ui/icons/ClearSharp';
import { HelpContainer, HelpText } from './styles';

const Help = ({ handleClose }) => {
  return (
    <HelpContainer>
      <ClearSharpIcon onClick={handleClose} />
      <h1>Help & Documentation</h1>
      <HelpText>
        <span>1.</span>
        <div>
          <p>Click on Get started and Select ETark Promo.</p>
        </div>
      </HelpText>
      <div>
        <img src="assets/images/help/help1.png" alt="" />
      </div>
      <HelpText>
        <span>2.</span>
        <div>
          <p>
            {' '}
            Fill in the Details to sign up and create an account with us. Select
            Organization if you are a local business (registered/unregistered),
            a startup, an established Indian company, an NGO or a global MNC.
            Otherwise if you are an individual like an artist, or someone who
            wishes to promote a message like wishing someone or making any
            announcement like inviting people for an event in your locality,
            select Individual. While creating an account, you have to verify
            both your phone number and email, so don’t forget that!
          </p>
        </div>
      </HelpText>
      <div>
        <img src="assets/images/help/help2.png" alt="" />
      </div>
      <HelpText>
        <span>3.</span>
        <div>
          <p>
            Once you create an account, you’ll see this Create a new promo
            section. Fill in the details to set up your first promo. In the
            Product/service field, you can mention any specific product/service
            or your entire business, whatever you want to promote. In the Start
            date, select the date from when you want your promo to go live. In
            Promo mode, you can choose between Banner and Story to select how
            you want your promo to appear. In the case of Promo mode as Banner,
            you would have to upload just one image of the content you want to
            promote, so we would suggest that you have all the relevant details
            in the image itself before uploading it. In the case of Promo mode
            as Story, you would be given option to write the title and
            description of your promotion and upload the logo of your business
            apart from the main content, so here you have the flexibility to
            simply click and upload images in the logo and main content without
            having to mention the details in the main image itself. For local
            businesses, we would recommend you to use Story as it gives you the
            flexibility of uploading images directly without having to mention
            any text on it.
          </p>
        </div>
      </HelpText>
      <div>
        <img src="assets/images/help/help3.png" alt="" />
      </div>
      <HelpText>
        <span>4.</span>
        <div>
          <p>
            Once you feed in all the details, our system immediately shows you
            the expected output in terms of the number of people your promo
            would be shown and the number of days your Promo would run on the
            ETark Platform. Here, you can also select if you want your Promo to
            be shown before a general audience or before a selected audience who
            are more likely to be interested in your product/service. Your
            Promo’s preview on the ETark Platform would also be shown to you so
            that it suits your requirement. Please note that the actual Ad
            duration and the actual total reach may differ with the expected
            values due to dynamic user behavior on the ETark Platform, although
            the total reach would be 99.99% the same as the expected value, the
            Ad duration may end early or end late that the Ad duration value
            because of surge or drop in user visit on the ETark Platform at
            different points of time. That means ultimately you’ll get the
            results as expected but it might at times be achieved early or late.
          </p>
        </div>
      </HelpText>
      <div>
        <img src="assets/images/help/help4.jpeg" alt="" />
      </div>

      <div>
        <img src="assets/images/help/help5.png" alt="" />
      </div>
      <HelpText>
        <span>5.</span>
        <div>
          <p>
            Finally if you are satisfied with the expected results and the Promo
            Previews, you may publish your Promo by selecting the checkbox and
            clicking on the Pay to Publish button to make the required payment
            as per the Ad budget set by you.
          </p>
        </div>
      </HelpText>
      <div>
        <img src="assets/images/help/help6.png" alt="" />
      </div>
      <HelpText>
        <span>6.</span>
        <div>
          <p>
            Once you publish your Promo with us, we’ll verify your Promo at your
            end to confirm if it complies with ETark’s Promotion Guidelines and
            then set the Promo live on the ETark Platform at the earliest.
          </p>
        </div>
      </HelpText>
      <div>
        <img src="assets/images/help/help7.png" alt="" />
      </div>
      <HelpText>
        <span>7.</span>
        <div>
          <p>
            You can track the live status of your Promo through the Existing
            Promo Section on your account.
          </p>
        </div>
      </HelpText>
      <div>
        <img src="assets/images/help/help8.jpeg" alt="" />
      </div>
    </HelpContainer>
  );
};

export default Help;

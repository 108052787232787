import styled, { css } from 'styled-components/macro';

export const CustomTable = styled.div`
  display: flex;
  background-color: #fefefe;
  height: max-content;
  width: 100%;
  margin-top: 1rem;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
`;

const CommonLeftRightStyles = css`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Left = styled.div`
  ${CommonLeftRightStyles};
  min-width: 7rem !important;
  @media (max-width: 550px) {
    font-size: 14px;
  }
  @media (max-width: 400px) {
    font-size: 13px;
  }
`;

export const Right = styled.div`
  ${CommonLeftRightStyles};
  box-shadow: -1px 0 0 0 #dbdbdb;

  @media (max-width: 550px) {
    font-size: 13px;
    font-weight: 500;
  }
`;

const CommonHeadInfoStyles = css`
  display: flex;
  align-items: center;
  height: 2.5rem;
  padding: 5px 15px;
  letter-spacing: 0.65px;
  &:nth-child(2n + 1) {
    background-color: #d0d0d035;
  }

  @media (max-width: 500px) {
    padding: 5px 5px;
  }
`;

export const Head = styled.div`
  ${CommonHeadInfoStyles};
  font-weight: 600;
`;

export const Info = styled.div`
  ${CommonHeadInfoStyles};
  font-weight: 300;
  flex-direction: row;
`;

export const Icon = styled.div`
  margin-left: 5px;
  margin-bottom: 1px;
  & .MuiSvgIcon-root {
    font-size: 20px;
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import Tab from './Tab';
import { connect, useDispatch } from 'react-redux';

import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PendingPage = ({ promos, actionMsg }) => {
  const dispatch = useDispatch();
  const demoFunc = () => {
    dispatch({ type: 'ACTION_RESET' });
    window.location.reload();
  };

  return (
    <div style={{ padding: 36 }}>
      <div style={{ fontSize: 40, fontWeight: '700' }}>
        Pending for Verification Dashboard
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={actionMsg && actionMsg !== ''}
        key={'topcenter'}
        autoHideDuration={2000}
        onClose={demoFunc}
      >
        {actionMsg !== '' ? (
          <Alert severity={actionMsg.includes('fail') ? 'error' : 'success'}>
            <span style={{ fontFamily: 'Poppins' }}>{actionMsg}</span>
          </Alert>
        ) : null}
      </Snackbar>
      {promos.map((promo) => {
        if (promo.status === 'pending')
          return <Tab promo={promo} type="pending" />;
      })}
    </div>
  );
};

PendingPage.propTypes = {
  promos: PropTypes.array.isRequired,
  actionMsg: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  promos: state.internal.promos,
  actionMsg: state.internal.actionMsg,
});

export default connect(mapStateToProps)(PendingPage);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getDistance } from 'geolib';
import { connect } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import PeopleIcon from '@material-ui/icons/People';
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
// import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import Button from '@material-ui/core/Button';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import ExpectedAdDuration from './ExpectedAdDuration';
import BannerPreview from './BannerPreview';
import StoriesPreview from './StoriesPreview';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import LocationBar from './LocationBar';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CloseIcon from '@material-ui/icons/Close';

import Resizer from 'react-image-file-resizer';
import cities from './cities';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import {
  AddPicture,
  OptionPara,
  StyledBtn,
  StyledTextField,
  SubTitle,
  Title,
} from './styles';
import { Modal } from '../helpers';
import { Guidlines } from '../Home';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const prodapi = process.env.REACT_APP_API;
const paytmapi = process.env.REACT_APP_PAYTM;
const mid = process.env.REACT_APP_MID;

const PromoTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: 14,
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  option: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 30,
    cursor: 'pointer',
    width: '100%',
  },
  input: {
    display: 'none',
  },
  cbox: {
    fontSize: 14,
    fontWeight: 400,
    color: '#FFF',
  },
}));

const CreateForm = ({ error, promo }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [pstate, setPstate] = useState('');
  const [promoId, setPromoId] = useState('');
  const [costModel, setCostModel] = useState('perView');
  const [link, setLink] = useState('');
  const [product, setProduct] = useState('');
  const [startDate, SetStartDate] = useState(
    new Date().toLocaleDateString('en-CA')
  );
  const [promoType, setPromoType] = useState('banner');
  const [promoImage, setPromoImage] = useState(''); //60fbfd47d3a0192266e585c3
  const [promoTitle, setPromoTitle] = useState('');
  const [promoDesc, setPromoDesc] = useState('');
  const [promoLogo, setPromoLogo] = useState('');
  const [budget, setBudget] = useState('');
  const [promoWay, setPromoWay] = useState(false);

  const [storyColour, setStoryColour] = useState('#34393E');
  const [storyLayout, setStoryLayout] = useState('layout1');

  const [pictureUrl, setPictureUrl] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);
  const [checked, setChecked] = useState(false);

  const [previewPicture, setPreviewPicture] = useState('');
  const [previewLogo, setPreviewLogo] = useState('');

  const [txnToken, setTxnToken] = useState('');
  const [order, setOrder] = useState({});
  const [clked, setClicked] = useState(false);
  const [apiErr, setError] = useState(false);
  const [errMsg, setMsg] = useState('');

  const [locs, setLocs] = useState([]);
  const [pview, setPviews] = useState(0);

  const [guidlines, setGuidlines] = useState(false);

  const selectViews = async () => {
    setCostModel('perView');
  };
  const selectClicks = async () => {
    setCostModel('perClick');
  };

  const handlePublish = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setPstate('Uploading Assets');
    setClicked(true);
    uploadPicture();
  };

  const handleCompression = (file) => {
    return new Promise((resolve, reject) => {
      try {
        Resizer.imageFileResizer(
          file,
          1000,
          1000,
          'JPEG',
          90,
          0,
          (uri) => {
            resolve(uri);
          },
          'file'
        );
      } catch (err) {
        console.log(err);
        resolve(file);
      }
    });
  };

  const uploadPicture = () => {
    var tmpImage = localStorage.getItem('promoImage') || '';
    if (tmpImage !== '' && pictureUrl.his === true) {
      setPromoImage(tmpImage);
      return;
    }

    let pictureData = new FormData();
    if (pictureUrl !== null) {
      pictureData.append('files', pictureUrl, pictureUrl.name);
      localStorage.setItem('previewPicture', previewPicture);
      localStorage.setItem('pictureName', pictureUrl.name);
      localStorage.setItem('promoImage', null);

      try {
        axios
          .post(`${prodapi}/upload`, pictureData)
          .then((res) => {
            // console.log(res.data[0].id);
            setPromoImage(res.data[0].id);
            localStorage.setItem('promoImage', res.data[0].id);
          })
          .catch(function (error) {
            // console.log(error.message);
            setIsLoading(false);
            setError(true);
            setMsg('Upload Error ! Please Refresh');
          });
      } catch (error) {
        // console.log(error);
        setIsLoading(false);
        setError(true);
        setMsg('Upload Error ! Please Refresh');
      }
    }
  };

  const uploadLogo = () => {
    var tmpLogo = localStorage.getItem('promoLogo') || '';
    if (tmpLogo !== '' && logoUrl.his === true) {
      setPromoLogo(tmpLogo);
      return;
    }

    let logoData = new FormData();
    if (logoUrl !== null) {
      logoData.append('files', logoUrl, logoUrl.name);
      localStorage.setItem('previewLogo', previewLogo);
      localStorage.setItem('logoName', logoUrl.name);
      localStorage.setItem('promoLogo', null);

      try {
        axios
          .post(`${prodapi}/upload`, logoData)
          .then((res) => {
            setPromoLogo(res.data[0].id);
            localStorage.setItem('promoLogo', res.data[0].id);
          })
          .catch(function (error) {
            // console.log(error.message);
            setIsLoading(false);
            setError(true);
            setMsg('Upload Error ! Please Refresh');
          });
      } catch (error) {
        // console.log(error);
        setIsLoading(false);
        setError(true);
        setMsg('Upload Error ! Please Refresh');
      }
    }
  };

  const handleSubmit = async () => {
    // console.log(promoImage);
    setPstate('Publishing your promo');
    var data = JSON.stringify({
      budget: budget,
      budgetLeft: budget,
      busz: localStorage.getItem('id'),
      costModel: costModel,
      isRelevant: promoWay,
      product: product,
      promoImage: promoImage,
      promoType: promoType,
      startDate: startDate,
      status: 'notpaid',
      ...(costModel === 'perClick' && {
        url: link,
      }),
      ...(promoType === 'story' && {
        title: promoTitle,
        description: promoDesc,
        promoLogo: promoLogo,
        layout: storyLayout,
        color: storyColour,
      }),
    });
    const jwt = localStorage.getItem('jwt');
    var config = {
      method: 'post',
      url: `${prodapi}/promos`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      data: data,
    };

    axios(config)
      .then((res) => {
        console.log('created');
        // setIsLoading(false);
        setPromoId(res.data.id);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
        setError(true);
        setMsg(error.message);
      });
  };

  useEffect(() => {
    if (promoImage !== '' && clked) {
      if (promoType === 'story') {
        uploadLogo();
      } else {
        handleSubmit();
      }
    }
  }, [promoImage]);

  useEffect(() => {
    if (promoLogo !== '' && clked) {
      handleSubmit();
    }
  }, [promoLogo]);

  const handlePicture = (event) => {
    if (event.target.files[0]) {
      handleCompression(event.target.files[0]).then((file) => {
        setPictureUrl(file);
        var reader = new FileReader();
        reader.onload = function (base64) {
          setPreviewPicture(base64.target.result);
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const handleLogo = (event) => {
    if (event.target.files[0]) {
      handleCompression(event.target.files[0]).then((file) => {
        console.log(file);
        setLogoUrl(file);
        var reader = new FileReader();
        reader.onload = function (base64) {
          setPreviewLogo(base64.target.result);
        };
        reader.readAsDataURL(file);
      });
    }
  };

  useEffect(() => {
    if (!clked) {
      var pv0 = localStorage.getItem('previewPicture');
      var pv1 = localStorage.getItem('pictureName');
      var pid = localStorage.getItem('promoImage');

      if (pv0 && pid !== null) setPreviewPicture(pv0);
      if (pv1 && pid !== null) setPictureUrl({ name: pv1, his: true });

      var pl0 = localStorage.getItem('previewLogo');
      var pl1 = localStorage.getItem('logoName');
      var plid = localStorage.getItem('promoLogo');

      if (pl0 && plid !== null) setPreviewLogo(pl0);
      if (pl1 && plid !== null) setLogoUrl({ name: pl1, his: true });
    }
  }, [clked]);

  const checkDisable = () => {
    var res = true;
    res &= product !== '';
    if (costModel === 'perClick') {
      res &= link !== '';
    }

    res &= pictureUrl !== null;
    if (promoType === 'story') {
      res &= promoTitle !== '';
      res &= promoDesc !== '';
      res &= logoUrl !== null;
    }

    res &= budget !== '' && budget >= 100;
    res &= checked;

    return res;
  };

  const startPay = () => {
    var data = {
      promo: promoId,
      Amount: budget,
    };

    axios
      .post(`${prodapi}/promopayments`, data)
      .then((res) => res.data)
      .then((data) => {
        if (data.status === 200) {
          // console.log("initiated");
          setTxnToken(data.txnToken);
          setOrder(data.order);
        } else {
          setError(true);
          // console.log("err 0");
          setMsg(data.message);
        }
      })
      .catch((err) => {
        // console.log(err);
        setError(true);
        setMsg(err.message);
      });
  };

  useEffect(() => {
    if (promoId) {
      // console.log("hmm",promoId);
      setPstate('Requesting Payment');
      startPay();
    }
  }, [promoId]);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const displayPaytm = async () => {
    var paytmApi = `${paytmapi}/merchantpgpui/checkoutjs/merchants/${mid}.js`;
    console.log(paytmApi);
    const res = await loadScript(paytmApi);
    if (!res) {
      setError(true);
      setMsg('Paytm failed to load');
      return;
    }

    var config = {
      root: '',
      flow: 'WEBSTAGING',
      data: {
        orderId: order.OrderID /* update order id */,
        token: txnToken /* update token value */,
        tokenType: 'TXN_TOKEN',
        amount: budget /* update amount */,
      },
      handler: {
        notifyMerchant: function (eventName, data) {
          console.log('eventName => ', eventName);
          console.log('data => ', data);
        },
      },
    };

    if (window.Paytm && window.Paytm.CheckoutJS) {
      window.Paytm.CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
        // initialze configuration using init method
        window.Paytm.CheckoutJS.init(config)
          .then(function onSuccess() {
            // after successfully updating configuration, invoke Blink Checkout
            window.Paytm.CheckoutJS.invoke();
          })
          .catch(function onError(error) {
            console.log('error => ', error);
            setError(true);
            setMsg(error.message);
          });
      });
    }
  };

  useEffect(() => {
    if (txnToken && order.OrderID) {
      // console.log("hmm2",txnToken, order.OrderID);
      setPstate('Opening payment window');
      displayPaytm();
    }
  }, [txnToken, order.OrderID]);

  const handleArray = (entry) => {
    var tmpLocs = [...locs],
      doExist = false;
    for (var i = 0; i < tmpLocs.length; i++) {
      if (tmpLocs[i].location.trim() === entry.location.trim()) {
        var doExist = true;
        break;
      }
    }

    if (!doExist) tmpLocs.push(entry);
    setLocs(tmpLocs);
  };

  const containsIndia = () => {
    for (var i = 0; i < locs.length; i++) {
      if (locs[i].location.trim().toLowerCase() === 'india') return true;
    }

    return false;
  };

  const popArray = (e) => {
    var idx = e.target.getAttribute('value');
    var tmpLocs = [...locs];
    tmpLocs.splice(idx, 1);
    setLocs(tmpLocs);
  };

  const addNoise = (num, range) => {
    var x = Math.sin(new Date().getHours() + new Date().getDate()) * 10000;
    x -= Math.floor(x);

    return Math.floor(num + (2 * x - 1) * range * num);
  };

  const calculateRegional = (num) => {
    if (containsIndia()) return num;

    var weights = 0;
    for (var i = 0; i < locs.length; i++) {
      var pw = 0;
      cities.map((tmpCity) => {
        var tmpDis = getDistance(
          {
            latitude: locs[i].lat,
            longitude: locs[i].lng,
          },
          {
            latitude: tmpCity.latitude,
            longitude: tmpCity.longitude,
          }
        );

        tmpDis = Math.max(0, tmpDis - 10000);
        tmpDis /= 10000;
        pw += Math.exp(-tmpDis);
      });

      weights += pw;
    }

    var frac = 1 - Math.exp(-weights / 2);
    return Math.floor(((frac + 1) * num) / 2);
  };

  const calculateEstimated = () => {
    var priceModel = {
      banner: {
        perView: [5, 7.5],
        perClick: [20, 30],
      },
      story: {
        perView: [1, 1.5],
        perClick: [10, 15],
      },
    };

    var reach = 0;
    reach += budget / priceModel[promoType][costModel][0];

    if (locs.length) {
      reach = calculateRegional(reach);
    }

    if (promoWay) {
      reach = Math.floor(addNoise(reach, 0.05) * 0.8);
    }

    reach = addNoise(reach, 0.05);
    return [reach, Math.floor(reach / (pview + 1))];
  };

  useEffect(() => {
    var vtype = costModel === 'perView' ? 'homepage_enter' : 'banner_click';

    axios
      .get(`${prodapi}/viewlogs/currStats?type=${vtype}`)
      .then((res) => {
        setPviews(res.data);
      }).catch((err) => {
        console.log(err.message);
        console.log(err.response && err.response.data.message);
      });
  }, [costModel]);

  return (
    <div>
      <form>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={apiErr}
          key={'topcenter'}
          autoHideDuration={3000}
        >
          <Alert severity="error">
            <span style={{ fontFamily: 'Poppins' }}>{errMsg}</span>
          </Alert>
        </Snackbar>
        <div className="createForm__container">
          {/* Promo Goal */}
          <Title>What’s your promotion goal?</Title>
          <div className={classes.option} onClick={selectViews}>
            <BrandingWatermarkIcon style={{ color: '#336CFB' }} />
            <div style={{ width: 500, paddingLeft: 30, paddingRight: 30 }}>
              <SubTitle>Brand Awarness/Announcement</SubTitle>
              <OptionPara>
                Showing your ads to create brand awarness or announcements
                regarding any new product/service.
              </OptionPara>
            </div>
            {costModel === 'perView' ? (
              <RadioButtonCheckedIcon
                style={{ color: '#336CFB' }}
                onClick={selectViews}
              />
            ) : (
              <RadioButtonCheckedIcon style={{ color: '#C4C4C4' }} />
            )}
          </div>
          {false ? (
            <div className={classes.option} onClick={selectClicks}>
              <PeopleIcon style={{ color: '#336CFB' }} />
              <div style={{ width: 500, paddingLeft: 30, paddingRight: 30 }}>
                <div style={{ fontSize: 16, fontWeight: 600 }}>
                  Get more Website/Mobile App Sign ups
                </div>
                <OptionPara>
                  Helps you to create ads and increase your app sign up counts.
                </OptionPara>
              </div>
              {costModel === 'perClick' ? (
                <RadioButtonCheckedIcon
                  style={{ color: '#336CFB' }}
                  onClick={selectClicks}
                />
              ) : (
                <RadioButtonCheckedIcon style={{ color: '#C4C4C4' }} />
              )}
            </div>
          ) : null}
          <Divider
            style={{
              marginTop: 50,
              marginBottom: 20,
              width: '100%',
              marginLeft: -30,
            }}
          />
          {/* Promo Goal End*/}
          {/* Link and Product */}
          <div className="service__promote">
            <div className="service__promote--container">
              {costModel === 'perClick' ? (
                <>
                  <Title>Share the link of your Website/Mobile App</Title>
                  <TextField
                    required
                    size="small"
                    variant="outlined"
                    name="link"
                    id="link"
                    value={link}
                    placeholder="Eg. https://mybrand.in"
                    onChange={(event) => setLink(event.target.value)}
                    style={{ width: 550, marginTop: 10, marginBottom: 15 }}
                  />
                </>
              ) : null}
              <Title> Product/Service that you want to promote</Title>

              <TextField
                required
                size="small"
                variant="outlined"
                name="product"
                id="product"
                value={product}
                placeholder="Eg. Telecom"
                onChange={(event) => setProduct(event.target.value)}
                className="service__promote--textfield"
              />
            </div>
            {/* Link and Product End*/}

            {/* Date and Promo Mode*/}
            <div className="dateAndPromo">
              <Title>Start Date</Title>
              <TextField
                className="dateAndPromo--calender"
                variant="outlined"
                size="small"
                name="startDate"
                id="startDate"
                type="date"
                value={startDate}
                onChange={(event) => {
                  var date1 = new Date(new Date().toDateString());
                  var date2 = new Date(event.target.value);
                  if (date1 <= date2) {
                    SetStartDate(event.target.value);
                  }
                }}
              />
              <Title>Choose your promo mode</Title>
              <FormControl
                variant="outlined"
                size="small"
                style={{ width: 160, marginTop: 10, marginBottom: 15 }}
              >
                <Select
                  id="promoType"
                  name="promoType"
                  value={promoType}
                  onChange={(event) => setPromoType(event.target.value)}
                >
                  <MenuItem className="menuItem" value={'banner'}>
                    banner
                  </MenuItem>
                  <MenuItem className="menuItem" value={'story'}>
                    story
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <Divider
            style={{
              marginTop: 30,
              marginBottom: 20,
              width: '100%',
              marginLeft: -30,
            }}
          />

          {/*Location*/}
          <div className="dateAndPromoEnd">
            <div>
              <Title>
                Enter the location where you want to run your promo
                <span className="text-xs">(optional)</span>
              </Title>
              <LocationBar handleArray={handleArray} />
            </div>
            <div className="dateAndPromoEnd--rightSection">
              <Title>India</Title>
              <div className="locWrp">
                {locs.map((loc, idx) => {
                  return (
                    <div className="locEnt">
                      <div className="mr-4 flex items-center">
                        <LocationOnIcon className="pmColor" />
                        <div className="locname text-xs">{loc.location}</div>
                      </div>
                      <CloseIcon
                        className="pmColor handcr"
                        value={idx}
                        onClick={popArray}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <Divider
            style={{
              marginTop: 30,
              marginBottom: 20,
              width: '100%',
              marginLeft: -30,
            }}
          />
          <div className="stories__promo">
            <div className="stories__promo--info">
              {/* Stories Promo Info*/}
              {promoType === 'story' ? (
                <>
                  <div>
                    <Title>Promo Info</Title>
                    <StyledTextField
                      required
                      border="none"
                      padding="0px"
                      variant="outlined"
                      size="small"
                      name="promoTitle"
                      id="promoTitle"
                      value={promoTitle}
                      error={promoTitle.length >= 30}
                      helperText={
                        promoTitle.length === 30 ? 'Maximum length is 30' : null
                      }
                      placeholder="Enter Title"
                      inputProps={{ maxLength: 30 }}
                      onChange={(event) => setPromoTitle(event.target.value)}
                      style={{ marginTop: 10 }}
                    />
                    <span>
                      <PromoTooltip title="Promo Title">
                        <InfoIcon
                          style={{
                            color: 'C4C4C4',
                            marginTop: 15,
                            marginLeft: 10,
                          }}
                        />
                      </PromoTooltip>
                    </span>
                  </div>
                  <div className="descriptionArea">
                    <StyledTextField
                      required
                      rows={3}
                      multiline
                      padding="10px"
                      aria-label="Description area"
                      placeholder="Description"
                      name="promoDesc"
                      id="promoDesc"
                      value={promoDesc}
                      inputProps={{ maxLength: 100 }}
                      onChange={(event) => setPromoDesc(event.target.value)}
                      error={promoDesc.length >= 100}
                      helperText={
                        promoDesc.length === 100 ? 'Maximum length is 100' : ''
                      }
                    />
                  </div>
                </>
              ) : null}
              {/*Stories Promo Info End */}

              {/* Banner or Stories Picture */}
              <div style={{ marginTop: 15 }}>
                <input
                  required
                  accept="image/*"
                  className={classes.input}
                  id="pictureUrl"
                  name="pictureUrl"
                  type="file"
                  onChange={handlePicture}
                />
                <label htmlFor="pictureUrl">
                  <AddPicture variant="contained" size="large" component="span">
                    <PhotoLibraryIcon
                      style={{ marginRight: 10, fontSize: 16 }}
                    />
                    Add Picture
                  </AddPicture>
                  <StyledBtn
                    type="button"
                    className={pictureUrl ? 'uploadedBtn' : 'uploadBtn'}
                    disabled
                    style={{
                      color: '#FFF',
                      fontSize: 14,
                      width: 160,
                      marginLeft: 82,
                      textTransform: 'none',
                    }}
                  >
                    {pictureUrl ? pictureUrl.name : 'Upload'}
                  </StyledBtn>
                </label>
              </div>
              {/* Banner or Stories Picture End */}
            </div>

            <div className="stories__promo--details">
              {/* Stories Logo */}
              {promoType === 'story' ? (
                <>
                  <Title>Upload your Logo</Title>
                  <div
                    style={{
                      marginTop: 10,
                      marginBottom: 40,
                      minWidth: '360px',
                    }}
                  >
                    <input
                      required
                      accept="image/*"
                      className={classes.input}
                      id="promoLogo"
                      name="promoLogo"
                      type="file"
                      onChange={handleLogo}
                    />
                    <label htmlFor="promoLogo" className="label">
                      <AddPicture
                        variant="contained"
                        size="large"
                        component="span"
                      >
                        <PhotoLibraryIcon
                          style={{ marginRight: 10, fontSize: 16 }}
                        />
                        Add Picture
                      </AddPicture>

                      <StyledBtn
                        // className="uploadbtn"
                        type="button"
                        className={logoUrl ? 'uploadedBtn' : 'uploadBtn'}
                        disabled
                      >
                        {logoUrl ? logoUrl.name : 'Upload'}
                      </StyledBtn>
                    </label>
                  </div>
                </>
              ) : null}
              {/* Stories Logo End*/}

              {/* Promo Budget */}
              <div className="promoBudget">
                <div className="promoBudget--label">
                  Add your promo budget (in INR)
                </div>
                <TextField
                  className="promoBudget--value"
                  variant="outlined"
                  size="small"
                  name="budget"
                  id="budget"
                  value={budget}
                  type="number"
                  autoComplete="off"
                  error={budget !== '' && budget < 100}
                  helperText={
                    budget !== '' && budget < 100
                      ? 'Enter a value of minimum 100'
                      : null
                  }
                  onChange={(event) => {
                    var num = event.target.value;
                    if (num > 0) {
                      setBudget(parseInt(num));
                    }
                  }}
                  style={{ width: 160, marginLeft: 20 }}
                />
              </div>
              <div
                style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}
              >
                <div className="promoBudget--label">
                  Total (in INR)
                  {/* <p style={{fontSize: 9, fontWeight: 'normal'}}>XXX x 6 days</p> */}
                </div>
                <Button
                  className="promoBudget--value"
                  variant="contained"
                  size="large"
                  style={{
                    backgroundColor: '#FFF',
                    color: '#336CFB',
                    fontSize: 18,
                    boxShadow: '0px 12px 26px rgba(16, 30, 115, 0.1)',
                    width: 160,
                    minWidth: '120px',
                    marginLeft: 20,
                  }}
                >
                  {budget ? budget : 'XXX'}
                </Button>
              </div>
            </div>
            {/* Promo Budget End */}
          </div>
          {/*Expected and Preview*/}
          <div
            className={`${
              promoType === 'banner' && 'banner'
            } expectedAndPreview`}
          >
            <div
              className={` ${
                promoType === 'story' && 'story'
              } expectedAndPreview--duration`}
            >
              <ExpectedAdDuration
                promoWay={promoWay}
                setPromoWay={setPromoWay}
                calculateEstimated={calculateEstimated}
              />
              <br />
              {promoType === 'story' ? (
                <BannerPreview previewLogo={previewLogo} />
              ) : null}
            </div>
            <div className="expectedAndPreview--story">
              {promoType === 'story' ? (
                <div className="storyPreview">
                  <StoriesPreview
                    previewPicture={previewPicture}
                    promoTitle={promoTitle}
                    promoDesc={promoDesc}
                    storyColour={storyColour}
                    setStoryColour={setStoryColour}
                    storyLayout={storyLayout}
                    setStoryLayout={setStoryLayout}
                  />
                </div>
              ) : (
                <BannerPreview previewPicture={previewPicture} />
              )}
            </div>
          </div>
          <Divider
            style={{
              marginTop: 50,
              marginBottom: 30,
              width: '105%',
              marginLeft: -30,
              backgroundColor: 'transparent',
            }}
          />
        </div>

        <div className="submitDiv">
          <div className="submitDiv--text">
            <Checkbox
              required
              style={{ color: '#FFF' }}
              size="medium"
              inputProps={{ 'aria-label': 'privacy checkbox' }}
              name="accept"
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
            />
            <span
              onClick={() => setGuidlines(true)}
              className={classes.cbox}
              style={{ cursor: 'pointer' }}
            >
              By Clicking the Publish Button, you agree to ETark’s Promotion
              guidelines
            </span>
          </div>
          {!isLoading ? (
            <Button
              className="publbtn"
              disabled={!checkDisable()}
              variant="contained"
              size="large"
              type="submit"
              onClick={checkDisable() && handlePublish}
            >
              Pay to Publish
            </Button>
          ) : (
            <div className="flex items-center">
              <CircularProgress style={{ color: '#FFF' }} />
              <div className="text-sm text-gray-200 mx-4">{pstate}</div>
            </div>
          )}
        </div>
      </form>
      <Modal open={guidlines} handleClose={() => setGuidlines(false)}>
        <Guidlines handleClose={() => setGuidlines(false)} />
      </Modal>
    </div>
  );
};

CreateForm.propTypes = {
  promo: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  promo: state.promo,
  error: state.error,
});

export default connect(mapStateToProps)(CreateForm);

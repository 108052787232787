import React from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';

import CreateForm from './CreateForm';
import UpdateForm from './UpdateForm';

import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CreatePage = ({ isCreate, promoId, actionMsg }) => {
  const dispatch = useDispatch();
  // console.log(isCreate, promoId, actionMsg);
  const demoFunc = () => {
    dispatch({ type: 'PROMOD_RESET' });
    window.location.reload();
  };

  return (
    <div className="initialPadding">
      <div className="heading">Control Dashboard</div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={actionMsg && actionMsg !== ''}
        key={'topcenter'}
        autoHideDuration={2000}
        onClose={demoFunc}
      >
        {actionMsg !== '' ? (
          <Alert severity={actionMsg.includes('fail') ? 'error' : 'success'}>
            <span style={{ fontFamily: 'Poppins' }}>{actionMsg}</span>
          </Alert>
        ) : null}
      </Snackbar>
      <div>
        {isCreate ? (
          <div
            style={{
              width: '100%',
              marginTop: 20,
            }}
          >
            <CreateForm />
          </div>
        ) : (
          <div style={{ width: '100%', marginTop: 20 }}>
            <UpdateForm promoId={promoId} />
          </div>
        )}
      </div>
    </div>
  );
};

CreatePage.propTypes = {
  isCreate: PropTypes.bool,
  promoId: PropTypes.string,
  actionMsg: PropTypes.string,
};

const mapStateToProps = (state) => ({
  actionMsg: state.promo.actionMsg,
});

export default connect(mapStateToProps)(CreatePage);

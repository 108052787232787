import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ActivePage from './ActivePage';
import RejectedPage from './RejectedPage';
import CompletedPage from './CompletedPage';
import PendingPage from './PendingPage';
import ModificationPage from './ModificationPage';
import PausedPage from './PausedPage';
import Button from '@material-ui/core/Button';
import IntLeftBar from './IntLeftBar';
import { connect } from 'react-redux';
import { logoutIntUser } from '../../redux/actions/internalActions';
import PromoAccount from './PromoAccount';

const IntDashboard = (props) => {
  const { logoutIntUser } = props;
  const [promo, setPromo] = useState(false);
  const [pending, setPending] = useState(true);
  const [modification, setModification] = useState(false);
  const [active, setActive] = useState(false);
  const [paused, setPaused] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [rejected, setRejected] = useState(false);

  const promoAccount = [];
  const activePromo = [];
  const completedPromo = [];
  const pendingPromo = [];
  const pausedPromo = [];
  const rejectedPromo = [];
  const modifiedPromo = [];

  const selectPromo = async () => {
    setPromo(true);
    setPending(false);
    setModification(false);
    setActive(false);
    setPaused(false);
    setCompleted(false);
    setRejected(false);
  };

  const selectPending = async () => {
    setPending(true);
    setPromo(false);
    setModification(false);
    setActive(false);
    setPaused(false);
    setCompleted(false);
    setRejected(false);
  };
  const selectModification = async () => {
    setPromo(false);
    setPending(false);
    setModification(true);
    setActive(false);
    setPaused(false);
    setCompleted(false);
    setRejected(false);
  };
  const selectActive = async () => {
    setPromo(false);
    setPending(false);
    setModification(false);
    setActive(true);
    setPaused(false);
    setCompleted(false);
    setRejected(false);
  };
  const selectPaused = async () => {
    setPromo(false);
    setPending(false);
    setModification(false);
    setActive(false);
    setPaused(true);
    setCompleted(false);
    setRejected(false);
  };
  const selectCompleted = async () => {
    setPromo(false);
    setPending(false);
    setModification(false);
    setActive(false);
    setPaused(false);
    setCompleted(true);
    setRejected(false);
  };
  const selectRejected = async () => {
    setPromo(false);
    setPending(false);
    setModification(false);
    setActive(false);
    setPaused(false);
    setCompleted(false);
    setRejected(true);
  };

  const handleLogout = () => {
    logoutIntUser();
  };

  return (
    <div
      style={{
        display: 'flex',
        minWidth: '100vw',
        minHeight: '100vh',
      }}
    >
      <IntLeftBar
        promo={promo}
        pending={pending}
        modification={modification}
        completed={completed}
        active={active}
        paused={paused}
        rejected={rejected}
        selectPromo={selectPromo}
        selectPending={selectPending}
        selectModification={selectModification}
        selectCompleted={selectCompleted}
        selectPaused={selectPaused}
        selectActive={selectActive}
        selectRejected={selectRejected}
      />
      <div style={{ backgroundColor: '#F6F8FB', width: '100%' }}>
        <Button
          type="button"
          variant="contained"
          color="primary"
          style={{ position: 'fixed', top: '3%', right: '1%' }}
          onClick={handleLogout}
        >
          Logout
        </Button>
        {promo ? <PromoAccount promoAccount={promoAccount} /> : <></>}
        {pending ? <PendingPage pendingPromo={pendingPromo} /> : <></>}
        {modification ? (
          <ModificationPage modifiedPromo={modifiedPromo} />
        ) : (
          <></>
        )}
        {completed ? <CompletedPage completedPromo={completedPromo} /> : <></>}
        {paused ? <PausedPage pausedPromo={pausedPromo} /> : <></>}
        {active ? <ActivePage activePromo={activePromo} /> : <></>}
        {rejected ? <RejectedPage rejectedPromo={rejectedPromo} /> : <></>}
      </div>
    </div>
  );
};

IntDashboard.propTypes = {
  logoutIntUser: PropTypes.func.isRequired,
  promos: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, { logoutIntUser })(IntDashboard);

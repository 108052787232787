import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Dashboard from '../Dashboard/Dashboard';

const ProtectedRoute = ({ isAuthenticated, query }) => {
  if (isAuthenticated) {
    var params = Object.fromEntries(new URLSearchParams(query));
    return (
      <>
        <Route
          path="/dashboard"
          render={(props) => <Dashboard params={params} />}
        />
      </>
    );
  } else {
    return <Redirect to="/signin" />;
  }
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

ProtectedRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
  query: PropTypes.object,
};

export default connect(mapStateToProps, {})(ProtectedRoute);

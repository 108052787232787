export const GET_PROMOS = 'GET_PROMOS';
export const DELETE_PROMOS = 'DELETE_PROMOS';
export const PROMOS_LOADING = 'PROMOS_LOADING';
export const PROMOS_FAIL = 'PROMOS_FAIL';
export const GET_PROMO_ACCOUNT = 'GET_PROMO_ACCOUNT';

export const USER_LOGGEDIN = 'USER_LOGGEDIN';
export const USER_NOT_LOGGEDIN = 'USER_NOT_LOGGEDIN';

export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAIL = 'SIGNIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';

export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const BLUR = 'BLUR';

export const VERIFY_DIALOG = 'VERIFY_DIALOG';
export const VERIFY_LOADING = 'VERIFY_LOADING';
export const OTP_SENT = 'OTP_SENT';
export const EMAIL_SENT = 'EMAIL_SENT';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const VERIFY_FAIL = 'VERIFY_FAIL';

export const GET_VERIFYERRORS = 'GET_VERIFYERRORS';
export const CLEAR_VERIFYERRORS = 'CLEAR_VERIFYERRORS';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';

// internal
export const INTUSER_LOGGEDIN = 'INTUSER_LOGGEDIN';
export const INTUSER_NOT_LOGGEDIN = 'INTUSER_NOT_LOGGEDIN';
export const INTUSER_LOADING = 'INTUSER_LOADING';
export const INTUSER_LOADED = 'INTUSER_LOADED';
export const INTSIGNIN_SUCCESS = 'INTSIGNIN_SUCCESS';
export const INTSIGNIN_FAIL = 'INTSIGNIN_FAIL';
export const INTLOGOUT_SUCCESS = 'INTLOGOUT_SUCCESS';
export const GET_INTPROMOS = 'GET_INTPROMOS';
export const INTPROMOS_FAIL = 'INTPROMOS_FAIL';
export const ACTION_FAIL = 'ACTION_FAIL';
export const ACTION_SUCCESS = 'ACTION_SUCCESS';
export const ACTION_RESET = 'ACTION_RESET';

export const PROMOD_SUCCESS = 'PROMOD_SUCCESS';
export const PROMOD_FAIL = 'PROMOD_FAIL';
export const PROMOD_RESET = 'PROMOD_RESET';

import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  SIGNIN_FAIL,
  SIGNIN_SUCCESS,
  SIGNUP_FAIL,
  SIGNUP_SUCCESS,
  LOGOUT_SUCCESS,
  USER_LOGGEDIN,
  USER_NOT_LOGGEDIN
} from '../actions/ActionTypes';

const initialState = {
  jwt: localStorage.getItem('jwt'),
  id: localStorage.getItem('id'),
  isAuthenticated: false,
  isLoading: false,
  account: null,
}

const AuthReducer = (state=initialState, action) => {
  switch (action.type) {
    case USER_LOGGEDIN:
      return {
        ...state,
        isAuthenticated: true,
      };
    case USER_NOT_LOGGEDIN:
      return {
        ...state,
        isAuthenticated: false,
      };
    case USER_LOADING:
      return {
        ...state,
        isLoading: true
      };

    case USER_LOADED:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        account: action.payload
      };

    case SIGNIN_SUCCESS:
      localStorage.setItem('jwt', action.payload.jwt);
      localStorage.setItem('id', action.payload.account.id);
      return{
        ...state,
        ...action.payload,
        jwt: localStorage.getItem('jwt'),
        id: localStorage.getItem('id'),
        isLoading: false,
        isAuthenticated: true
      };

      case SIGNUP_SUCCESS:
      localStorage.setItem('id', action.payload.account.id);
        return{
          ...state,
          ...action.payload,
          id: localStorage.getItem('id'),
          isLoading: false,
          isAuthenticated: false
        };

    case SIGNUP_FAIL:
    case SIGNIN_FAIL:
    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
      localStorage.clear();
      return{
        ...state,
        jwt: null,
        id: null,
        account: null,
        isLoading: false,
        isAuthenticated: false
      };
    default:
      return state;
  }
}
export default AuthReducer;

import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginIntUser } from '../../redux/actions/internalActions';
import { clearErrors } from '../../redux/actions/errorActions';
import AuthSidebar from '../shared/AuthSidebar';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { StyledTextInput } from '../Authentication/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 600,
    fontSize: 28,
    letterSpacing: '0.02em',
    color: '#444',
  },
  label: {
    fontSize: 16,
    fontWeight: 400,
    marginBottom: 10,
    marginTop: 20,
  },
}));

const IntSignIn = (props) => {
  const classes = useStyles();
  const { isLoading, isAuthenticated, error, loginIntUser, clearErrors } =
    props;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [showPassword, setPasswordVisiblity] = useState(false);
  const handleClickShowPassword = (e) => {
    e.preventDefault();
    setPasswordVisiblity(!showPassword);
  };

  useEffect(() => {
    clearErrors();
  }, [clearErrors]);

  const handleSubmit = (event) => {
    event.preventDefault();
    let user = {
      email: email,
      password: password,
    };
    loginIntUser(user);
    clearErrors();
  };

  if (isAuthenticated) {
    return <Redirect to="/intdashboard" />;
  } else {
    return (
      <div className="auth__container">
        <div className="authBanner" style={{ minWidth: 360 }}>
          <AuthSidebar />
        </div>
        <div>
          <div className="signin__container--internal">
            <div className="auth__title">Sign In For ETark’s staff</div>

            {/*Error Alert*/}
            {error.msg.message && !isLoading ? (
              <Alert
                severity="error"
                className="width-sm"
                style={{ marginTop: 20 }}
              >
                {error.msg.message}
              </Alert>
            ) : null}

            {/*SignIn form*/}
            <form onSubmit={handleSubmit}>
              <div className={classes.label}>Email Address</div>
              <StyledTextInput
                required
                variant="outlined"
                size="small"
                name="email"
                id="email"
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              <div style={{ position: 'relative' }}>
                <div className={classes.label}>Password</div>
                <StyledTextInput
                  // maxWidth="120px"
                  required
                  variant="outlined"
                  size="small"
                  name="password"
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                  className="eyeIcon--signin--internal"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </div>
              {/* <br /> */}
              {!isLoading ? (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="signin__btn--internal"
                  style={{}}
                >
                  Sign In
                </Button>
              ) : (
                <div style={{ width: 180, margin: 'auto', marginTop: 20 }}>
                  <CircularProgress size={35} />
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  isLoading: state.internal.isLoading,
  isAuthenticated: state.internal.isAuthenticated,
  error: state.error,
});

IntSignIn.propTypes = {
  isLoading: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  error: PropTypes.object.isRequired,
  loginIntUser: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { loginIntUser, clearErrors })(
  IntSignIn
);

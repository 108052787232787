import {
  INTUSER_LOADED,
  INTUSER_LOADING,
  INTSIGNIN_FAIL,
  INTSIGNIN_SUCCESS,
  INTLOGOUT_SUCCESS,
  INTUSER_LOGGEDIN,
  INTUSER_NOT_LOGGEDIN,
  GET_INTPROMOS,
  INTPROMOS_FAIL,
  ACTION_FAIL,
  ACTION_SUCCESS,
  ACTION_RESET
} from '../actions/ActionTypes';

const initialState = {
  jwtInt: localStorage.getItem('jwtInt'),
  isAuthenticated: false,
  isLoading: false,
  account: null,
  promos: [],
  actionMsg: '',
}

const InternalReducer = (state=initialState, action) => {
  switch (action.type) {
    case INTUSER_LOGGEDIN:
      return {
        ...state,
        isAuthenticated: true,
      };
    case INTUSER_NOT_LOGGEDIN:
      return {
        ...state,
        isAuthenticated: false,
      };
    case INTUSER_LOADING:
      return {
        ...state,
        isLoading: true
      };

    case INTUSER_LOADED:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        account: action.payload
      };

    case INTSIGNIN_SUCCESS:
      localStorage.setItem('jwtInt', action.payload.jwt);
      return{
        ...state,
        ...action.payload,
        jwtInt: localStorage.getItem('jwtInt'),
        isLoading: false,
        isAuthenticated: true
      };
    case INTSIGNIN_FAIL:
    case INTLOGOUT_SUCCESS:
      localStorage.removeItem('jwtInt');
      return{
        ...state,
        jwtInt: null,
        account: null,
        isLoading: false,
        isAuthenticated: false,
        promos: []
      };
      case GET_INTPROMOS:
        return {
          ...state,
          promos: action.payload,
        };
      case INTPROMOS_FAIL:
        return {
          ...state,
          promos: [],
        };
      case ACTION_SUCCESS:
        return{
          ...state,
          actionMsg: 'Action Successful'
        }
      case ACTION_FAIL:
        return{
          ...state,
          actionMsg: 'Action failed, please try again'
        }
      case ACTION_RESET:
        return{
          ...state,
          actionMsg: ''
        }
      default:
        return state;
  }
}
export default InternalReducer;

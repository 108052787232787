import {
  GET_PROMOS,
  DELETE_PROMOS,
  PROMOS_LOADING,
  PROMOS_FAIL,
  PROMOD_SUCCESS,
  PROMOD_FAIL,
  GET_PROMO_ACCOUNT,
} from '../actions/ActionTypes';
import axios from 'axios';
//import { returnErrors } from './errorActions';

const prodapi = process.env.REACT_APP_API;

export const getPromos = () => (dispatch) => {
  dispatch(setPromosLoading());
  const busz = localStorage.getItem('id');
  const jwt = localStorage.getItem('jwt');
  axios
    .get(`${prodapi}/promos`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      params: {
        busz: busz,
      },
    })
    .then((res) => {
      dispatch({
        type: GET_PROMOS,
        payload: res.data,
      });
    })
    .catch((error) => {
      //console.log(error.response);
      dispatch({
        type: PROMOS_FAIL,
      });
    });
};

export const deletePromos = (id) => {
  return {
    type: DELETE_PROMOS,
    payload: id,
  };
};

export const setPromosLoading = () => {
  return {
    type: PROMOS_LOADING,
  };
};

export const promoResubmission =
  ({ id, type, changes }) =>
  (dispatch) => {
    //headers
    const jwt = localStorage.getItem('jwt');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    };
    //request body
    const body = JSON.stringify({
      id,
      type,
      changes,
    });

    axios
      .post(`${prodapi}/promos/actionBsz`, body, config)
      .then((res) => {
        dispatch({
          type: PROMOD_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: PROMOD_FAIL,
        });
      });
  };

export const promoStateToggle =
  ({ id, type }) =>
  (dispatch) => {
    //headers
    const jwt = localStorage.getItem('jwt');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    };
    //request body
    const body = JSON.stringify({ id, type });
    axios
      .post(`${prodapi}/promos/actionBsz`, body, config)
      .then((res) => {
        dispatch({
          type: PROMOD_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: PROMOD_FAIL,
        });
      });
  };

export const getPromoAccounts = () => {
  return async (dispatch) => {
    //headers
    const jwt = localStorage.getItem('jwtInt');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    };
    //request body
    const res = await axios.get(`${prodapi}/buszs?_limit=-1`, {}, config);

    if (res.status === 200) {
      dispatch({
        type: GET_PROMO_ACCOUNT,
        payload: {
          accounts: res.data,
        },
      });
    }
  };
};

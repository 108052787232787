import React, { useState } from 'react';
import { Dialog } from '@material-ui/core';
import { Navbar } from './index';
import useStyles, { Content, Title, SubTitle, iframe } from './styles';

const Refund = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [how, setHow] = useState(false);

  return (
    <>
      <Dialog
        onClose={() => setHow(false)}
        open={how}
        fullWidth={true}
        maxWidth="lg"
        className="flex justify-around"
      >
        <iframe
          className={classes.iframe}
          src="https://www.youtube.com/embed/MvrBECJoEr0"
          title="ETark: How to use"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </Dialog>
      <Navbar setOpen={setOpen} setHow={setHow} home />
      <Title>Refund and Cancellation Policy</Title>
      <Content>
        <p style={{ fontStyle: 'italic' }}>
          Any Capitalized terms used but not defined herein shall have the
          meaning assigned to them under the Terms of Use which govern your use
          of our website www.etark.in (the “Website”). The Website is also
          referred to as the “Platform”.
        </p>
        <br />
        <SubTitle pl="none">(i) User Cancellation</SubTitle>
        <p>
          As a general rule, a User shall not be entitled to cancel A Promo once
          published. Under both these cases of the User entitled to cancel, the
          device would be returned to the User’s address without repair with or
          without forfeiting of the Inspection Fee deposit, initially collected
          from the user by ETark, by the service center.
        </p>
        <br />
        <SubTitle pl="none"> (ii) Cancellation policy for ETark</SubTitle>
        <p>
          Any Promo which violates ETark Promotion Guidelines shall be given one
          opportunity to make required changes through the Request for
          Modification mode during which the User needs to make the required
          changes and resubmit the Promo without making any additional payment
          to us, however if still the Promo is founding violating the ETark
          Promotion Guidelines, your Promo would get rejected by us. ETark
          reserves the right to reject Promos due to reasons beyond the ETark
          Promotion Guidelines on account of extraneous circumstances arising
          beyond the control of ETark like Govt. requests or Security reasons.
        </p>
        <br />
        <SubTitle pl="none">(iii) Refunds or Compensation</SubTitle>
        <p>
          In the unlikely event of the Promo being rejected by us on account of
          ETark Promotion Guidelines violation, the equivalent amount set in the
          Ad Budget for the particular Promo under conflict shall be refunded
          back to the account holder within 7- 10 weeks from the date of Promo
          rejection by us.
        </p>
        <br />
        <p>Our decision on refunds shall be final and binding.</p>
      </Content>
    </>
  );
};

export default Refund;

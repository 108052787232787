import React from 'react';
import CancelIcon from '@material-ui/icons/NotInterested';
import PropTypes from 'prop-types';
import Tab from './Tab';
import {connect} from 'react-redux';

const RejectedPage = ({promos}) => {
  return (
    <div style={{ padding: 36}}>
      <div style={{fontSize: 40, fontWeight: '700', display: 'flex', alignItems: 'center'}}>
        Rejected Promos
        <CancelIcon style={{fontSize: "inherit", marginLeft: 10, color: "red"}} />
      </div>
      {promos.map(promo =>{
        if(promo.status==='rejected'){
          return <Tab promo={promo} />;
        }
        else {
          return null;
        }
      })}
    </div>
  )
}

RejectedPage.propTypes = {
  promos: PropTypes.array.isRequired
}

const mapStateToProps = (state) => ({
  promos: state.internal.promos
});

export default connect(mapStateToProps)(RejectedPage);

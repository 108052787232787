import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import { useDispatch, useSelector } from 'react-redux';

import { getPromoAccounts } from '../../redux/actions/promoActions';

const PromoAccount = ({ promos }) => {
  const dataperpage = 10;
  const [page, setPage] = useState(1);
  const indexOfLastData = page * dataperpage;
  const indexOfFirstData = indexOfLastData - dataperpage;

  const dispatch = useDispatch();
  const promo = useSelector((state) => state.promo);

  const totalPromoSpend = (promos) => {
    let total = 0;
    for (let i = 0; i < promos.length; i++) {
      total += promos[i].budget;
    }
    return total.toLocaleString();
  };

  const activePromos = (promos) => {
    let total = 0;
    for (let i = 0; i < promos.length; i++) {
      if (promos[i].status === 'active') {
        total += 1;
      }
    }
    return total;
  };

  const pausedPromos = (promos) => {
    let total = 0;
    for (let i = 0; i < promos.length; i++) {
      if (promos[i].status === 'paused') {
        total += 1;
      }
    }
    return total;
  };
  const pendingPromos = (promos) => {
    let total = 0;
    for (let i = 0; i < promos.length; i++) {
      if (promos[i].status === 'pending') {
        total += 1;
      }
    }
    return total;
  };

  const reqForModification = (promos) => {
    let total = 0;
    for (let i = 0; i < promos.length; i++) {
      if (promos[i].status === 'modification') {
        total += 1;
      }
    }
    return total;
  };

  const completedPromos = (promos) => {
    let total = 0;
    for (let i = 0; i < promos.length; i++) {
      if (promos[i].isCompleted === true) {
        total += 1;
      }
    }
    return total;
  };
  const rejectedPromos = (promos) => {
    let total = 0;
    for (let i = 0; i < promos.length; i++) {
      if (promos[i].status === 'rejected') {
        total += 1;
      }
    }
    return total;
  };

  useEffect(() => {
    dispatch(getPromoAccounts());
  }, []);

  const count = Math.ceil(promo.promoAccounts.length / 10);
  return (
    <div className="intContainer">
      <div className="intHeading">Promo Dashboard </div>
      <TableContainer className="promoTable">
        <Table size="medium" padding="normal">
          <TableHead className="promoTable--head">
            <TableRow>
              <TableCell
                className="promoTable--cell promoTable--head"
                align="left"
              >
                Promo Account
              </TableCell>
              <TableCell
                className="promoTable--cell promoTable--head"
                align="left"
              >
                Total Promo Spend
              </TableCell>
              <TableCell
                className="promoTable--cell promoTable--head"
                align="left"
              >
                Active
              </TableCell>
              <TableCell
                className="promoTable--cell promoTable--head"
                align="left"
              >
                Paused
              </TableCell>
              <TableCell
                className="promoTable--cell promoTable--head"
                align="left"
              >
                Pending
              </TableCell>
              <TableCell
                className="promoTable--cell promoTable--head"
                align="left"
              >
                Request for modification
              </TableCell>
              <TableCell
                className="promoTable--cell promoTable--head"
                align="left"
              >
                Completed
              </TableCell>
              <TableCell
                className="promoTable--cell promoTable--head"
                align="left"
              >
                Rejected
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {promo.promoAccounts
              .slice(indexOfFirstData, indexOfLastData)
              .map((item) => {
                return (
                  <TableRow>
                    <TableCell className="promoTable--bodytext">
                      {item.fullName}
                    </TableCell>
                    <TableCell className="promoTable--bodytext">
                      INR {totalPromoSpend(item.promos)}
                    </TableCell>
                    <TableCell className="promoTable--bodytext">
                      {activePromos(item.promos)}
                    </TableCell>
                    <TableCell className="promoTable--bodytext">
                      {pausedPromos(item.promos)}
                    </TableCell>
                    <TableCell className="promoTable--bodytext">
                      {pendingPromos(item.promos)}
                    </TableCell>
                    <TableCell className="promoTable--bodytext">
                      {reqForModification(item.promos)}
                    </TableCell>
                    <TableCell className="promoTable--bodytext">
                      {completedPromos(item.promos)}
                    </TableCell>
                    <TableCell className="promoTable--bodytext">
                      {rejectedPromos(item.promos)}
                    </TableCell>
                  </TableRow>
                );
              })}
            <TableCell colspan="8" className="paginationContainer">
              <Pagination
                count={count}
                defaultPage={page}
                onChange={(event, value) => setPage(value)}
                variant="outlined"
                shape="rounded"
              />
            </TableCell>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PromoAccount;

import React from 'react';
import './dash.scss';
import TableView from '../shared/Table';

const CompletedPage = ({ CompletedData, selectCreate }) => {
  return (
    <div className="initialPadding">
      <div className="heading">Your Promos</div>
      <TableView Data={CompletedData} />
    </div>
  );
};

export default CompletedPage;

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Navbar, Footer, BottomBanner } from './index';
import { Link, useLocation } from 'react-router-dom';
import { Dialog } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { blurBackground } from '../../redux/actions/errorActions';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100vw',
    overflow: 'hidden',
    overflowY: 'scroll',
  },
  modal: {
    width: 550,
    height: 300,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '80vw',
      height: 200,
    },
  },
  modalLink: {
    color: '#185ADB',
    fontSize: 24,
    fontWeight: 'bold',
    textDecoration: 'none',
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  heroBanner: {
    width: '90%',
    margin: '40px 5%',
    padding: 20,
    backgroundColor: 'rgba(255, 255, 255)',
    boxShadow:
      '0px 13px 25px 1px rgba(64, 123, 255, 0.12), 0px 8px 16px rgba(159, 209, 255, 0.08)',
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      padding: 15,
    },
  },
  grid: {
    width: '90%',
    margin: '60px 5%',
    display: 'flex',
    color: '#52575C',
    fontSize: 14,
    textAlign: 'justify',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: 10,
    },
  },
  title: {
    color: '#52575C',
    fontSize: 35,
    fontWeight: 500,
    textStroke: '4px #52575C',
    fontFamily: 'Poppins',
    letterSpacing: '5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
      letterSpacing: '3px',
      textStroke: '2px black',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      letterSpacing: '1px',
      textStroke: '1px #52575C',
    },
  },
  letter: {
    fontFamily: 'monospace !important',
  },
  iframe: {
    width: 'calc(100vw - 40vw)',
    height: 'calc(100vw - 67vw)',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 10vw)',
      height: 'calc(100vw - 50vw)',
    },
  },
  infoDiv: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  svg: {
    marginTop: 15,
    marginRight: 10,
    [theme.breakpoints.down('sm')]: {
      height: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      height: 12,
    },
  },
  towerContainer: {
    width: '90%',
    margin: '60px 5%',
    display: 'flex',
    color: '#52575C',
    fontSize: 14,
    justifyContent: 'space-around',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: 10,
    },
  },
  tower1: {
    position: 'absolute',
    top: '-38%',
    left: '1%',
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
      top: '-20%',
      left: '-4%',
      width: '25%',
    },
  },
  tower2: {
    position: 'absolute',
    top: '-40%',
    right: '0%',
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
      top: '-24%',
      right: '-5%',
      width: '25%',
    },
  },
  gradient: {
    position: 'absolute',
    width: '100%',
    height: '7rem',
    background: 'rgba(50, 36, 211, 0.47)',
    filter: 'blur(300px)',
  },
  sales: {
    width: 450,
    padding: 20,
    borderRadius: 20,
    backgroundColor: 'rgba(255, 255, 255)',
    boxShadow:
      '0px 13px 25px 1px rgba(64, 123, 255, 0.1), 0px 8px 16px rgba(159, 209, 255, 0.09)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  towerContainerHead: {
    textAlign: 'center',
    color: '#52575C',
    fontSize: 28,
    marginTop: 60,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      padding: '0px 40px',
    },
  },
}));

const Home = () => {
  const classes = useStyles();
  const error = useSelector((state) => state.error);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [how, setHow] = useState(false);
  const [offPane, setOfferPane] = useState(0);
  const [slide, setSlide] = useState(0);

  useEffect(() => {
    dispatch(blurBackground(false));
  }, []);

  return (
    <div className={classes.root}>
      <div style={{ filter: error.bluredState ? 'blur(3px)' : 'blur(0px)' }}>
        <Dialog
          onClose={() => setHow(false)}
          open={how}
          fullWidth={true}
          maxWidth="lg"
          className="flex justify-around"
        >
          <iframe
            className={classes.iframe}
            src="https://www.youtube.com/embed/MvrBECJoEr0"
            title="ETark: How to use"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Dialog>
        <Navbar setOpen={setOpen} setHow={setHow} />
        <div className={classes.heroBanner}>
          <div>
            <div className={`${classes.title} home--title1`}>
              W<span className={classes.letter}>e</span>’ll add to your profit
            </div>
            <ul
              className="list-disc"
              style={{ color: '#76797c', marginTop: 10, marginLeft: 25 }}
            >
              <li className="my-2 home--txt1">
                Increase sales through target based ADs
              </li>
              <li className="my-2 home--txt1">
                Resolve consumer complaints instantly for free
              </li>
            </ul>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <img
              className="homeBanner"
              src="assets/images/homebanner.png"
              alt="home banner"
            />
          </div>
        </div>
        <div className={classes.towerContainerHead}>
          A Revolutionary Platform <b>for, of & by</b> your business
        </div>
        <div className={classes.towerContainer}>
          <div className={classes.gradient} />
          <img
            className={classes.tower1}
            src="/assets/images/landingPage/lower-tower1.svg"
            alt=""
          />
          <img
            className={classes.tower2}
            src="/assets/images/landingPage/lower-tower2.svg"
            alt=""
          />

          <div id="sales" className={classes.sales}>
            <div className="sales">
              {slide === 0 ? 'Sales' : 'Consumer'}
              <div className="sales--btns">
                <button
                  onClick={() => setSlide(0)}
                  className="sales--btns--left"
                >
                  {'<'}
                </button>
                <button
                  onClick={() => setSlide(1)}
                  className="sales--btns--right"
                >
                  {'>'}
                </button>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src={
                  slide === 0
                    ? 'assets/images/home1.png'
                    : 'assets/images/home2.png'
                }
                alt="home1"
                className="home--img1"
              />
            </div>
            {slide === 0 ? (
              <>
                <ul style={{ fontWeight: 500, marginTop: 10, lineHeight: 1.5 }}>
                  <li style={{ display: 'flex', marginBottom: 10 }}>
                    <FiberManualRecordIcon className="bullet" />
                    Are you losing business due to your ads shown along with
                    your rival business?
                  </li>
                  <li style={{ display: 'flex', marginBottom: 10 }}>
                    <FiberManualRecordIcon className="bullet" />
                    Or is your ad getting lost in a sea of internet users who
                    don't find your product relevant?
                  </li>
                </ul>

                <div style={{ display: 'flex' }}>
                  <FiberManualRecordIcon className="bullet" />
                  Worry no more, ETark is here to generate sales for you through
                  our target based marketing approach
                </div>

                <div className="learn-more">
                  <a href="#etarkpromo">Learn More...</a>
                </div>
              </>
            ) : (
              <>
                <p style={{ fontWeight: 500, marginTop: 20, lineHeight: 1.5 }}>
                  <div style={{ display: 'flex' }}>
                    <FiberManualRecordIcon className="bullet" />
                    Consumer complaints, even one, can add to your customer
                    support cost, reduce your Net Promotor score and pose a
                    legal risk for your business
                  </div>

                  <br />
                  <br />
                  <div style={{ display: 'flex' }}>
                    <FiberManualRecordIcon className="bullet" />
                    Try our patented invention through which you can resolve
                    your consumer complaints in less than 1 second for free!
                  </div>
                </p>
                <div className="learn-more">
                  <Link to="/oneshot">
                    <p>Learn More...</p>
                  </Link>
                </div>
              </>
            )}
          </div>
          <div className="consumer">
            <div style={{ marginBottom: 20, fontWeight: 700 }}>
              Consumer Complaints
            </div>
            <img
              src="assets/images/home2.png"
              alt="home1"
              width="380px"
              height="130px"
            />
            <p style={{ fontWeight: 500, marginTop: 20, lineHeight: 1.5 }}>
              <div style={{ display: 'flex' }}>
                <FiberManualRecordIcon className="bullet" />
                Consumer complaints, even one, can add to your customer support
                cost, reduce your Net Promotor score and pose a legal risk for
                your business
              </div>
              <br />
              <br />
              <div style={{ display: 'flex' }}>
                <FiberManualRecordIcon className="bullet" />
                Try our patented invention through which you can resolve your
                consumer complaints in less than 1 second for free!
              </div>
            </p>
          </div>
        </div>
        <div className="section--whyEtark" id="etarkpromo">
          <p>Why opt to ETark?</p>
          <div className="flex justify-between">
            <div className="section--whyEtark--title">
              We’ve got multiple offerings for your business
            </div>
            <div className="flex -mt-6 section--whyEtark-btns">
              <button
                className="section--whyEtark-btns--left"
                onClick={() => {
                  setOfferPane(0);
                }}
              >
                {'<'}
              </button>
              <button
                className="section--whyEtark-btns--right"
                onClick={() => {
                  setOfferPane(1);
                }}
              >
                {'>'}
              </button>
            </div>
          </div>
          <div className="section--whyEtark--imgContainer">
            <img
              className="section--whyEtark-imgs01"
              src={
                offPane === 0
                  ? 'assets/images/home3.png'
                  : 'assets/images/home4.png'
              }
              alt="home3"
              width="320px"
            />
            <img
              className="section--whyEtark-imgs02"
              src={
                offPane === 0
                  ? 'assets/images/home3.5.png'
                  : 'assets/images/home4.5.png'
              }
              alt="home3"
              width="320px"
            />
            <div id="solutions" style={{ color: '#52575C' }}>
              <h4 className="font-bold solutions--title">
                {offPane === 0
                  ? 'ETark Promo'
                  : 'One Shot Resolution (for free)'}
              </h4>
              <p className="solutions--text" style={{ marginTop: 10 }}>
                {offPane === 0 ? (
                  <div>
                    <div className="flex items-center my-6">
                      <img
                        src="assets/icons/pane1a.png"
                        className="solution--icon"
                        alt=""
                      />
                      <span className="ml-4 solutions--text">
                        Show Ads to only those users who use your
                        product/service
                      </span>
                    </div>
                    <div className="flex items-center my-6">
                      <img
                        src="assets/icons/pane1b.png"
                        className="solution--icon"
                        alt=""
                      />
                      <span className="ml-4 solutions--text">
                        Face zero competition from your competitors while
                        advertising
                      </span>
                    </div>
                    <div className="flex items-center my-6">
                      <img
                        src="assets/icons/pane1c.png"
                        className="solution--icon"
                        alt=""
                      />
                      <span className="ml-4 solutions--text">
                        Get more relevant conversions for a given ad budget
                      </span>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="flex items-center my-6">
                      <img
                        src="assets/icons/pane2a.png"
                        className="solution--icon"
                        alt=""
                      />
                      <span className="ml-4">
                        Customer complaint resolution time reduced to less than
                        1 second thus saving cost
                      </span>
                    </div>
                    <div className="flex items-center my-6">
                      <img
                        src="assets/icons/pane2b.png"
                        className="solution--icon"
                        alt=""
                      />
                      <span className="ml-4">
                        Increase in net promoter score & brand equity
                      </span>
                    </div>
                    <div className="flex items-center my-6">
                      <img
                        src="assets/icons/pane2c.png"
                        className="solution--icon"
                        alt=""
                      />
                      <span className="ml-4">
                        Prevent your consumer complaints from turning into a
                        legal risk
                      </span>
                    </div>
                    <div className="flex items-center my-6">
                      <img
                        src="assets/icons/pane2d.png"
                        className="solution--icon"
                        alt=""
                      />
                      <span className="ml-4">
                        Listing your online business for free in our mobile app
                        for sales
                      </span>
                    </div>
                  </div>
                )}
              </p>
            </div>
          </div>
        </div>

        <div className="div--bottom">
          <p style={{ color: '#336CFB', fontSize: 12, marginBottom: 10 }}>
            How are we different?
          </p>
          <div className="section--whyEtark--title">ETark promo VS Others</div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
            }}
          >
            <div className="section--bottom">
              <h4 className="section--bottom--title">ETark Promo</h4>
              <div className={classes.infoDiv}>
                <img
                  className={classes.svg}
                  src="/assets/icons/vector.svg"
                  alt=""
                />
                <p className="section--bottom--text">
                  Forget complicated ad account management which made you spend
                  more time in managing your ad account than focus on your
                  business.
                </p>
                <br />
                <br />
              </div>
              <div className={classes.infoDiv}>
                <img
                  src="/assets/icons/vector-2.svg"
                  alt=""
                  className={classes.svg}
                />
                <p className="section--bottom--text">
                  Target relevant users in a simple hassle free manner with one
                  touch
                </p>
              </div>
            </div>
            <img
              className="section--whyEtark-imgs01 "
              src="assets/images/home5.png"
              alt="home3"
              width="400px"
              height="250px"
            />
            <img
              className="section--whyEtark-imgs02 "
              src="assets/images/home5.5.png"
              alt="home3"
              width="400px"
              height="250px"
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              marginTop: 50,
            }}
          >
            <img
              className="section--whyEtark-imgs01 "
              src="assets/images/home6.png"
              alt="home3"
              width="400px"
              height="250px"
            />
            <img
              className="section--whyEtark-imgs02 "
              src="assets/images/home6.5.png"
              alt="home3"
              width="400px"
              height="250px"
            />
            <div className="section--bottom">
              <h4 className="section--bottom--title">
                One Shot Resolution VS Others
              </h4>
              <div className={classes.infoDiv}>
                <img
                  src="/assets/icons/vector-3.svg"
                  alt=""
                  className={classes.svg}
                />
                <p className="section--bottom--text">
                  No customer care follow up thus bring down cost and time of
                  resolution
                </p>
              </div>
              <br />
              <div className={classes.infoDiv}>
                <img
                  src="/assets/icons/vector-4.svg"
                  alt=""
                  className={classes.svg}
                />
                <p className="section--bottom--text">
                  Customers trust our resolution because we are an independent
                  platform
                </p>
              </div>
              <br />

              <div className={classes.infoDiv}>
                <img
                  src="/assets/icons/vector-5.svg"
                  alt=""
                  className={classes.svg}
                />
                <p className="section--bottom--text">
                  Save your business from the embarrasment of the customer
                  complaining on social media/review sites
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*Get Started Dialog box*/}
      <Dialog
        onClose={() => setOpen(false)}
        aria-labelledby="get-started-dialog"
        open={open}
      >
        <div className={classes.modal}>
          <div style={{ fontSize: 20, fontWeight: 500, color: '#888889' }}>
            What would you like to use?
          </div>
          <div style={{ marginTop: 20 }}>
            <Link to="/signup" className={classes.modalLink}>
              ETark Promo
            </Link>
          </div>
          <div style={{ marginTop: 20 }}>
            <Link to="/oneshot" className={classes.modalLink}>
              One Shot Resolution
            </Link>
          </div>
        </div>
      </Dialog>
      <BottomBanner setOpen={setOpen} setHow={setHow} />
      <Footer />
    </div>
  );
};

export default Home;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import CreatePage from './CreatePage';
import ExistingPage from './ExistingPage';
import CompletedPage from './CompletedPage';
import Button from '@material-ui/core/Button';
import LeftTabBar from '../LeftTabBar/LeftTabBar';
import { connect } from 'react-redux';
import { getPromos } from '../../redux/actions/promoActions';
import { logoutUser } from '../../redux/actions/authActions';
import NotifyIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Dialog, DialogContent } from '@material-ui/core';

import './dash.scss';
import { LogoutBtn } from './styles';

const Dashboard = (props) => {
  const { promo, getPromos, logoutUser } = props;
  const history = useHistory();

  const [create, setCreate] = useState(!false);
  const [existing, setExisting] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [kudos, setKudo] = useState(props.params.act != null);
  const [kstate, setKst] = useState(props.params.act || 'error');
  const [upd, setUpdate] = useState(props.params.update === 'true');
  const [promoId, setPromoId] = useState(props.params.promoId);

  const [notify, setNotify] = useState(false);
  const [ExistingData, setExistingData] = useState([]);
  const [CompletedData, setCompletedData] = useState([]);

  const selectCreate = async () => {
    setCreate(true);
    setExisting(false);
    setCompleted(false);
    setKudo(false);
  };

  const selectExisting = async () => {
    setCreate(false);
    setExisting(true);
    setCompleted(false);
  };

  const selectCompleted = async () => {
    setCreate(false);
    setExisting(false);
    setCompleted(true);
  };

  const closeKudo = () => setKudo(false);

  useEffect(() => {
    if (props.params.update == 'true' && upd) {
      setCreate(false);
      setExisting(false);
      setCompleted(false);
    }
  }, []);

  useEffect(() => {
    getPromos();
    //  console.log('kstate', kstate);
    //  console.log('props', props);
  }, [getPromos]);

  useEffect(() => {
    // console.log(promo);
    if (ExistingData.length === 0 && CompletedData.length === 0) {
      var tmpEx = [];
      var tmpComp = [];
      promo.promos.map((item) => {
        if (
          !item.isCompleted &&
          item.status !== 'rejected' &&
          item.status !== 'notpaid'
        ) {
          tmpEx.push(item);
        } else if (item.isCompleted || item.status === 'rejected') {
          tmpComp.push(item);
        }
      });

      if (tmpEx.length) {
        setExistingData(tmpEx.reverse());
      }

      if (tmpEx.length && upd !== true) {
        selectExisting();
      }

      if (tmpComp.length) setCompletedData(tmpComp.reverse());
    }
  }, [promo, ExistingData, CompletedData]);

  const handleLogout = () => {
    logoutUser();
    history.push('/home');
  };

  return (
    <div className="dashboard">
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={kudos}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent className="flex flex-col items-center my-6">
          {kstate !== 'error' ? (
            <div className="text-3xl text-eblue-800 font-black">KUDOS!</div>
          ) : (
            <div className="text-3xl text-red-500 font-black ">
              PAYMENT UNSUCCESFUL
            </div>
          )}
          <div className="text-center text-gray-500 my-4 text-sm">
            {kstate !== 'error' ? (
              <>
                <div className="my-2">We have recieved your promo request!</div>
                <div className="my-2">
                  Our team will notify when your promo request is approved.
                </div>
              </>
            ) : (
              <>
                <div className="my-2">
                  We couldn’t process your promo request!
                </div>
                <div className="my-2">Try again to publish your promo.</div>
              </>
            )}
          </div>
          <Button
            variant="contained"
            onClick={kstate !== 'error' ? closeKudo : selectCreate}
            className="contBtn"
          >
            {kstate !== 'error' ? 'CONTINUE TO DASHBOARD' : 'Try Again'}
          </Button>
        </DialogContent>
      </Dialog>
      <LeftTabBar
        create={create}
        existing={existing}
        completed={completed}
        selectCreate={selectCreate}
        selectExisting={selectExisting}
        selectCompleted={selectCompleted}
      />
      <div
        className="relative"
        style={{ backgroundColor: '#F6F8FB', width: '100%' }}
      >
        <div style={{ float: 'right' }}>
          {!existing && !completed ? null : (
            <div>
              <Badge className="text-gray-400 bell" invisible={notify}>
                <NotifyIcon />
              </Badge>
              <LogoutBtn
                type="button"
                color="primary"
                size="large"
                onClick={handleLogout}
                variant="contained"
                style={{ margin: 30 }}
              >
                Logout
              </LogoutBtn>
            </div>
          )}
        </div>
        {!create && !existing && !completed && upd ? (
          <CreatePage isCreate={false} promoId={promoId} />
        ) : null}
        {create ? <CreatePage isCreate={true} /> : null}
        {existing ? <ExistingPage ExistingData={ExistingData} /> : null}
        {completed ? <CompletedPage CompletedData={CompletedData} /> : null}
        {!existing && !completed ? null : (
          <Fab
            className="plusCont"
            color="primary"
            aria-label="add"
            style={{ position: 'fixed', right: '3%' }}
            onClick={selectCreate}
          >
            <AddIcon />
          </Fab>
        )}
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  getPromos: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  promo: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  promo: state.promo,
});

export default connect(mapStateToProps, { getPromos, logoutUser })(Dashboard);

import React from 'react';
import TableView from '../shared/Table';
import { connect, useDispatch } from 'react-redux';

import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ExistingPage = ({ ExistingData, actionMsg }) => {
  const dispatch = useDispatch();
  const demoFunc = () => {
    dispatch({ type: 'PROMOD_RESET' });
    window.location.reload();
  };

  return (
    <div className="initialPadding">
      <div className="heading">Your Promos</div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={actionMsg && actionMsg !== ''}
        key={'topcenter'}
        autoHideDuration={2000}
        onClose={demoFunc}
      >
        {actionMsg !== '' ? (
          <Alert severity={actionMsg.includes('fail') ? 'error' : 'success'}>
            <span style={{ fontFamily: 'Poppins' }}>{actionMsg}</span>
          </Alert>
        ) : null}
      </Snackbar>
      <TableView Data={ExistingData} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  actionMsg: state.promo.actionMsg,
});

export default connect(mapStateToProps)(ExistingPage);

var cities = [ {
  "name": "Kolkata",
  "state": "West Bengal",
  "latitude": "22.5414185",
  "longitude": "88.3576912"
}, {
  "name": "Ranchi",
  "state": "Jharkhand",
  "latitude": "23.3700354",
  "longitude": "85.3250132"
}, {
  "name": "Pondicherry",
  "state": "Puducherry",
  "latitude": "10.9156489",
  "longitude": "79.8069488"
}, {
  "name": "Jodhpur",
  "state": "Rajasthan",
  "latitude": "26.2967719",
  "longitude": "73.0351433"
}, {
  "name": "Thiruvananthapuram",
  "state": "Kerala",
  "latitude": "8.5241122",
  "longitude": "76.9360573"
}, {
  "name": "Mumbai",
  "state": "Maharashtra",
  "latitude": "19.0759899",
  "longitude": "72.8773928"
}, {
  "name": "Varanasi",
  "state": "Uttar Pradesh",
  "latitude": "25.3356491",
  "longitude": "83.0076292"
}, {
  "name": "Ghaziabad",
  "state": "Uttar Pradesh",
  "latitude": "28.711241",
  "longitude": "77.4445372"
}, {
  "name": "Tiruchirappalli",
  "state": "Tamil Nadu",
  "latitude": "10.804973",
  "longitude": "78.6870296"
}, {
  "name": "Jhansi",
  "state": "Uttar Pradesh",
  "latitude": "25.5310311",
  "longitude": "78.652689"
}, {
  "name": "Gurgaon",
  "state": "Haryana",
  "latitude": "28.4282624",
  "longitude": "77.0027001"
},{
  "name": "Jalandhar",
  "state": "Punjab",
  "latitude": "31.2920107",
  "longitude": "75.5680577"
}, {
  "name": "Noida",
  "state": "Uttar Pradesh",
  "latitude": "28.5707841",
  "longitude": "77.3271074"
}, {
  "name": "Gwalior",
  "state": "Madhya Pradesh",
  "latitude": "26.2037247",
  "longitude": "78.1573628"
}, {
  "name": "Tirupati",
  "state": "Andhra Pradesh",
  "latitude": "13.6316368",
  "longitude": "79.4231711"
}, {
  "name": "Raichur",
  "state": "Karnataka",
  "latitude": "16.083333",
  "longitude": "77.166667"
}, {
  "name": "Mathura",
  "state": "Uttar Pradesh",
  "latitude": "27.633333",
  "longitude": "77.583333"
}, {
  "name": "Bangalore",
  "state": "Karnataka",
  "latitude": "12.9791198",
  "longitude": "77.5912997"
}, {
  "name": "Navi Mumbai",
  "state": "Maharashtra",
  "latitude": "19.0308262",
  "longitude": "73.0198537"
}, {
  "name": "Aligarh",
  "state": "Uttar Pradesh",
  "latitude": "27.8769897",
  "longitude": "78.1372903"
}, {
  "name": "Thane",
  "state": "Maharashtra",
  "latitude": "19.1943294",
  "longitude": "72.9701779"
}, {
  "name": "Lucknow",
  "state": "Uttar Pradesh",
  "latitude": "26.8381",
  "longitude": "80.9346001"
}, {
  "name": "Bhopal",
  "state": "Madhya Pradesh",
  "latitude": "23.2584857",
  "longitude": "77.401989"
}, {
  "name": "Srinagar",
  "state": "Jammu and Kashmir",
  "latitude": "34.0747444",
  "longitude": "74.8204443"
}, {
  "name": "New Delhi",
  "state": "Delhi",
  "latitude": "28.6138954",
  "longitude": "77.2090057"
}, {
  "name": "Howrah",
  "state": "West Bengal",
  "latitude": "22.5736296",
  "longitude": "88.3251045"
}, {
  "name": "Chandigarh",
  "state": "Chandigarh",
  "latitude": "30.7334421",
  "longitude": "76.7797143"
}, {
  "name": "Patiala",
  "state": "Punjab",
  "latitude": "30.2090874",
  "longitude": "76.3398721"
}, {
  "name": "Jammu",
  "state": "Jammu and Kashmir",
  "latitude": "32.7185614",
  "longitude": "74.8580917"
}, {
  "name": "Kharagpur",
  "state": "West Bengal",
  "latitude": "25.1331731",
  "longitude": "86.5250404"
}, {
  "name": "Pune",
  "state": "Maharashtra",
  "latitude": "18.521428",
  "longitude": "73.8544541"
}, {
  "name": "Surat",
  "state": "Gujarat",
  "latitude": "21.1864607",
  "longitude": "72.8081281"
}, {
  "name": "Ahmedabad",
  "state": "Gujarat",
  "latitude": "23.0216238",
  "longitude": "72.5797068"
}, {
  "name": "Hyderabad",
  "state": "Telangana",
  "latitude": "17.360589",
  "longitude": "78.4740613"
}, {
  "name": "Indore",
  "state": "Madhya Pradesh",
  "latitude": "22.7203616",
  "longitude": "75.8681996"
}, {
  "name": "Amritsar",
  "state": "Punjab",
  "latitude": "31.6343083",
  "longitude": "74.8736788"
}, {
  "name": "Dehradun",
  "state": "Uttarakhand",
  "latitude": "30.3255646",
  "longitude": "78.0436813"
}, {
  "name": "Agartala",
  "state": "Tripura",
  "latitude": "23.8312377",
  "longitude": "91.2823821"
}, {
  "name": "Shimla",
  "state": "Himachal Pradesh",
  "latitude": "31.1041526",
  "longitude": "77.1709729"
}, {
  "name": "Jaipur",
  "state": "Rajasthan",
  "latitude": "26.9154576",
  "longitude": "75.8189817"
}, {
  "name": "Chennai",
  "state": "Tamil Nadu",
  "latitude": "13.0836939",
  "longitude": "80.270186"
}, {
  "name": "Agra",
  "state": "Uttar Pradesh",
  "latitude": "27.1752554",
  "longitude": "78.0098161"
}, {
  "name": "Panchkula",
  "state": "Haryana",
  "latitude": "30.6162165",
  "longitude": "77.041978"
}];

export default cities;

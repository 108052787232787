import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components/macro';

const useStyles = makeStyles((theme) => ({
  already: {
    fontSize: 16,
    fontWeight: 'normal',
    letterSpacing: '0.02em',
    float: 'right',
    marginRight: 100,
  },
  title: {
    fontWeight: 600,
    fontSize: 28,
    letterSpacing: '0.02em',
  },
  label: {
    fontSize: 16,
    fontWeight: 400,
    marginBottom: 10,
    marginTop: 20,
  },
}));

export const StyledTextInput = styled(TextField)`
  && {
    width: 380px;
    max-width: ${({ maxWidth }) => maxWidth || '480px'};
    @media (max-width: 420px) {
      width: calc(95vw - 10vw) !important;
    }
  }
`;

export const SignInBtn = styled(Button)`
  && {
    width: 180px;
    background: #245fd3;
    text-transform: none;
    margin-top: 20px;
    font-size: 16px;

    @media (max-width: 500px) {
      width: 100px;
      font-size: 14px;
      padding: 5px 2px;
    }
  }
`;

export default useStyles;

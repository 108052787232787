import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import AuthSidebar from '../shared/AuthSidebar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { connect } from 'react-redux';
import axios from 'axios';

const prodapi = process.env.REACT_APP_API;

const VerifyEmail = (props) => {
  const { isAuthenticated } = props;
  const [params, setParams] = useState(
    Object.fromEntries(new URLSearchParams(window.location.search))
  );
  const [isLoading, setLoading] = useState(true);
  const [pass, setPass] = useState(false);
  const [verf, setVerf] = useState(false);
  const [msg, setMsg] = useState('');

  useEffect(() => {
    if (params.id && params.token) {
      var data = JSON.stringify({
        id: params.id,
        emailToken: params.token,
      });

      var config = {
        method: 'post',
        url: `${prodapi}/buszs/verify`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };

      axios(config)
        .then((res) => {
          setLoading(false);
          setPass(true);
          setVerf(res.data.confirmed);
        })
        .catch((err) => {
          setLoading(false);
          setPass(false);
          setMsg(
            (err.response && err.response.data.message) || 'Internal Error'
          );
        });
    }
  }, [params]);

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ minWidth: 360 }}>
        <AuthSidebar />
      </div>
      <div className="w-full h-screen grid place-items-center">
        {isLoading ? (
          <div>
            <CircularProgress color="primary" size={96} />
          </div>
        ) : (
          <div className="flex flex-col items-center">
            {pass ? (
              <img
                src="../assets/images/success.png"
                alt="success"
                width="600"
              />
            ) : (
              <img src="../assets/images/home5.png" alt="success" width="600" />
            )}
            <div className="my-6 text-lg text-gray-800">
              {pass ? (
                <>
                  Congratulations!! Your {verf ? 'account' : 'email'} has been
                  verified
                </>
              ) : (
                msg
              )}
            </div>
            {pass ? (
              <Button
                className="noShad"
                href="/signin"
                variant="contained"
                color="primary"
              >
                Proceed to Login
              </Button>
            ) : (
              <Button
                className="noShad"
                href="/"
                variant="contained"
                color="primary"
              >
                Back to Home
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(VerifyEmail);

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Facebook, LinkedIn, Twitter } from '@material-ui/icons';
import InstagramIcon from '@material-ui/icons/Instagram';
import { Link } from 'react-router-dom';
import { Modal } from '../helpers';
import Help from './Help';
import styled from 'styled-components/macro';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#26252E',
    width: '100%',
    height: 'auto',
    color: '#F6F6F6',
    padding: '50px 5% 30px 5%',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  links: {
    color: '#185ADB',
    fontSize: 24,
    fontWeight: 'bold',
    textDecoration: 'none',
  },
  privacy: {
    fontSize: 10,
    fontWeight: 300,
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
}));

const Footer = ({ home }) => {
  const classes = useStyles();
  const [openHelp, setOpenHelp] = useState(false);
  const [openEtarkPromo, setOpenEtarkPromo] = useState(false);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <div>
            <img src="assets/images/logo.png" alt="logo" />
          </div>
          <Button
            onClick={() => window.open('mailto:contact@etark.in', '_parent')}
            type="button"
            href="mailto:contact@etark.in"
            style={{ backgroundColor: '#fff', marginTop: 30, width: 120 }}
          >
            Contact Us
          </Button>
          <div style={{ fontSize: 14, fontWeight: 400, marginTop: 20 }}>
            13 K.B. Sarani <br /> Kolkata, India
          </div>
          <div style={{ marginTop: 40, marginBottom: 20 }}>
            <a
              href="https://www.facebook.com/etarkcompany"
              rel="noreferrer"
              target="_blank"
            >
              <Facebook style={{ marginRight: 5 }} />
            </a>
            <a
              href="https://www.linkedin.com/company/etark"
              rel="noreferrer"
              target="_blank"
            >
              <LinkedIn style={{ marginRight: 5 }} href="https://google.com" />
            </a>
            <a
              href="https://twitter.com/ETark4"
              rel="noreferrer"
              target="_blank"
            >
              <Twitter style={{ marginRight: 5 }} />
            </a>
            <a
              href="https://www.instagram.com/etark_official/?hl=en"
              rel="noreferrer"
              target="_blank"
            >
              <InstagramIcon />
            </a>
          </div>
        </Grid>

        <Grid item xs={12} sm={3} md={3} lg={3}>
          <div style={{ fontSize: 16, fontWeight: 600 }}>Our Solutions</div>
          <div style={{ fontSize: 14, fontWeight: 400, marginTop: 20 }}>
            {window.location.hostname === 'localhost' ? (
              <a href="http://localhost:3000/#etarkpromo">ETark Promo</a>
            ) : (
              <a href="https://business.etark.in/#etarkpromo">ETark Promo</a>
            )}
          </div>
          <div
            style={{
              fontSize: 14,
              fontWeight: 400,
              marginTop: 10,
              marginBottom: 20,
            }}
          >
            <Link to="/oneshot">One Shot Resolution</Link>
          </div>
        </Grid>

        <Grid item xs={12} sm={3} md={3} lg={3}>
          <div style={{ fontSize: 16, fontWeight: 600 }}>Information</div>

          <div
            onClick={() => setOpenHelp(true)}
            style={{
              fontSize: 14,
              fontWeight: 400,
              marginTop: 10,
              cursor: 'pointer',
            }}
          >
            Help and Documentation
          </div>

          <div style={{ fontSize: 14, fontWeight: 400, marginTop: 10 }}>
            <Link to="/intellectual-property">Intellectual Property</Link>
          </div>
          <div
            style={{
              fontSize: 14,
              fontWeight: 400,
              marginTop: 10,
            }}
          >
            <Link to="/disclaimers"> Disclaimer</Link>
          </div>
          <div style={{ fontSize: 14, fontWeight: 400, marginTop: 10 }}>
            <a href="/internal">Internal Dashboard</a>
          </div>
        </Grid>

        <Grid item xs={12}>
          <div
            style={{
              fontSize: 14,
              fontWeight: 400,
              marginBottom: 20,
              textAlign: 'center',
            }}
          >
            Brands we serve : Myntra | Femora | Portronics | Yuva Soul | The Man
            Company | OneAssist | Clinikk
          </div>
          <hr />
        </Grid>

        <Grid item xs={12} sm={6}>
          <div style={{ fontSize: 10, fontWeight: 300 }}>
            Copyright 2020. ETark Tech Pvt. Ltd. All rights reserved
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.privacy} style={{}}>
            <Link to="/privacy-policy">Privacy</Link>
            <span style={{ marginLeft: 20 }}>Terms & Conditions</span>
            <span style={{ marginLeft: 20 }}>
              <Link to="/refund-and-cancellation-policy">
                Refund and Cancellation Policy
              </Link>
            </span>
          </div>
        </Grid>
      </Grid>
      <Modal open={openHelp} handleClose={() => setOpenHelp(false)}>
        <HelpModalContainer>
          <h1 onClick={() => setOpenEtarkPromo(true)}>ETark Promo</h1>
        </HelpModalContainer>
      </Modal>
      <Modal open={openEtarkPromo} handleClose={() => setOpenEtarkPromo(false)}>
        <Help handleClose={() => setOpenEtarkPromo(false)} />
      </Modal>
    </div>
  );
};

export default Footer;

export const HelpModalContainer = styled.div`
  background: #ffffff;
  width: 400px;
  height: 200px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 20px;
  h1 {
    font-size: 24px;
    font-weight: 900;
    color: #185adb;
    cursor: pointer;
  }
  h3 {
    margin-bottom: 1rem;
    font-size: 20px;
    font-weight: 500;
    color: #888889;
  }

  @media (max-width: 450px) {
    width: calc(100vw - 10vw);
    height: calc(100vw - 60vw);
    h1 {
      font-size: 20px;
    }
  }
`;

import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import AuthSidebar from '../shared/AuthSidebar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
// import useStyles from './styles';
import { connect } from 'react-redux';
import axios from 'axios';

const prodapi = process.env.REACT_APP_API;

const ResetPass = (props) => {
  const { isAuthenticated } = props;
  //   const classes = useStyles();
  const [params, setParams] = useState(
    Object.fromEntries(new URLSearchParams(window.location.search))
  );
  const [isLoading, setLoading] = useState(false);
  const [pass, setPass] = useState(false);
  const [sent, setSent] = useState(false);
  const [newpass, setNewPass] = useState('');
  const [passConfirm, setPasswordConfirm] = useState('');
  const [msg, setMsg] = useState('');

  const handleChange = () => {
    if (params.id && params.token) {
      var data = JSON.stringify({
        id: params.id,
        resetPasswordToken: params.token,
        newpass: newpass,
      });

      var config = {
        method: 'post',
        url: `${prodapi}/buszs/verifyResetToken`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };

      setLoading(true);
      setSent(true);

      axios(config)
        .then((res) => {
          if (res.data.status === 200) {
            setLoading(false);
            setPass(true);
          } else {
            setLoading(false);
            setPass(false);
            setMsg((res.data && res.data.message) || 'Unexpected Error');
          }
        })
        .catch((err) => {
          setLoading(false);
          setPass(false);
          setMsg(
            (err.response && err.response.data.message) || 'Unexpected Error'
          );
        });
    }
  };

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  if (!params.id || !params.token) {
    return <Redirect to="/" />;
  }

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ minWidth: 360 }}>
        <AuthSidebar />
      </div>
      <div
        className={
          sent || isLoading
            ? 'w-full h-screen grid place-items-center'
            : 'w-full h-screen p-24'
        }
      >
        {isLoading ? (
          <div>
            <CircularProgress color="primary" size={96} />
          </div>
        ) : (
          <>
            {sent ? (
              <div className="flex flex-col items-center">
                {pass ? (
                  <img
                    src="../assets/images/success.png"
                    alt="success"
                    width="600"
                  />
                ) : (
                  <img
                    src="../assets/images/home5.png"
                    alt="success"
                    width="600"
                  />
                )}
                <div className="my-6 text-lg text-gray-800">
                  {pass ? (
                    <>Congratulations!! Your password has been changed</>
                  ) : (
                    msg
                  )}
                </div>
                {pass ? (
                  <Button
                    className="noShad"
                    href="/signin"
                    variant="contained"
                    color="primary"
                  >
                    Proceed to Login
                  </Button>
                ) : (
                  <Button
                    className="noShad"
                    href="/"
                    variant="contained"
                    color="primary"
                  >
                    Back to Home
                  </Button>
                )}
              </div>
            ) : (
              <div className="resetForm">
                <div className="text-4xl text-gray-600 font-semibold mb-12">
                  Reset Password
                </div>
                <TextField
                  className="passInp"
                  label="New Password"
                  variant="outlined"
                  type="text"
                  value={newpass}
                  onChange={(e) => setNewPass(e.target.value)}
                />
                <br />
                <TextField
                  className="passInp"
                  label="Confirm Password"
                  variant="outlined"
                  type="text"
                  error={newpass !== passConfirm && passConfirm !== ''}
                  helperText={
                    passConfirm !== '' && newpass !== passConfirm
                      ? "Password doesn't match"
                      : ''
                  }
                  value={passConfirm}
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                />
                <br />
                <Button
                  className="noShad"
                  variant="contained"
                  disabled={
                    newpass === '' ||
                    passConfirm === '' ||
                    newpass !== passConfirm
                  }
                  color="primary"
                  onClick={handleChange}
                >
                  Change password
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(ResetPass);

//import Data from "../../components/shared/data";
import {
  GET_PROMOS,
  DELETE_PROMOS,
  PROMOS_LOADING,
  PROMOS_FAIL,
  PROMOD_SUCCESS,
  PROMOD_FAIL,
  PROMOD_RESET,
  GET_PROMO_ACCOUNT,
} from '../actions/ActionTypes';

const initialState = {
  isLoading: false,
  promos: [],
  promoAccounts: [],
  actionMsg: '',
};

const PromoReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROMOS:
      return {
        ...state,
        promos: action.payload,
        isLoading: false,
      };
    case DELETE_PROMOS:
      return {
        ...state,
        promos: state.promos.filter((promo) => promo.id !== action.payload),
      };
    case PROMOS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case PROMOS_FAIL:
      return {
        ...state,
        promos: [],
        isLoading: false,
      };
    case PROMOD_SUCCESS:
      return {
        ...state,
        actionMsg: 'Action Successful',
      };
    case PROMOD_FAIL:
      return {
        ...state,
        actionMsg: 'Action failed, please try again',
      };
    case PROMOD_RESET:
      return {
        ...state,
        actionMsg: '',
      };
    case GET_PROMO_ACCOUNT:
      return {
        ...state,
        promoAccounts: action.payload.accounts,
      };
    default:
      return state;
  }
};
export default PromoReducer;

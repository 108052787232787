import styled from 'styled-components/macro';
import { Button, TextField } from '@material-ui/core';

export const StyledBtn = styled(Button)`
  && {
    background-color: ${({ background }) => background || '#fff'};
    font-size: ${({ fontSize }) => fontSize || '14px'};
    margin-left: ${({ ml }) => ml || '75px !important'};

    @media (max-width: 1250px) {
      margin-left: 16px !important;
    }

    @media (max-width: 850px) {
      margin-left: 82px !important;
    }

    @media (max-width: 515px) {
      margin-left: 70px !important;
    }

    @media (max-width: 500px) {
      width: 120px !important;
      margin-left: 60px !important;
      font-size: 13px !important;
    }
  }
`;

export const AddPicture = styled(Button)`
  && {
    background: #fff;
    color: #336cfb;
    text-transform: none;
    font-size: 12px;
    box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.1);

    @media (max-width: 500px) {
      width: max-content;
      padding: 10px;
      font-size: 12px !important;
    }
  }
`;

export const StyledTextField = styled(TextField)`
  && {
    width: calc(45vw - 10vw);
    max-width: 500px;
    margin-top: 15px;
    padding: ${({ padding }) => padding || '5px'};
    border: ${({ border }) => border || 'solid 2px #ccc'};
    border-radius: 3px;

    @media (max-width: 850px) {
      max-width: 380px;
      width: calc(100vw - 25vw);
    }

    @media (max-width: 500px) {
      .MuiInputBase-input,
      .MuiOutlinedInput-input {
        font-size: 13px !important;
      }
    }
  }
`;

export const Title = styled.div`
  margin-left: ${({ ml }) => ml || 'auto'};
  font-size: 17px;
  font-weight: bold;
  color: #336cfb;
  width: 100%;
  @media (max-width: 500px) {
    font-size: 16px;
  }
  @media (max-width: 400px) {
    font-size: 14px;
    /* color: red; */
  }
`;

export const SubTitle = styled.div`
  font-size: 16px;
  font-weight: 600;

  @media (max-width: 500px) {
    font-size: 13px;
  }
`;

export const OptionPara = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: #857f7f;
  margin-top: 10px;

  @media (max-width: 500px) {
    font-size: 11px;
  }
`;

export const LogoutBtn = styled(Button)`
  && {
    @media (max-width: 500px) {
      padding: 6px 9px;
      margin: 24px !important;
      margin-left: 8px !important;
      font-size: 11px;
    }
  }
`;

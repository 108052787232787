import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IntDashboard from './IntDashboard';
import IntSignIn from './IntSignIn';
import { getIntPromos } from '../../redux/actions/internalActions';

class IntMain extends React.Component {

  componentDidMount() {
    this.props.getIntPromos();
  }

  render () {
    if (this.props.isAuthenticated){
      return <IntDashboard />
    }
    else{
      return <IntSignIn />
    }
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.internal.isAuthenticated,
});

IntMain.propTypes = {
  isAuthenticated: PropTypes.bool,
  getIntPromos: PropTypes.func.isRequired,
}
export default connect(mapStateToProps, {getIntPromos})(IntMain);

import React, {
  useState
} from 'react';
// import PlacesAutocomplete, {
//   geocodeByAddress,
//   getLatLng,
// } from 'react-places-autocomplete';
// import scriptLoader from 'react-async-script-loader';
import TextField from '@material-ui/core/TextField';

import cityArr from './cities.json';

const LocationBar = ({
  handleArray
}) => {
  const [address, setAddress] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [focus, setFocus] = useState(false);
  const [cities, setCities] = useState(cityArr);

  const handleDrawer = (e) => {
    var tpadd = e.target.value.trim();
    setAddress(tpadd);
    if(tpadd.length==0) return setSuggestions([]);

    var tpsugg = [],
      tpadd = tpadd.toLowerCase();
    for (var i = 0; i < cities.length; i++) {
      if (cities[i].name.toLowerCase().includes(tpadd)) {
        tpsugg.push(cities[i])
      }
    }

    tpsugg.sort((a, b) => {
      var idx1 = a.name.toLowerCase().indexOf(tpadd),
        idx2 = b.name.toLowerCase().indexOf(tpadd);

      return idx1 > idx2 ? 1 : -1
    });

    setSuggestions(tpsugg);
  }

  const handleSelect = (e) => {
    var tpadd = e.target.innerText.trim(),
      lon = e.target.dataset.lon,
      lat = e.target.dataset.lat;

    setAddress('');
    setSuggestions([]);
    handleArray({
      location: tpadd,
      lat: lat,
      lng: lon
    });
  };

  return (
    <div className="locField" tabindex="0">
      <TextField
        variant="outlined"
        className="locInput"
        value={address}
        onChange={handleDrawer}/>
      <div className="locopts thinScroll">
        {suggestions.map((sugg) => {
          return (
            <div
              className="locopt" data-lon={sugg.longitude}
              data-lat={sugg.latitude} onClick={handleSelect}>
              {sugg.name}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LocationBar;

// export default scriptLoader([
//   `https://maps.googleapis.com/maps/api/js?key=AIzaSyBM-fv_cTh82JWnc3hDdKJXz_m11ZqqPAc&libraries=places`,
// ])(LocationBar);

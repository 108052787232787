import { combineReducers } from 'redux';
import PromoReducer from './promoReducer';
import ErrorReducer from './errorReducer';
import AuthReducer from './authReducer';
import VerifyReducer from './verifyReducer';
import InternalReducer from './internalReducer';

export default combineReducers({
  promo: PromoReducer,
  error: ErrorReducer,
  auth: AuthReducer,
  verify: VerifyReducer,
  internal: InternalReducer,
});

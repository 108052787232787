import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 26,
    backgroundColor: '#336CFB',
    borderRadius: 8,
    textAlign: 'center',
    color: '#fff',
    maxWidth: 330,
    maxHeight: 395,
    [theme.breakpoints.down('xs')]: {
      padding: 20,
      maxWidth: 300,
    },
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      fontSize: 15,
    },
  },
  para: {
    fontSize: 12,
    fontWeight: 'normal',
    color: '#FFF1F1',
    marginTop: 8,
    padding: '0 6px',
    marginBottom: 15,
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  btnSelected: {
    backgroundColor: '#FFF',
    color: '#336CFB',
    textTransform: 'none',
    width: 140,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      width: 110,
      marginBottom: 10,
    },
  },
  btnNotSelected: {
    backgroundColor: '#336CFB',
    color: '#FFF',
    textTransform: 'none',
    width: 140,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      width: 110,
      marginBottom: 10,
    },
  },
}));

const ExpectedAdDuration = ({ promoWay, setPromoWay, calculateEstimated }) => {
  const classes = useStyles();

  const simplify = () => {
    var res = calculateEstimated()[0];
    res = res.toString();
    if (res.length > 3) {
      res = Math.floor((res * 10) / 1000) / 10;
      res += 'K';
    }

    return res;
  };

  return (
    <div className={classes.root}>
      <div className={classes.title}>Expected Ad Duration</div>
      <div className={classes.para}>{calculateEstimated()[1]} Days</div>

      <Box position="relative" display="inline-flex">
        <CircularProgress
          className="absolute"
          variant="determinate"
          value={100}
          size={150}
          style={{ color: 'rgba(255, 255, 255, 0.25)' }}
        />
        <CircularProgress
          variant="determinate"
          value={32}
          size={150}
          style={{ color: '#fefefe' }}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <div
            style={{ fontSize: 24, fontWeight: 300 }}
            title={calculateEstimated()[0]}
          >
            {simplify()}
          </div>
          <div className={classes.para}>
            Estimated Total
            <br />
            Reach
          </div>
        </Box>
      </Box>

      <div className={classes.para} style={{ fontSize: 12, color: '#FFF' }}>
        How do you want to show your promotion?
      </div>

      <ButtonGroup style={{ borderColor: '#FFF' }}>
        <Button
          variant="contained"
          className={!promoWay ? classes.btnSelected : classes.btnNotSelected}
          onClick={() => setPromoWay(false)}
        >
          General
        </Button>
        <Button
          variant="contained"
          className={promoWay ? classes.btnSelected : classes.btnNotSelected}
          onClick={() => setPromoWay(true)}
        >
          Relevant
        </Button>
      </ButtonGroup>
      <div className={classes.para}>
        {promoWay
          ? 'Advertise before a selected audience who use your product/service'
          : 'Advertise before a general audience'}
      </div>
    </div>
  );
};

export default ExpectedAdDuration;

import React from 'react';
import PropTypes from 'prop-types';
import Tab from './Tab';
import { connect } from 'react-redux';

const ModificationPage = ({ promos }) => {
  return (
    <div style={{ padding: 36 }}>
      <div style={{ fontSize: 40, fontWeight: '700' }}>
        Modification Dashboard
      </div>
      {promos.map((promo) => {
        if (promo.status === 'modification') {
          return <Tab promo={promo} />;
        }
      })}
    </div>
  );
};

ModificationPage.propTypes = {
  promos: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  promos: state.internal.promos,
});

export default connect(mapStateToProps)(ModificationPage);

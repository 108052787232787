import React, { useState } from 'react';
import './LeftTabBar.scss';
import { makeStyles } from '@material-ui/core/styles';
import { TabsBtn1, TabsBtn2 } from './TabsBtn';
import { Link } from 'react-router-dom';
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
import { Drawer } from '../helpers';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#FFFFFF',
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    boxShadow: '4px 0px 16px rgba(16, 30, 115, 0.08)',
    minWidth: '280px',
    zIndex: 1,
    padding: 24,
  },
  logo: {
    width: 48,
    marginTop: 34,
  },
  logoText: {
    color: '#185ADB',
    marginLeft: 10,
    marginTop: 48,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 16,
    letterSpacing: '0.02em',
  },
  promos: {
    marginTop: 40,
    fontSize: 14,
    fontWeight: 400,
    color: '#A0A4A8',
  },
  TabsHead: {
    marginTop: 26,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
}));

const LeftTabBar = ({
  create,
  existing,
  completed,
  selectCreate,
  selectExisting,
  selectCompleted,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const toggleNav = () => {
    setOpen(!open);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="toggleNav">
        <Button
          onClick={() => {
            toggleNav();
          }}
        >
          <MenuOutlinedIcon />
        </Button>
      </div>

      <div className="root">
        <Drawer open={open} onClose={onClose} width="15rem" color="#fff">
          <Link to="/" style={{ textDecoration: 'none' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                background: '#ffffff',
              }}
            >
              <img
                alt="logo"
                src="assets/images/bluelogo.png"
                className={classes.logo}
              />
              <div className={classes.logoText}>For Business</div>
            </div>
          </Link>
          <div className={classes.promos} style={{ marginLeft: '1.5rem' }}>
            Your Promos
          </div>
          <div className={classes.TabsHead} style={{ marginLeft: '1.5rem' }}>
            {create ? (
              <TabsBtn2 text="Create a new Promo" type="create" />
            ) : (
              <TabsBtn1
                text="Create a new Promo"
                type="create"
                onClickFunction={selectCreate}
              />
            )}
            {existing ? (
              <TabsBtn2 text="Existing promos" type="existing" />
            ) : (
              <TabsBtn1
                text="Existing promos"
                type="existing"
                onClickFunction={selectExisting}
              />
            )}

            {completed ? (
              <TabsBtn2 text="Completed promos" type="completed" />
            ) : (
              <TabsBtn1
                text="Completed promos"
                type="completed"
                onClickFunction={selectCompleted}
              />
            )}
          </div>
        </Drawer>

        <Link to="/" style={{ textDecoration: 'none' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
            }}
          >
            <img
              alt="logo"
              src="assets/images/bluelogo.png"
              className={classes.logo}
            />
            <div className={classes.logoText}>For Business</div>
          </div>
        </Link>
        <div className={classes.promos}>Your Promos</div>
        <div className={classes.TabsHead}>
          {create ? (
            <TabsBtn2 text="Create a new Promo" type="create" />
          ) : (
            <TabsBtn1
              text="Create a new Promo"
              type="create"
              onClickFunction={selectCreate}
            />
          )}
          {existing ? (
            <TabsBtn2 text="Existing promos" type="existing" />
          ) : (
            <TabsBtn1
              text="Existing promos"
              type="existing"
              onClickFunction={selectExisting}
            />
          )}

          {completed ? (
            <TabsBtn2 text="Completed promos" type="completed" />
          ) : (
            <TabsBtn1
              text="Completed promos"
              type="completed"
              onClickFunction={selectCompleted}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default LeftTabBar;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#245FD3 url(assets/images/towers.png) no-repeat',
    backgroundPosition: 'center top',
    width: '100%',
    color: '#F6F6F6',
    padding: '80px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  bottomText: {
    fontSize: 22,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      padding: '0 10px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  getStarted: {
    width: 200,
    backgroundColor: '#fff',
    color: '#185ADB',
    [theme.breakpoints.down('xs')]: {
      width: '120px',
      fontSize: 13,
    },
  },
  howToUse: {
    marginLeft: 20,
    fontSize: 14,
    color: '#fefefe',
  },
}));

const BottomBanner = ({ setOpen, setHow }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <div className={classes.bottomText}>
          Don't wait, get your business transformed instantly through us
        </div>
        <div style={{ marginTop: 30 }}>
          <Button
            type="button"
            onClick={() => setOpen(true)}
            variant="contained"
            className={classes.getStarted}
          >
            Get Started
          </Button>
          <Button
            className={classes.howToUse}
            onClick={() => {
              setHow(true);
            }}
          >
            HOW TO USE
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BottomBanner;

import { GET_ERRORS, CLEAR_ERRORS, BLUR } from './ActionTypes';

export const returnErrors = (msg, status) => {
  return {
    type: GET_ERRORS,
    payload: { msg, status },
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

export const blurBackground = (value) => {
  return async (dispatch) => {
    dispatch({
      type: BLUR,
      payload: {
        value,
      },
    });
  };
};
